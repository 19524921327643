import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'store';
import { SmallSpacer, Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { IVendor, VendorBookingPolicy, vendorBookingPolicyLabels, VendorConfiguration } from 'models/vendor';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { VendorsApi } from 'framework/API/vendor.api';
import { currentVendorSelector } from 'store/selectors/vendor.selectors';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import RSelect from 'components/Select/RSelect';
import { Resources } from 'enum/resources.enum';

export interface RMyServicesHeaderProps {
	title: string;
	description: string;
	bookingPolicy: VendorBookingPolicy;
}

const RMyServicesHeader: React.FC<RMyServicesHeaderProps> = ({ title, description, bookingPolicy }: RMyServicesHeaderProps) => {
	const currentVendor = useSelector(currentVendorSelector, shallowEqual);
	const [vendorDescription, setVendorDescription] = useState(description);
	const [vendorBookingPolicy, setVendorBookingPolicy] = useState<VendorBookingPolicy>(bookingPolicy);
	const dispatch = useDispatch();

	const updateVendorDescription = () => {
		if (currentVendor?.vendor) {
			const newConfiguration: VendorConfiguration = {
				...currentVendor.vendor.configuration,
				description: vendorDescription,
			};
			performAsyncAction(
				dispatch,
				new VendorsApi().update(currentVendor.vendor.id, { configuration: newConfiguration }),
				(vendor: IVendor) => {
					setVendorDescription(vendor.configuration.description);
				}
			);
		}
	};

	const bookingPolicyOptions = React.useMemo(
		() =>
			Object.entries(vendorBookingPolicyLabels).map(([value, label]) => ({
				label,
				value: Number(value),
				selected: vendorBookingPolicy === Number(value),
			})),
		[vendorBookingPolicy]
	);

	const updateVendorBookingPolicy = (value: VendorBookingPolicy) => {
		if (currentVendor?.vendor) {
			const newConfiguration: VendorConfiguration = {
				...currentVendor.vendor.configuration,
				booking_policy: value,
			};
			performAsyncAction(
				dispatch,
				new VendorsApi().update(currentVendor.vendor.id, { configuration: newConfiguration }),
				(vendor: IVendor) => {
					setVendorBookingPolicy(vendor.configuration.booking_policy);
				}
			);
		}
	};

	return (
		<Container>
			<RLabel value={title} type={RLabelTypes.BOLD_41} />
			<Spacer />
			<RLabel value={Resources.MY_SERVICE_SCREEN_DESCRIPTION_LABEL} type={RLabelTypes.BOLD_18} />
			<SmallSpacer />
			<TextField
				className="description-text-area"
				value={vendorDescription}
				onBlur={updateVendorDescription}
				fullWidth
				onChange={e => setVendorDescription(e.target.value)}
				multiline
				variant="outlined"
				minRows={2}
				maxRows={2}
			/>
			<Spacer />
			<RLabel value={Resources.MY_SERVICE_SCREEN_BOOKING_POLICY_LABEL} type={RLabelTypes.BOLD_18} />
			<RLabel value={Resources.MY_SERVICE_SCREEN_BOOKING_POLICY_SUBTITLE} type={RLabelTypes.REGULAR_16} />
			<SmallSpacer />
			<RSelect dense options={bookingPolicyOptions} onChange={updateVendorBookingPolicy} minWidth={256} />
		</Container>
	);
};

export default RMyServicesHeader;

const Container = styled.div.attrs({ className: 'my-services-header' })`
	.description-text-area {
		background: white;

		.MuiOutlinedInput-multiline {
			padding: 11.5px 14px;
		}
	}
`;
