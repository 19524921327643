import { Dialog } from '@material-ui/core';
import React from 'react';
import { DivContainer } from 'App';
import { Resources } from 'enum/resources.enum';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import RCheckbox from 'components/Checkbox/RCheckbox';
import { FloatRight, SmallSpacer, Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Form } from 'react-bootstrap';
import { IProductCategory } from 'models/productCategory';

export interface IRCategoryDialogProps {
	category?: IProductCategory;
	open: boolean;
	onClose: () => void;
}

const RCategoryDialog: React.FC<IRCategoryDialogProps> = ({ category, open, onClose }) => (
	<Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
		<DivContainer style={{ padding: 30 }}>
			<RLabel value={Resources.CREATE_CATEGORY_DIALOG_TITLE} type={RLabelTypes.BOLD_25} />
			<Spacer />
			<Form>
				<Form.Group>
					<Form.Label>
						<RLabel value={Resources.CREATE_CATEGORY_DIALOG_FIELD_1_LABEL} type={RLabelTypes.BOLD_18} />
					</Form.Label>
					<Form.Control type="text" value={category?.name} disabled />
				</Form.Group>
				<SmallSpacer />
				<RCheckbox
					disabled
					isChecked={Boolean(category?.is_single_product_per_order)}
					label={Resources.CREATE_CATEGORY_DIALOG_FIELD_2_LABEL}
				/>
				<RLabel value={Resources.CREATE_CATEGORY_DIALOG_FIELD_2_DESCRIPTION} />

				<RCheckbox isChecked={Boolean(category?.is_recurring_products)} label={Resources.CREATE_CATEGORY_DIALOG_FIELD_3_LABEL} />
				<RLabel value={Resources.CREATE_CATEGORY_DIALOG_FIELD_3_DESCRIPTION} />
			</Form>
			<Spacer />
			<FloatRight>
				<RButton onClick={onClose} label={Resources.CREATE_CATEGORY_DIALOG_CANCEL_BUTTON} theme={RButtonTheme.LIGHT} />
				<RButton
					label={Resources.CREATE_CATEGORY_DIALOG_PUBLISH_BUTTON}
					theme={RButtonTheme.DARK}
					disabled
					onClick={() => onClose()}
				/>
			</FloatRight>
		</DivContainer>
	</Dialog>
);

export default RCategoryDialog;
