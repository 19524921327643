import axios from 'axios';
import BaseApi from 'framework/base.api';
import NetworkManager from 'framework/NetworkManager';

export class SlotsApi extends BaseApi {
	constructor() {
		super();
		this.route = this.getRoute();
	}

	getRoute(): string {
		return `${NetworkManager.apiEndpoint}/slots`;
	}

	getSlots(vendorId: string, date: Date) {
		//To prevent diff hours issue
		date.setHours(12);
		return axios.post(`${this.route}/read-at-date`, {
			at_date: date,
			vendor: vendorId,
		});
	}

	getMonthlyDates(vendorId: string, month?: number) {
		return axios.post(`${this.route}/read-monthly-dates`, {
			vendor: vendorId,
			month,
		});
	}
}
