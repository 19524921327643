import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'store';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { FlexSpaceBetween, Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Resources } from 'enum/resources.enum';
import RContainer from 'components/Container/RContainer';
import REmptyCategoryView from 'features/CategoriesPage/REmptyCategoryView';
import { ROUTES } from 'constants/routes';
import RCategoryDialog from 'components/CategoryDialog/RCategoryDialog';
import { IVendorParams } from 'features/MyServicesPage/MyServicesPage';
import { IVendor } from 'models/vendor';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { VendorsApi } from 'framework/API/vendor.api';
import { GetVendorResponse } from 'store/slices/vendor.slice';
import { IProductCategory } from 'models/productCategory';
import { CategoriesApi } from 'framework/API/categories.api';
import RCategoryCard from 'features/CategoriesPage/RCategoryCard';
import RBreadcrumbs from 'components/Breadcrumbs/RBreadcrumbs';

export interface ICategoryParams extends IVendorParams {
	menuId: string;
}

const CategoriesPage: React.FC = () => {
	const { menuId, vendorId } = useParams<ICategoryParams>();
	const dispatch = useDispatch();
	const [currentVendor, setCurrentVendor] = useState<IVendor | null>();
	const [categories, setCategories] = useState<IProductCategory[]>([]);

	const [categoryDialog, setCategoryDialog] = useState<{ open: boolean; category?: IProductCategory }>({ open: false });

	const fetchCategories = useCallback(() => {
		if (currentVendor) {
			performAsyncAction(dispatch, new CategoriesApi().searchBy(menuId), (res: any[]) => {
				//mapping the _id to be id
				setCategories(
					res.map(i => {
						return { ...i, id: i._id };
					})
				);
			});
		}
	}, [currentVendor, dispatch, menuId]);

	useEffect(() => {
		if (!currentVendor) {
			performAsyncAction(dispatch, new VendorsApi().readById(vendorId), (res: GetVendorResponse) => {
				setCurrentVendor(res.vendor);
			});
		}
	}, [currentVendor, dispatch, vendorId]);

	useEffect(() => {
		fetchCategories();
	}, [currentVendor, fetchCategories]);

	return (
		<RContainer>
			<Spacer />
			<RBreadcrumbs
				links={[
					{ href: ROUTES.FOOD_DASHBOARD, label: Resources.MENUS_SCREEN_CURRENT_DIR_LINK },
					{ href: ROUTES.MENUS.replace(':vendorId', vendorId), label: currentVendor?.type || '' },
					{ label: `${menuId} ${Resources.CATEGORIES_SCREEN_CURRENT_DIR_LINK}` },
				]}
			/>
			<Spacer />
			<FlexSpaceBetween>
				<RLabel value={Resources.CATEGORIES_SCREEN_TITLE} type={RLabelTypes.BOLD_25} />
			</FlexSpaceBetween>
			<Spacer />
			{categories.length > 0 ? (
				<Grid container spacing={3}>
					{categories.map((category, index) => {
						return (
							<Grid key={index} item xs={12} sm={12}>
								<RCategoryCard category={category} />
							</Grid>
						);
					})}
				</Grid>
			) : (
				<REmptyCategoryView description={Resources.CATEGORIES_SCREEN_EMPTY_STATE_DESCRIPTION} />
			)}
			<Spacer />
			<RCategoryDialog {...categoryDialog} onClose={() => setCategoryDialog({ open: false })} />
		</RContainer>
	);
};

export default CategoriesPage;
