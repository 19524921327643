import { makeStyles } from '@material-ui/core';
import { getDay } from 'date-fns';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { createGlobalStyle } from 'styled-components';
import { DivContainer } from 'App';
import theme from 'components/theme';

const DatePickerWrapperStyles = createGlobalStyle`
		.react-datepicker__tab-loop {
			margin: 0;
			
			&__start, &__end {
				margin: 0;
			}
		}
    .datepicker-calendar {
      
    }
    .react-datepicker__header {
      background-color: #fff;
    }
    .react-datepicker__day--selected {
      border-radius: 1rem !important;
      background-color: ${theme.palette.primary.main} !important;
    }

    .react-datepicker__day--selected:hover {
      border-radius: 1rem !important;
      background-color: ${theme.palette.primary.main} !important;
    }

    .react-datepicker__day--highlighted {
      border-radius: 1rem;
      background-color: ${theme.palette.primary.light};
    }

    .react-datepicker__day--highlighted:hover {
      border-radius: 1rem;
      background-color: ${theme.palette.primary.light};
    }
`;

const useStyles = makeStyles(() => ({
	datepicker: {
		height: 45,
		width: 120,
		border: '1px solid #CBCBCB',
		borderRadius: 3,
		paddingLeft: 40,
	},
}));
export interface IRDatePickerProps {
	startDate?: Date;
	onChange: (date: Date) => void;
	onMonthChange?: (date: Date) => void;
	highlightDates?: Date[];
	filterDate?: (date: Date) => boolean;
}

const RDatePicker: React.FC<IRDatePickerProps> = props => {
	const classes = useStyles();
	const [startDate, setStartDate] = useState(props.startDate || new Date());

	const isBusinessDay = (date: any) => {
		const day = getDay(date);
		return day !== 5 && day !== 6;
	};

	return (
		<DivContainer>
			<DatePicker
				className={classes.datepicker}
				calendarClassName="datepicker-calendar"
				selected={startDate}
				dateFormat="dd/MM/yy"
				onMonthChange={props.onMonthChange}
				onChange={(date: Date) => {
					props.onChange(date);
					setStartDate(date);
				}}
				filterDate={props.filterDate || isBusinessDay}
				highlightDates={props.highlightDates}
			/>
			<DatePickerWrapperStyles />
		</DivContainer>
	);
};
export default RDatePicker;
