import * as React from 'react';

const useDebounce = <T>(value: T, delay: number = 300): T => {
	const [debouncedValue, setDebouncedValue] = React.useState(value);

	React.useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);
		// clean on unmount
		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return React.useMemo(() => debouncedValue, [debouncedValue]);
};

export default useDebounce;
