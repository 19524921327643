import { IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { RServiceCardMoreOption } from 'features/MyServicesPage/ServiceCard/RServiceCard';
import Menu from 'components/Menu/Menu';

const useStyles = makeStyles(theme => ({
	root: {
		float: 'right',
		marginBottom: '5px',
	},
	button: {
		color: theme.palette.primary.main,
	},
}));

export interface RMoreButtonProps {
	options: RServiceCardMoreOption[];
	disabled?: boolean;
}

const RMoreButton: React.FC<RMoreButtonProps> = ({ options, disabled }: RMoreButtonProps) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = (option: RServiceCardMoreOption) => {
		setAnchorEl(null);
		option.onSelect();
	};

	return (
		<div className={classes.root}>
			<IconButton
				disabled={disabled || false}
				className={classes.button}
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu id="simple-menu" anchorEl={anchorEl} handleClose={handleClose} handleSelect={handleSelect} options={options} />
		</div>
	);
};

export default RMoreButton;
