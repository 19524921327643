import { Avatar, makeStyles } from '@material-ui/core';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';

const useStyles = makeStyles(theme => ({
	root: {
		alignItems: 'center',
	},
	small: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		marginRight: 10,
		backgroundColor: 'transparent',
	},
}));

export interface RImageAndLabelProps {
	label?: string;
	imageUrl?: string;
	element?: JSX.Element;
	type?: RLabelTypes;
}

const RImageAndLabel: React.FC<RImageAndLabelProps> = (props: RImageAndLabelProps) => {
	const classes = useStyles();
	return (
		<ListGroup className={classes.root} horizontal>
			{props.imageUrl ? (
				<Avatar alt="Remy Sharp" src={props.imageUrl} className={classes.small} />
			) : props.element ? (
				<Avatar className={classes.small}>{props.element}</Avatar>
			) : (
				''
			)}

			<RLabel value={props.label || ''} type={props.type || RLabelTypes.REGULAR_16} />
		</ListGroup>
	);
};

export default RImageAndLabel;
