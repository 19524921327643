import { CardContent, Link } from '@material-ui/core';
import React from 'react';
import RButton, { RButtonProps } from 'components/Button/RButton';
import RCard from 'components/Card/RFade';
import { IServiceParams } from 'features/ServicePage/ServicePage';
import { BaseRoute } from 'framework/Routes/Base.route';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { FlexSpaceBetween } from 'components/general';
import { CategoriesPageRoute } from 'framework/Routes/CategoriesPage.route';
import { IProductCategoryMenu } from 'models/productCategory';
import { ListGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

export interface RMenuCardProps {
	menu: IProductCategoryMenu;
	button?: RButtonProps;
	link?: { label: string; href: string };
}

const RMenuCard: React.FC<RMenuCardProps> = (props: RMenuCardProps) => {
	const { vendorId } = useParams<IServiceParams>();

	const navigateToCategoriesPage = () => {
		BaseRoute.navigateTo(new CategoriesPageRoute(), [vendorId, props.menu]);
	};

	return (
		<RCard height={100} padding={20}>
			<CardContent>
				<FlexSpaceBetween>
					<RLabel value={props.menu} type={RLabelTypes.BOLD_18} />
					<ListGroup style={{ alignItems: 'center' }}>
						<RButton {...props.button} height={40} width={163} onClick={props.button?.onClick || navigateToCategoriesPage} />
						{props.link ? (
							<Link style={{ textAlign: 'center' }} href={props.link?.href}>
								{' '}
								{props.link?.label}{' '}
							</Link>
						) : (
							''
						)}
					</ListGroup>
				</FlexSpaceBetween>
			</CardContent>
		</RCard>
	);
};

export default RMenuCard;
