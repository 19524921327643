import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
	const history = useHistory();

	const backToSafety = () => {
		history.replace('/');
	};
	return (
		<div data-testid="Sign-in">
			<h1>Page Not found</h1>
			<Button onClick={backToSafety}>I'm scared, go Back to dashboard</Button>
		</div>
	);
};

export default NotFoundPage;
