import { RScheduleSlotsDayRowProps } from 'components/ScheduleSlotsDaysTable/ScheduleSlotsDayRow/ScheduleSlotsDayRow';
import { IROption } from 'components/Select/RSelect';
import moment from 'moment';

export interface ISlotsScheduleTemplate {
	id?: string;
	time_spread: SlotsTimeSpread;
	duration: number;
	day_properties: SlotDayProperties[];
	minimum_subscribers: number;
	maximum_subscribers: number;
	slot_type: string;
	default_location: string;
}

export enum SlotsTimeSpread {
	DAILY = 0,
	WEEKLY = 7,
	MONTHLY = 30,
}

export enum SlotsScheduleMethod {
	DATE_RANGE = 0,
	SINGLE_DAY = 1,
}

export enum BUSINESS_DAY {
	SUNDAY = 0,
	MONDAY = 1,
	TUESDAY = 2,
	WEDNESDAY = 3,
	THURSDAY = 4,
}

export type SlotDayProperties = {
	day: BUSINESS_DAY;
	is_active: boolean;
	properties: SlotDayProperty[];
};

export class SlotDayProperty {
	start_time: HourAndMinutes;

	end_time: HourAndMinutes;

	max_participants: number;

	location: string;

	constructor(start_time: HourAndMinutes, end_time: HourAndMinutes, max_participants: number, location: string) {
		this.start_time = start_time;
		this.end_time = end_time;
		this.max_participants = max_participants;
		this.location = location;
	}
}
export class HourAndMinutes {
	hour: number;

	minutes: number;

	constructor(hour: number, minutes: number) {
		this.hour = hour;
		this.minutes = minutes;
	}

	addDuration(duration: number) {
		this.minutes += duration;
		if (this.minutes >= 60) {
			this.minutes = this.minutes - 60;
			if (this.hour + 1 <= DEFAULT_END_HOUR) {
				this.hour += 1;
			}
		}
		return this;
	}

	lt(time: HourAndMinutes): boolean {
		return this.hour < time.hour || (this.hour === time.hour && this.minutes < time.minutes);
	}

	lte(time: HourAndMinutes): boolean {
		return this.hour < time.hour || (this.hour === time.hour && this.minutes <= time.minutes);
	}

	gt(time: HourAndMinutes): boolean {
		return this.hour > time.hour || (this.hour === time.hour && this.minutes > time.minutes);
	}
}

const MAX_SLOT_DURATION = 120;
const SLOT_DURATION_INTERVAL = 5;
export const slotsDuration = new Array(MAX_SLOT_DURATION / SLOT_DURATION_INTERVAL)
	.fill(0)
	.map((_, i) => (i + 1) * SLOT_DURATION_INTERVAL);

export const businessDayToString = (day: BUSINESS_DAY): string => {
	return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday'][day];
};

export const slotsTimeSpread = [SlotsTimeSpread.WEEKLY, SlotsTimeSpread.MONTHLY];

const MINUTES_DIFFERENCE = 5;
const DEFAULT_START_HOUR = 6;
export const DEFAULT_END_HOUR = 23;

export const buildHoursSelectOptions = (time: SlotDayProperty, selectedTime: HourAndMinutes[]): IROption[] => {
	const options: IROption[] = [];
	while (
		time.start_time.hour < time.end_time.hour ||
		(time.start_time.hour === time.end_time.hour && time.start_time.minutes < MINUTES_DIFFERENCE && time.start_time.hour < 24)
	) {
		const value: HourAndMinutes = time.start_time;
		const option: IROption = {
			value: [value.hour < 10 ? `0${value.hour}` : value.hour, value.minutes < 10 ? `0${value.minutes}` : value.minutes].join(
				':'
			),
			label: [value.hour < 10 ? `0${value.hour}` : value.hour, value.minutes < 10 ? `0${value.minutes}` : value.minutes].join(
				':'
			),
			selected: selectedTime.some(timeSlot => value.hour === timeSlot.hour && value.minutes === timeSlot.minutes),
		};
		time.start_time.minutes += MINUTES_DIFFERENCE;
		if (time.start_time.minutes >= 60) {
			time.start_time.minutes = 0;
			time.start_time.hour += 1;
		}
		options.push(option);
	}
	return options;
};

export const getScheduleSlotsTable = (data: SlotDayProperties[], sessionDuration: number): RScheduleSlotsDayRowProps[] =>
	data.map<RScheduleSlotsDayRowProps>(slotsDayProperty => ({
		sessionDuration,
		//disable checkbox of a row if only one will be display
		disableCheckbox: data.length === 1,
		day: slotsDayProperty.day,
		isActive: slotsDayProperty.is_active,
		selectedProperties: slotsDayProperty.properties.map(property => ({
			startOptions: buildHoursSelectOptions(getDefaultDayProperties(), [property.start_time]),
			endOptions: buildHoursSelectOptions(getDefaultDayProperties(), [property.end_time]),
			maxParticipants: property.max_participants,
			location: property.location,
		})),
	}));

export const getDefaultScheduleSlotsTemplateData = (serviceId: string): ISlotsScheduleTemplate => {
	return {
		time_spread: SlotsTimeSpread.WEEKLY,
		duration: 15,
		day_properties: [
			getDefaultProperties(BUSINESS_DAY.SUNDAY),
			getDefaultProperties(BUSINESS_DAY.MONDAY),
			getDefaultProperties(BUSINESS_DAY.TUESDAY),
			getDefaultProperties(BUSINESS_DAY.WEDNESDAY),
			getDefaultProperties(BUSINESS_DAY.THURSDAY),
		],
		minimum_subscribers: 1,
		maximum_subscribers: 15,
		slot_type: serviceId,
		default_location: null,
	};
};

const getDefaultProperties = (day: BUSINESS_DAY): SlotDayProperties => {
	return {
		day,
		is_active: true,
		properties: [getDefaultDayProperties()],
	};
};

export const getDefaultDayProperties = (): SlotDayProperty => {
	return new SlotDayProperty(new HourAndMinutes(DEFAULT_START_HOUR, 0), new HourAndMinutes(DEFAULT_END_HOUR, 0), 15, '');
};

export const getSelectedTime = (options: IROption[]): HourAndMinutes => {
	const start = options.find(a => a.selected)?.value;
	const [startHour, startMinutes] = (start ?? '00:00')?.split(':').map(Number);
	return new HourAndMinutes(startHour, startMinutes);
};

export const getTheBusinessDate = (): Date => {
	const date = moment(new Date());
	switch (date.day()) {
		case 5:
			return date.add(2, 'day').toDate();
		case 6:
			return date.add(1, 'day').toDate();
		default:
			return date.toDate();
	}
};
