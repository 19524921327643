import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import RContainer from 'components/Container/RContainer';
import { useDispatch, useSelector, shallowEqual } from 'store';
import vendorActions from 'features/Vendor/vendor.actions';
import RCard from 'components/Card/RFade';
import RImageAndLabel from 'components/ImageAndLabel/RImageAndLabel';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { HoverItem, Spacer } from 'components/general';
import { BaseRoute } from 'framework/Routes/Base.route';
import { MyServicesPageRoute } from 'framework/Routes/MyServicesPage.route';
import { resetCurrentVendor } from 'store/slices/vendor.slice';
import { VendorCategory } from 'models/vendor';
import { FoodDashboardPageRoute } from 'framework/Routes/FoodDashboardPage.route';
import { RestaurantImage } from 'components/Icons/Icons';
import { vendorsByCategorySelector } from 'store/selectors/vendor.selectors';
import * as AppSelector from 'store/selectors/app.selector';
import { Role } from 'enum/role.enum';

const DashboardPage: React.FC = () => {
	const vendorsByCategory = useSelector(vendorsByCategorySelector, shallowEqual);
	const role = useSelector(AppSelector.roleSelector);
	const dispatch = useDispatch();

	useEffect(() => {
		if (vendorsByCategory.length === 0) {
			dispatch(vendorActions.getAllVendorsAction());
		}
		dispatch(resetCurrentVendor());
	}, [dispatch, vendorsByCategory]);

	const handleVendorClick = (vendorId: string) => {
		BaseRoute.navigateTo(new MyServicesPageRoute(), [vendorId]);
	};

	const handleFoodClick = () => {
		BaseRoute.navigateTo(new FoodDashboardPageRoute(), []);
	};

	return (
		<RContainer>
			<Spacer />
			{/* Display one box of food to lead to food dashboard */}
			{(role.includes(Role.Admin) || role.includes(Role.BusinessAdmin) || role.includes(Role.FoodAdmin)) && (
				<>
					<Grid container spacing={3}>
						<Grid item xs={4} sm={4}>
							<HoverItem>
								<RCard onClick={handleFoodClick} height={50} padding={0}>
									<RImageAndLabel label={VendorCategory.FOOD} element={RestaurantImage} type={RLabelTypes.BOLD_16} />
								</RCard>
							</HoverItem>
						</Grid>
					</Grid>
					<Spacer />
				</>
			)}
			{/* Filter out the vendors with food category */}
			{vendorsByCategory
				.filter(c => c.category !== VendorCategory.FOOD)
				.map((item, index) => {
					return (
						<div key={index}>
							<RLabel value={item.category} type={RLabelTypes.BOLD_18} />
							<Grid container spacing={3}>
								{item.data.map((vendor, vIndex) => {
									return (
										<Grid key={vIndex} item xs={4} sm={4}>
											<HoverItem>
												<RCard
													onClick={() => {
														handleVendorClick(vendor.id);
													}}
													height={50}
													padding={0}
												>
													<RImageAndLabel label={vendor.type} imageUrl={vendor.thumbnail_url} type={RLabelTypes.BOLD_16} />
												</RCard>
											</HoverItem>
										</Grid>
									);
								})}
							</Grid>
							<Spacer />
						</div>
					);
				})}
		</RContainer>
	);
};

export default DashboardPage;
