import { makeStyles } from '@material-ui/core';
import React from 'react';
import { ListGroup } from 'react-bootstrap';

export interface RListGroupProps {
	horizontal: boolean;
	withMargin?: boolean;
}

const RListGroup: React.FC<RListGroupProps> = ({ horizontal, withMargin = true, children }) => {
	const classes = makeStyles(theme => ({
		root: {
			alignItems: 'center',
			'& *': {
				margin: withMargin ? 3 : 0,
			},
		},
	}))();

	return (
		<ListGroup className={classes.root} horizontal={horizontal}>
			{children}
		</ListGroup>
	);
};

export default RListGroup;
