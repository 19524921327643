import { AppDispatch, AppThunk } from 'store/store';
import { setUser, disconnect as clearUser, setOnLoading, setRelatedVendor, showSnackbar } from 'store/slices/app.slice';
import { disconnect as clearVendors } from 'store/slices/vendor.slice';
import { AuthProvider } from 'framework/AuthProvider';
import { LoginRepo } from 'features/Login/Login.repository';
import { GetLoginServerResponse } from 'features/Login/Login.types';
import { decode, JwtPayload } from 'jsonwebtoken';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { IVendor } from 'models/vendor';

const loginFirebaseAction = (): AppThunk => async (dispatch: AppDispatch) => {
	try {
		dispatch(setOnLoading(true));
		await AuthProvider.signInWithGoogle();
		const token = await AuthProvider.auth.currentUser?.getIdToken();

		if (token) {
			LoginRepo.setFBToken(token);
			dispatch(loginAPIAction());
		} else {
			dispatch(setOnLoading(false));
		}
	} catch (err) {
		dispatch(
			showSnackbar({
				isOpen: true,
				label: `${(err.response?.data?.dev_message as string) || 'Something went wrong'}`,
				severity: 'error',
			})
		);
		dispatch(setOnLoading(false));
	}
};

const loginAPIAction = (): AppThunk => async (dispatch: AppDispatch) => {
	try {
		const result: GetLoginServerResponse = await LoginRepo.signIn();
		dispatch(setUser({ user: result.profile }));
		dispatch(setOnLoading(false));
	} catch (err) {
		dispatch(
			showSnackbar({ isOpen: true, label: `${err.response?.data?.dev_message || 'Something went wrong'}`, severity: 'error' })
		);
		dispatch(setOnLoading(false));
		dispatch(logoutAction());
	}
};

const logoutAction = (): AppThunk => async dispatch => {
	try {
		LoginRepo.logout();
		dispatch(clearUser());
		dispatch(clearVendors());
	} catch (err) {
		console.log(err);
	}
};

const checkIsLoggedInAction = (): AppThunk => async dispatch => {
	try {
		const token = LoginRepo.getAPIToken();
		if (token) {
			const result = decode(token) as JwtPayload;
			dispatch(setUser({ user: result.user }));
			performAsyncAction(dispatch, LoginRepo.getRelatedVendor(), (res: IVendor[]) => {
				dispatch(setRelatedVendor(res));
			});
		} else {
			dispatch(logoutAction());
		}
	} catch (err) {
		console.log(err);
	}
};

const authActions = {
	loginFirebaseAction,
	loginAPIAction,
	logoutAction,
	checkIsLoggedInAction,
};

export default authActions;
