import BaseApi from 'framework/base.api';
import axios from 'axios';
import NetworkManager from 'framework/NetworkManager';
import { IOrder, OrderStage, SearchableOrder } from 'models/order';
import { IProductCategoryMenu } from 'models/productCategory';
import { SearchableProfile } from 'models/profile';
import { DocumentStatus } from 'models/misc';
import { Subscriber } from 'models/slot';
import { SearchableActivity } from 'models/activities';
import { WaitingList, WaitingListSubscriber } from 'models/waitingList';

export class AdminApi extends BaseApi {
	constructor() {
		super();
		this.route = this.getRoute();
	}

	getRoute(): string {
		return NetworkManager.adminEndpoint;
	}

	searchProfiles(params: {
		q: string;
		orderBy: keyof SearchableProfile;
		direction: 1 | -1;
		size: number;
		page: number;
		active?: boolean;
	}) {
		return axios.get<{ profiles: SearchableProfile[]; count: number }>(`/${this.route}/profiles/search`, { params });
	}

	searchActivities(params: { q: string; orderBy: keyof SearchableActivity; direction: 1 | -1; size: number; page: number }) {
		return axios.get<{ activities: SearchableActivity[]; count: number }>(`/${this.route}/profiles/activity-log`, { params });
	}

	updateEmployeeStatus(id: string, status: DocumentStatus) {
		return axios.put(`/${this.route}/profiles/${id}/update-status`, { status });
	}

	searchOrders(params: { q: string; orderBy: keyof SearchableOrder; direction: 1 | -1 }) {
		return axios.get<IOrder[]>(`/${this.route}/orders/search`, { params });
	}

	getReadyOrders(vendorId: string, menu: string | null, paging: { skip: number; limit: number }) {
		return axios.get(
			`/${this.route}/vendors/${vendorId}/orders/ready?menu=${menu || ''}&skip=${paging.skip}&limit=${paging.limit}`
		);
	}

	updateOrderStage(orderId: string, stage: OrderStage) {
		return axios.put(`/${this.route}/orders/${orderId}/update-stage`, { stage });
	}

	bulkUpdateOrderStage(data: { ids: string[]; stage: OrderStage }) {
		return axios.put(`/${this.route}/orders/bulk-update-stage`, data);
	}

	updateOrderStageByImage(data: FormData) {
		return axios.put(`/${this.route}/orders/update-stage-by-image-detection`, data, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		});
	}

	getSlotSubscribers(slotId: string, params: { q: string }) {
		return axios.get(`/${this.route}/slots/${slotId}/subscribers`, { params });
	}

	getWaitingListSubscribers(params: { q: string; date: string; vendorId: string }) {
		return axios.get(`/${this.route}/waiting-lists/subscribers`, { params });
	}

	addSlotSubscriber(data: Partial<Subscriber>) {
		return axios.post(`/${this.route}/subscribers/add-subscriber`, data);
	}

	addWaitingListSubscriber(
		data: Pick<WaitingListSubscriber, 'profile' | 'subscription_notes'> & Pick<WaitingList, 'date' | 'vendor'>
	) {
		return axios.post(`/${this.route}/waiting-lists/add-subscriber`, data);
	}

	updateSlotSubscriber(subscriberId: string, data: { fulfilled: boolean }) {
		return axios.put(`/${this.route}/subscribers/${subscriberId}/update-subscriber`, data);
	}

	removeSlotSubscriber(subscriberId: string, data?: Partial<Subscriber>) {
		return axios.put(`/${this.route}/subscribers/${subscriberId}/remove-subscriber`, data);
	}

	removeWaitingListSubscriber(subscriberId: string, data?: Pick<WaitingListSubscriber, 'subscription_notes'>) {
		return axios.put(`/${this.route}/waiting-list-subscribers/${subscriberId}/remove-subscriber`, data);
	}

	uploadMenu(vendorId: string, file: any) {
		const formData = new FormData();
		formData.append('file', file);
		return axios.post(`/${this.route}/vendors/${vendorId}/upload-menu`, formData, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		});
	}

	getAssociatedAdmins(vendorId: string) {
		return axios.get(`/${this.route}/vendors/${vendorId}/associated-admins`);
	}

	scanQRCode(vendorId: string, scannedProfileId: string, menu?: IProductCategoryMenu) {
		let url = `/${this.route}/vendors/${vendorId}/scan-qr-code/${scannedProfileId}`;
		if (menu) {
			url = `${url}?menu=${menu}`;
		}
		return axios.put(url);
	}

	notifySlotSubscribers(slotId: string, message: string) {
		return axios.post(`/${this.route}/slots/${slotId}/send-push`, {
			message,
		});
	}

	getProfilesFeedback(startDate: string, endDate: string) {
		return axios.get(`/${this.route}/profiles/feedback?start_date=${startDate}&end_date=${endDate}`);
	}

	getProfilesPreferences() {
		return axios.get(`/${this.route}/profiles/preferences`);
	}

	getProfilesUsage(startDate: string, endDate: string) {
		return axios.get(`/${this.route}/profiles/usage?start_date=${startDate}&end_date=${endDate}`);
	}

	getProfilesTakeawayData(startDate: string, endDate: string) {
		return axios.get(`/${this.route}/profiles/takeaway?start_date=${startDate}&end_date=${endDate}`);
	}

	getTakeawayDishCountData(startDate: string, endDate: string) {
		return axios.get(`/${this.route}/profiles/takeaway-dish-count?start_date=${startDate}&end_date=${endDate}`);
	}

	getProfilesPenalties(month: number, year: number) {
		return axios.get(`/${this.route}/profiles/penalties?month=${month}&year=${year}`);
	}

	getHealth() {
		return axios.get('/api/health');
	}
}
