import { makeStyles, Grid, Dialog, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import React from 'react';
import { Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import dateFormat from 'dateformat';
import RSmallTextField from 'components/SmallTextField/RSmallTextField';
import useDebounce from 'hooks/useDebounce';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { AdminApi } from 'framework/API/admin.api';
import { shallowEqual, useDispatch, useSelector } from 'store/store';
import { onLoadingSelector, roleSelector } from 'store/selectors/app.selector';
import styled from 'styled-components';
import { SearchableProfile } from 'models/profile';
import { Role } from 'enum/role.enum';
import SubscribersList from './SubscribersList';
import EmployeesList from './EmployeesList';
import AddSubscriberForm from './AddSubscriberForm';
import { showSnackbar } from 'store/slices/app.slice';
import { Resources } from 'enum/resources.enum';
import RemoveSubscriberForm from './RemoveSubscriberForm';
import { WaitingList, WaitingListSubscriber } from 'models/waitingList';
import { currentVendorSelector } from 'store/selectors/vendor.selectors';
import moment from 'moment';

export const useStyles = makeStyles(theme => ({
	root: {
		padding: 30,
	},
}));

export interface IRSlotSubscribersDialogProps {
	waitingList: WaitingList | null;
	date: Date;
	initialAddMode?: boolean;
	open: boolean;
	onClose: () => void;
}

const WaitingListSubscribersDialog: React.FC<IRSlotSubscribersDialogProps> = ({
	waitingList,
	date,
	open,
	initialAddMode = false,
	onClose,
}) => {
	const currentVendor = useSelector(currentVendorSelector, shallowEqual);
	const classes = useStyles();
	const dispatch = useDispatch();
	const isLoading = useSelector(onLoadingSelector);
	const roles = useSelector(roleSelector);
	const [mode, setMode] = React.useState<'add' | 'remove' | null>(initialAddMode ? 'add' : null);
	const [search, setSearch] = React.useState<string>('');
	const [employees, setEmployees] = React.useState<SearchableProfile[]>([]);
	const [selectedProfile, setSelectedProfile] = React.useState<SearchableProfile | null>(null);
	const q = useDebounce(search);

	const canAddOrRemoveSubscribers = React.useMemo(
		() => roles.includes(Role.Admin) || roles.includes(Role.BusinessAdmin),
		[roles]
	);

	const onModeChange = (value: 'add' | 'remove' | null, profile: SearchableProfile | null = null) => {
		setMode(value);
		setSelectedProfile(profile);
	};

	const onSelectProfile = (profile: SearchableProfile | null) => {
		setSelectedProfile(profile);
		setMode(prev => (prev === 'remove' ? null : prev));
	};

	const fetchEmployees = React.useCallback(
		() =>
			performAsyncAction(
				dispatch,
				new AdminApi().searchProfiles({
					q,
					orderBy: 'name',
					direction: 1,
					size: 10,
					page: 0,
					active: true,
				}),
				(res: { profiles: SearchableProfile[]; count: number }) => setEmployees(res.profiles)
			),
		[dispatch, q]
	);

	const addSubscriber = (data: Pick<WaitingListSubscriber, 'subscription_notes'>) => {
		performAsyncAction(
			dispatch,
			new AdminApi().addWaitingListSubscriber({
				...data,
				vendor: waitingList?.vendor?.toString?.() ?? currentVendor?.vendor?.id,
				date: moment(date).format('YYYY-MM-DD'),
				profile: selectedProfile.id,
			}),
			() => {
				setMode(null);
				setSelectedProfile(null);
				setSearch('');
				dispatch(showSnackbar({ isOpen: true, label: Resources.SUBSCRIBER_FORM_ADD_SUCCESS, severity: 'success' }));
			}
		);
	};

	const removeSubscriber = (subscriberId: string, reason: string) =>
		performAsyncAction(dispatch, new AdminApi().removeWaitingListSubscriber(subscriberId, { subscription_notes: reason }), () => {
			setMode(null);
			setSelectedProfile(null);
			dispatch(showSnackbar({ isOpen: true, label: Resources.SUBSCRIBER_FORM_REMOVE_SUCCESS, severity: 'success' }));
		});

	React.useEffect(() => {
		if (canAddOrRemoveSubscribers) fetchEmployees();
	}, [canAddOrRemoveSubscribers, fetchEmployees, roles]);

	const modeTitle = {
		add: ' (Adding a new subscriber)',
		remove: ' (Removing a subscriber)',
		default: '',
	}[mode ?? 'default'];

	return (
		<Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
			<Container className={classes.root}>
				<RLabel value={`Waiting List${modeTitle}`} type={RLabelTypes.BOLD_18} />
				<RLabel value={dateFormat(waitingList?.date ?? date, 'mmm d, yyyy') || ''} color="textSecondary" />
				<Spacer />
				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item xs={5}>
						<RSmallTextField
							dense
							disabled={Boolean(selectedProfile)}
							onChange={value => {
								setSelectedProfile(null);
								setSearch(value);
							}}
							value={search}
							placeholder={`${mode ? 'Add' : 'Search for'} subscribers`}
							width={207}
						/>
					</Grid>
					<Grid item xs={3}>
						{canAddOrRemoveSubscribers && (
							<Tooltip enterDelay={100} title={mode ? 'Show subscribers' : 'Add new subscriber'}>
								<IconButton
									disabled={mode === 'add' && !waitingList?.unfulfilled_subscribers_amount}
									color="primary"
									onClick={() => onModeChange(['add', 'remove'].includes(mode) ? null : 'add')}
								>
									{mode ? <ListIcon /> : <AddIcon />}
								</IconButton>
							</Tooltip>
						)}
					</Grid>
					<Grid item xs={4}>
						{isLoading && <CircularProgress size={32} />}
					</Grid>
				</Grid>

				<Spacer />

				{canAddOrRemoveSubscribers && mode ? (
					<>
						<EmployeesList
							items={selectedProfile ? [selectedProfile] : employees}
							selectedProfile={selectedProfile}
							onSelect={onSelectProfile}
						/>
						{selectedProfile && mode === 'add' && <AddSubscriberForm onSubmit={addSubscriber} />}
						{selectedProfile && mode === 'remove' && (
							<RemoveSubscriberForm
								onSubmit={(subscriptionNotes: string) => removeSubscriber(selectedProfile.id, subscriptionNotes)}
							/>
						)}
					</>
				) : (
					<SubscribersList
						subscribers={waitingList?.subscribers ?? []}
						date={date}
						q={q}
						canRemoveSubscriber={canAddOrRemoveSubscribers}
						onRemove={(profile: SearchableProfile) => onModeChange('remove', profile)}
					/>
				)}

				<Spacer />
			</Container>
		</Dialog>
	);
};

export default WaitingListSubscribersDialog;

const Container = styled.div`
	.small-field-textfield-container .small-text-field {
		margin-left: 0;
	}
`;
