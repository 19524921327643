import { Grid, makeStyles, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import RCard from 'components/Card/RFade';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Resources } from 'enum/resources.enum';

export const useStyles = makeStyles(theme => ({
	center: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	subtitle: {
		color: theme.palette.primary.main,
	},
}));
export interface RCreateServiceCardProps {
	onClick: () => void;
}

const RCreateServiceCard: React.FC<RCreateServiceCardProps> = (props: RCreateServiceCardProps) => {
	return (
		<RCard>
			<Grid container direction="column" justifyContent="center" alignItems="center">
				<Fab onClick={props.onClick} color="inherit" aria-label="add">
					<AddIcon />
				</Fab>
				<RLabel value={Resources.SERVICE_CARD_CREATE_BUTTON} type={RLabelTypes.BOLD_16} />
			</Grid>
		</RCard>
	);
};

export default RCreateServiceCard;
