import React from 'react';
import styled from 'styled-components';
import { Resources } from 'enum/resources.enum';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { useDispatch } from 'store/store';
import useDebounce from 'hooks/useDebounce';
import { AdminApi } from 'framework/API/admin.api';
import RContainer from 'components/Container/RContainer';
import { Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import RSmallTextField from 'components/SmallTextField/RSmallTextField';
import Table from 'components/Table';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { CSVLink } from 'react-csv';
import { SearchableActivity } from 'models/activities';

const headers = [
	{ key: 'username', label: Resources.ACTIVITY_LOG_SCREEN_HEADER_CELL_1, sortable: true },
	{ key: 'email', label: Resources.ACTIVITY_LOG_SCREEN_HEADER_CELL_2, sortable: true },
	{ key: 'permission', label: Resources.ACTIVITY_LOG_SCREEN_HEADER_CELL_3, sortable: true },
	{ key: 'activity', label: Resources.ACTIVITY_LOG_SCREEN_HEADER_CELL_4, sortable: true },
	{ key: 'date', label: Resources.ACTIVITY_LOG_SCREEN_HEADER_CELL_5, sortable: true },
];

const ActivityLogPage = () => {
	const [search, setSearch] = React.useState<string>('');
	const [orderBy, setOrderBy] = React.useState<keyof SearchableActivity>('date');
	const [direction, setDirection] = React.useState<'asc' | 'desc'>('desc');
	const q = useDebounce(search);
	const [activities, setActivities] = React.useState<{ results: SearchableActivity[]; count: number } | null>(null);
	const [page, setPage] = React.useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, (activities?.count ?? 0) - page * rowsPerPage);

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	const dispatch = useDispatch();

	const fetchActivities = React.useCallback(
		() =>
			performAsyncAction(
				dispatch,
				new AdminApi().searchActivities({
					q,
					orderBy,
					direction: direction === 'asc' ? 1 : -1,
					size: rowsPerPage,
					page,
				}),
				(res: { results: SearchableActivity[]; count: number }) => setActivities(res)
			),
		[direction, dispatch, orderBy, page, q, rowsPerPage]
	);

	React.useEffect(() => {
		fetchActivities();
	}, [fetchActivities]);

	const onRequestSort = (_event: React.MouseEvent<unknown>, property: keyof SearchableActivity) => {
		setDirection(prev => (property !== orderBy || prev === 'desc' ? 'asc' : 'desc'));
		setOrderBy(property);
		setPage(0);
	};

	const onSearch = (value: string) => {
		setSearch(value);
	};

	React.useEffect(() => {
		setPage(0);
	}, [q]);

	return (
		<RContainer>
			<Spacer />
			<RLabel value={Resources.ACTIVITY_LOG_SCREEN_TITLE} type={RLabelTypes.BOLD_25} />
			<Spacer />
			<SearchContainer>
				<RSmallTextField dense onChange={onSearch} value={search} placeholder="Search by any field..." width={200} />

				<RButton
					disabled={!activities?.results?.length}
					removeMargin
					theme={RButtonTheme.DARK}
					label={
						<CSVLink
							style={{ color: 'inherit', textDecoration: 'none' }}
							filename={`Activity log page ${page + 1} (${
								activities?.results?.length ?? 0
							} records) - ${new Date().toDateString()}`}
							data={activities?.results ?? []}
						>
							{Resources.REPORTS_EXPORT_ACTION_TITLE}
						</CSVLink>
					}
				/>
			</SearchContainer>
			<Spacer />
			<Table.Container>
				<Table.Head>
					<Table.HeadRow>
						{headers.map(header =>
							header.sortable ? (
								<Table.SortableCell
									key={header.key}
									orderBy={orderBy}
									order={direction}
									itemKey={header.key}
									style={{ width: '20%' }}
									onRequestSort={onRequestSort}
								>
									<RLabel type={RLabelTypes.BOLD_16} value={header.label} />
								</Table.SortableCell>
							) : (
								<Table.Cell key={header.label} style={{ width: '20%' }}>
									<RLabel type={RLabelTypes.BOLD_16} value={header.label} />
								</Table.Cell>
							)
						)}
					</Table.HeadRow>
				</Table.Head>
				<Table.Body>
					{activities?.results?.map(activity => (
						<Table.Row key={activity.id}>
							<Table.Cell>{activity.username}</Table.Cell>
							<Table.Cell>{activity.email}</Table.Cell>
							<Table.Cell>{activity.permission}</Table.Cell>
							<Table.Cell>{activity.activity}</Table.Cell>
							<Table.Cell>{activity.date}</Table.Cell>
						</Table.Row>
					))}
					{emptyRows > 0 && (
						<Table.Row style={{ height: 53 * emptyRows }}>
							<Table.Cell colSpan={6} />
						</Table.Row>
					)}
				</Table.Body>
				<Table.Pagination
					count={activities?.count ?? 0}
					page={page}
					rowsPerPage={rowsPerPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Table.Container>
		</RContainer>
	);
};

export default ActivityLogPage;

const SearchContainer = styled.div.attrs({ className: 'search-container' })`
	display: flex;
	align-items: center;
	justify-content: space-between;
	.small-text-field {
		width: 100% !important;
		align-items: flex-start;
		margin: 0;

		.small-field-textfield-container {
			> .list-group-horizontal {
				align-items: flex-start !important;
			}
			width: 100% !important;
		}
		input {
			width: 400px !important;
		}
	}
`;
