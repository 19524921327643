import { AppDispatch, AppThunk } from 'store/store';
import { setOnLoading, showSnackbar } from 'store/slices/app.slice';
import { VendorsApi } from 'framework/API/vendor.api';
import {
	GetVendorResponse,
	GetVendorsResponse,
	removeSlotType,
	setCurrentSlotsScheduler,
	setCurrentVendor,
	setVendors,
	updateSlotsTypes,
} from 'store/slices/vendor.slice';
import { SlotsTypesApi } from 'framework/API/slots-types.api';
import { ISlotType, ISlotTypeParams } from 'models/slotType';
import { Resources } from 'enum/resources.enum';
import { AxiosResponse } from 'axios';
import { BaseRoute } from 'framework/Routes/Base.route';
import { MyServicesPageRoute } from 'framework/Routes/MyServicesPage.route';
import { ScheduleSlotsPageRoute } from 'framework/Routes/ScheduleSlotsPage.route';
import { ISlotsScheduleTemplate } from 'features/ScheduleSlotsPage/ScheduleSlotsPage.types';
import { ScheduleSlotsApi } from 'framework/API/slots-scheduler-template.api';
import { ManageSlotsPageRoute } from 'framework/Routes/ManageSlotsPage.route';
import authActions from 'features/Login/auth.actions';

export const performAsyncAction = async (
	dispatch: AppDispatch,
	action: Promise<AxiosResponse<any>>,
	onFinish?: (res: any) => void,
	onError?: () => void
) => {
	try {
		dispatch(setOnLoading(true));
		const res = (await action).data;
		dispatch(setOnLoading(false));
		onFinish?.(res);
	} catch (err) {
		console.log(err);
		dispatch(setOnLoading(false));
		dispatch(
			showSnackbar({ isOpen: true, label: `${err.response?.data?.message || 'Something went wrong'}`, severity: 'error' })
		);
		if (err.response?.status === 401) {
			dispatch(authActions.logoutAction());
		} else {
			onError?.();
		}
	}
};

const getAllVendorsAction = (): AppThunk => async (dispatch: AppDispatch) => {
	performAsyncAction(dispatch, new VendorsApi().read(), (res: GetVendorsResponse) => {
		dispatch(setVendors(res));
	});
};

const getMyServicesAction =
	(vendorId: string): AppThunk =>
	async (dispatch: AppDispatch) => {
		performAsyncAction(dispatch, new VendorsApi().readById(vendorId), (res: GetVendorResponse) => {
			dispatch(setCurrentVendor(res));
		});
	};

const updateServiceAction =
	(id: string, slotType: ISlotTypeParams): AppThunk =>
	async (dispatch: AppDispatch) => {
		performAsyncAction(dispatch, new SlotsTypesApi().update(id, { ...slotType }), (res: ISlotType) => {
			dispatch(showSnackbar({ isOpen: true, label: Resources.SERVICE_SCREEN_SUCCESS_EDIT, severity: 'success' }));
			dispatch(updateSlotsTypes(res));
			BaseRoute.navigateTo(new MyServicesPageRoute(), [res.vendor]);
		});
	};

const createServiceAction =
	(slotType: ISlotTypeParams): AppThunk =>
	async (dispatch: AppDispatch) => {
		performAsyncAction(dispatch, new SlotsTypesApi().create({ ...slotType }), (res: ISlotType) => {
			dispatch(showSnackbar({ isOpen: true, label: Resources.SERVICE_SCREEN_SUCCESS_CREATE, severity: 'success' }));
			dispatch(updateSlotsTypes(res));
			BaseRoute.navigateTo(new ScheduleSlotsPageRoute(), [res.vendor, res.id]);
		});
	};

const deleteServiceAction =
	(slot: ISlotType): AppThunk =>
	async (dispatch: AppDispatch) => {
		performAsyncAction(dispatch, new SlotsTypesApi().delete(slot.id), () => {
			dispatch(showSnackbar({ isOpen: true, label: Resources.SERVICE_SCREEN_SUCCESS_DELETE, severity: 'success' }));
			dispatch(removeSlotType(slot));
		});
	};

/**
 *
 * @param slotType each vendor has few slot types (services)
 * @returns slots scheduler template for a given slot type
 */
const getScheduleSlotsTemplateAction =
	(slotTypeId: string): AppThunk =>
	async (dispatch: AppDispatch) => {
		performAsyncAction(dispatch, new SlotsTypesApi().getSlotsSchedulerTemplate(slotTypeId), (res: ISlotsScheduleTemplate) => {
			dispatch(setCurrentSlotsScheduler(res));
		});
	};

const publishScheduleSlotsTemplateAction =
	(vendorId: string, startDate: Date, scheduleTemplate: ISlotsScheduleTemplate): AppThunk =>
	async (dispatch: AppDispatch) => {
		const scheduleApi = new ScheduleSlotsApi();
		//first we update the template if an id is provided or create a template if there is no id provided
		const updateOrCreateAction = scheduleTemplate.id
			? scheduleApi.update(scheduleTemplate.id, scheduleTemplate)
			: scheduleApi.create(scheduleTemplate);

		performAsyncAction(dispatch, updateOrCreateAction, (res: ISlotsScheduleTemplate) => {
			//second we publish the template to create new slots
			const publishAction = scheduleApi.publishTemplate(res.id || '', startDate);
			performAsyncAction(dispatch, publishAction, response => {
				dispatch(showSnackbar({ isOpen: true, label: Resources.SERVICE_SCREEN_SUCCESS_EDIT, severity: 'success' }));
				BaseRoute.navigateTo(new ManageSlotsPageRoute(), [vendorId]);
			});
		});
	};

const vendorActions = {
	getAllVendorsAction,
	getMyServicesAction,
	updateServiceAction,
	createServiceAction,
	deleteServiceAction,
	getScheduleSlotsTemplateAction,
	publishScheduleSlotsTemplateAction,
};

export default vendorActions;
