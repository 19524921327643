import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'store';
import { useParams } from 'react-router-dom';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import { SmallSpacer, Spacer } from 'components/general';
import { Resources } from 'enum/resources.enum';
import RDivider from 'components/Divider/RDivider';
import RFade from 'components/Fade/RFade';
import { ISlotType } from 'models/slotType';
import RCard from 'components/Card/RFade';
import { Form } from 'react-bootstrap';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import vendorActions, { performAsyncAction } from 'features/Vendor/vendor.actions';
import { IVendorParams } from 'features/MyServicesPage/MyServicesPage';
import { BaseRoute } from 'framework/Routes/Base.route';
import { MyServicesPageRoute } from 'framework/Routes/MyServicesPage.route';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import RImageAndLabel from 'components/ImageAndLabel/RImageAndLabel';
import { RServiceCardProps } from 'features/MyServicesPage/ServiceCard/RServiceCard';
import { IAdmin } from 'models/admin';
import { AdminApi } from 'framework/API/admin.api';
import { Role } from 'enum/role.enum';
import RSelect from 'components/Select/RSelect';
import { currentVendorSelector } from 'store/selectors/vendor.selectors';
import { roleSelector, userSelector } from 'store/selectors/app.selector';

const useStyles = makeStyles(theme => ({
	card: {},
	form: {},
	buttons: {
		float: 'right',
	},
}));

export type IServiceParams = IVendorParams & Record<'index', string>;

const ServicePage: React.FC = () => {
	const { index, vendorId } = useParams<IServiceParams>();
	const currentVendor = useSelector(currentVendorSelector, shallowEqual);
	const user = useSelector(userSelector, shallowEqual);
	const role = useSelector(roleSelector);
	const [vendorAdmins, setVendorAdmins] = useState<IAdmin[]>([]);
	const [selectedOperatorId, setSelectedOperatorId] = useState<string>();
	const [currentService, setService] = useState<RServiceCardProps>({
		index: 0,
		header: '',
		imageUrl: '',
		imageLabel: '',
		description: '',
	});
	const [slotType, setSlotType] = useState<ISlotType>();
	const classes = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!currentVendor.vendor) {
			dispatch(vendorActions.getMyServicesAction(vendorId));
		}
	}, [dispatch, vendorId, currentVendor.vendor]);

	useEffect(() => {
		const numIndex = Number(index);
		const newSlot = currentVendor.slots_types[numIndex];
		setSlotType(newSlot);
		setService({
			index: numIndex,
			header: newSlot?.name,
			imageUrl: newSlot?.operator?.picture || user?.picture || '',
			imageLabel: newSlot?.operator?.name || user?.name || '',
			description: newSlot?.description,
		});

		if (currentVendor?.vendor?.id && role.includes(Role.Admin)) {
			performAsyncAction(dispatch, new AdminApi().getAssociatedAdmins(currentVendor.vendor.id), (res: IAdmin[]) => {
				setVendorAdmins(res);
				const selectedAdmin = res.find(a => {
					return a.profile?._id === newSlot?.operator?._id;
				});
				setSelectedOperatorId(selectedAdmin?.profile?._id || (res[0] && res[0].profile?._id));
			});
		}
	}, [role, user?.name, user?.picture, dispatch, index, currentVendor]);

	const navigateBack = () => {
		BaseRoute.navigateTo(new MyServicesPageRoute(), [vendorId]);
	};

	const getResources = () => {
		return {
			title: slotType ? Resources.SERVICE_SCREEN_EDIT_TITLE : Resources.SERVICE_SCREEN_CREATE_TITLE,
		};
	};

	const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		if (slotType) {
			//means we are editing some service
			dispatch(
				vendorActions.updateServiceAction(slotType.id, {
					name: currentService.header,
					description: currentService.description,
					operator: selectedOperatorId || user?.id || '',
				})
			);
		} else {
			//means we are creating a new service
			dispatch(
				vendorActions.createServiceAction({
					name: currentService.header,
					description: currentService.description,
					operator: selectedOperatorId || user?.id || '',
					vendor: vendorId,
				})
			);
		}
	};

	const resources = getResources();

	return (
		<RFade>
			<Container maxWidth="md">
				<Spacer />
				<Button onClick={navigateBack}>Back</Button>
				<Spacer />
				<RCard>
					<div className={classes.card}>
						{/* Header elements */}
						<RLabel value={resources.title} type={RLabelTypes.BOLD_25} />
						<RLabel value={Resources.SERVICE_SCREEN_SUBTITLE} type={RLabelTypes.REGULAR_16} />
						<RDivider />
						{/* Form elements */}
						<Form>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Form.Group>
										{/* Service Name Field */}
										<Form.Label>
											<RLabel value={Resources.SERVICE_SCREEN_FIELD_1_LABEL} type={RLabelTypes.BOLD_18} />
										</Form.Label>
										<Form.Control
											maxLength={30}
											defaultValue={currentService.header}
											onChange={e => {
												setService({ ...currentService, header: e.target.value });
											}}
											placeholder={Resources.SERVICE_SCREEN_FIELD_1_PLACEHOLDER}
										/>
									</Form.Group>
								</Grid>
								<Grid item xs={6}>
									<RLabel value={Resources.SERVICE_SCREEN_FIELD_2_LABEL} type={RLabelTypes.BOLD_18} />
									<SmallSpacer />
									{role.includes(Role.Admin) && vendorAdmins.length > 0 ? (
										<RSelect
											onChange={id => {
												setSelectedOperatorId(id);
											}}
											minWidth={200}
											options={vendorAdmins.map(a => {
												return {
													label: a.profile?.name ?? a.email,
													value: a.profile?._id,
													selected: selectedOperatorId === a.profile?._id,
													disabled: !a.profile?._id,
												};
											})}
										/>
									) : (
										<RImageAndLabel imageUrl={currentService.imageUrl} label={currentService.imageLabel} />
									)}
								</Grid>
							</Grid>
							<Spacer />
							{/* Service Description Field */}
							<Form.Group>
								<Form.Label>
									<RLabel value={Resources.SERVICE_SCREEN_FIELD_3_LABEL} type={RLabelTypes.BOLD_18} />
								</Form.Label>
								<Form.Control
									maxLength={150}
									defaultValue={currentService.description}
									onChange={e => {
										setService({ ...currentService, description: e.target.value });
									}}
									as="textarea"
									placeholder={Resources.SERVICE_SCREEN_FIELD_3_PLACEHOLDER}
									rows={3}
								/>
							</Form.Group>
							<Spacer />

							<div className={classes.buttons}>
								<RButton label="Cancel" theme={RButtonTheme.LIGHT} onClick={navigateBack} />
								<RButton
									type="submit"
									disabled={!(currentService?.header?.length > 0)}
									label={slotType ? 'Save' : 'Create'}
									theme={RButtonTheme.DARK}
									onClick={handleSubmit}
								/>
							</div>
							<Spacer />
						</Form>
					</div>
				</RCard>
			</Container>
		</RFade>
	);
};

export default ServicePage;
