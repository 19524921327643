import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import MyServicesPage from 'features/MyServicesPage/MyServicesPage';

export class MyServicesPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.MY_SERVICES;
	}

	getParams(): string[] {
		return ['vendorId'];
	}

	getComponent(): React.FC<any> {
		return MyServicesPage;
	}
}
