import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import ManageSlotsPage from 'features/ManageSlotsPage/ManageSlotsPage';

export class ManageSlotsPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.MANAGE_SLOTS;
	}

	getParams(): string[] {
		return ['vendorId'];
	}

	getComponent(): React.FC<any> {
		return ManageSlotsPage;
	}
}
