import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRSnackbarProps } from 'components/Snackbar/RSnackbar';
import { Role } from 'enum/role.enum';
import { IProfile } from 'models/profile';
import { IVendor } from 'models/vendor';

export interface IAppState {
	isConnected: boolean;
	role: Role[];
	user: IProfile | null;
	onLoading: boolean;
	snackbar: IRSnackbarProps;
	related_vendor: IVendor[];
}

const defaultState: IAppState = {
	isConnected: false,
	onLoading: false,
	role: [Role.NONE],
	related_vendor: [],
	user: null,
	snackbar: { isOpen: false },
};

export const appSlice = createSlice({
	name: 'app',
	initialState: defaultState,
	reducers: {
		disconnect: (state: IAppState) => {
			state.isConnected = false;
			state.user = null;
			state.role = [Role.NONE];
			state.related_vendor = [];
			state.snackbar = { isOpen: false };
		},
		setRole: (state: IAppState, action: PayloadAction<{ role: Role }>) => {
			state.role = state.role.includes(action.payload.role)
				? state.role.filter(role => role !== action.payload.role)
				: [...state.role, action.payload.role];
		},
		setOnLoading: (state: IAppState, action: PayloadAction<boolean>) => {
			state.onLoading = action.payload;
		},
		showSnackbar: (state: IAppState, action: PayloadAction<IRSnackbarProps>) => {
			state.snackbar = action.payload;
		},
		setUser: (state: IAppState, action: PayloadAction<{ user: IProfile }>) => {
			state.isConnected = true;
			state.role = action.payload.user.role;
			state.user = action.payload.user;
		},
		setRelatedVendor: (state: IAppState, action: PayloadAction<IVendor[]>) => {
			state.related_vendor = action.payload;
		},
	},
});

export const { disconnect, setRole, setUser, setOnLoading, showSnackbar, setRelatedVendor } = appSlice.actions;

export default appSlice.reducer;
