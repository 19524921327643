import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import LoginPage from 'features/Login/Login';

export class LoginPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.LOGIN;
	}

	getParams(): string[] {
		return [];
	}

	getComponent(): React.FC<any> {
		return LoginPage;
	}

	isConnected(): boolean {
		return false;
	}
}
