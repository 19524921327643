export enum Resources {
	EDIT = 'Edit',
	DELETE = 'Delete',
	ACTIVATE = 'Activate',
	DEACTIVATE = 'Set Inactive',
	INACTIVE = 'Inactive',
	UPDATE = 'Update',
	ARRIVED = 'Arrived',
	DEFAULT_OPTION = 'Default',
	SCAN_SUCCEED = 'Scanning succeed',
	FROM = 'From',
	TO = 'To',
	MONTH = 'Month',
	YEAR = 'Year',
	YES = 'Yes',
	NO = 'No',
	START_DATE = 'Start time:',
	END_DATE = 'End time:',
	ADD = 'Add',
	ADD_SUBSCRIBER = 'Add subscriber',
	REMOVE = 'Remove',
	VIEW = 'View',

	LOGIN_SCREEN_SUBTITLE = 'Sign in to Rapyd world',

	SERVICE_CARD_CREATE_BUTTON = 'Create service',

	SERVICE_SCREEN_CREATE_TITLE = 'Create your service',
	SERVICE_SCREEN_EDIT_TITLE = 'Edit your service',
	SERVICE_SCREEN_SUBTITLE = 'here you can tell about the services you providing, this information will be displayed at Rapyd’s app and can be edit any time from the service page',
	SERVICE_SCREEN_FIELD_1_LABEL = 'Service title',
	SERVICE_SCREEN_FIELD_1_PLACEHOLDER = 'Enter a name for your service',
	SERVICE_SCREEN_FIELD_2_LABEL = 'Operator',
	SERVICE_SCREEN_FIELD_3_LABEL = 'Service description',
	SERVICE_SCREEN_FIELD_3_PLACEHOLDER = 'Write something about the service...',

	SERVICE_SCREEN_SUCCESS_CREATE = 'Create success',
	SERVICE_SCREEN_SUCCESS_EDIT = 'Update success',
	SERVICE_SCREEN_SUCCESS_DELETE = 'Delete success',

	MY_SERVICE_SCREEN_TITLE = 'Services',
	MY_SERVICE_SCREEN_SUBTITLE = 'Here you will see all the services that you provide as',
	MY_SERVICE_SCREEN_EMPTY_STATE_DESCRIPTION = 'There are no services, yet.',
	MY_SERVICE_SCREEN_DESCRIPTION_LABEL = 'Description',
	MY_SERVICE_SCREEN_BOOKING_POLICY_LABEL = 'Booking Policy',
	MY_SERVICE_SCREEN_BOOKING_POLICY_SUBTITLE = 'Choose the frequency of subscriptions to this service per user',

	MENUS_SCREEN_SUBTITLE = 'Here you can edit the info text which will appear in the app. Only super admin can edit this text.',
	MENUS_SCREEN_DESCRIPTION_FIELD_PLACE_HOLDER = 'We brew our coffee daily from 8:00 to 18:00. Offering the highest quality of coffee along with a variety of freshly baked pastries every morning.',
	MENUS_SCREEN_MAX_ORDERS_TITLE = 'Set max orders per time range',
	MENUS_SCREEN_MAX_TA_ORDERS_TITLE = 'Set max T.A orders per time range',
	MENUS_SCREEN_MAX_ORDERS_SUBTITLE = 'Choose how many orders can be ordered for 15 minutes slot',
	MENUS_SCREEN_TA_ORDER_SCHEDULE_TITLE = 'Order schedule',
	MENUS_SCREEN_TA_ORDER_SCHEDULE_SUBTITLE = 'Select the time slots that should be available when ordering products from this vendor (multiple selection)',
	MENUS_SCREEN_MIN = 'Min',
	MENUS_SCREEN_MAX = 'Max',
	MENUS_SCREEN_TA_ORDER_TIME_INTERVALS_TITLE = 'Time Intervals',
	MENUS_SCREEN_CANCELLATION_TITLE = 'Set Cancellation time (minutes)',
	MENUS_SCREEN_CANCELLATION_SUBTITLE = 'Choose how much time in advance the user can cancel.',
	MENUS_SCREEN_SITTING_ARRANGEMENT_TITLE = 'Seating arrangements',
	MENUS_SCREEN_SITTING_ARRANGEMENT_SUBTITLE = 'Here you can manage restaurant seating arrangements, time and max participants',
	MENUS_SCREEN_SITTING_ARRANGEMENT_ACTION_BUTTON = 'Manage seatings',

	MENUS_SCREEN_MENUS_SECTION_TITLE = 'Menus',
	MENUS_SCREEN_MENUS_SECTION_CARD_ACTION_TITLE = 'View menu',
	MENUS_SCREEN_MENUS_SECTION_CARD_UPLOAD_FILE = 'Upload File',
	MENUS_SCREEN_CURRENT_DIR_LINK = 'Food Dashboard',

	CATEGORIES_SCREEN_TITLE = 'Categories',
	CATEGORIES_SCREEN_ADD_BUTTON = '+ Add New Category',
	CATEGORIES_SCREEN_EMPTY_STATE_DESCRIPTION = 'There are no categories here, yet.',
	CATEGORIES_SCREEN_CURRENT_DIR_LINK = 'Categories',
	CATEGORIES_SCREEN_CARD_ACTION_TITLE = 'View products',

	CREATE_CATEGORY_DIALOG_TITLE = 'Category',
	CREATE_CATEGORY_DIALOG_FIELD_1_LABEL = 'Category Name',
	CREATE_CATEGORY_DIALOG_FIELD_2_LABEL = 'Make this category a one-item choice.',
	CREATE_CATEGORY_DIALOG_FIELD_2_DESCRIPTION = 'It means that users can select only one item per order for this category (e.g: one coffee).',
	CREATE_CATEGORY_DIALOG_FIELD_3_LABEL = 'Make this category with recurring products',
	CREATE_CATEGORY_DIALOG_FIELD_3_DESCRIPTION = 'It means that something',
	CREATE_CATEGORY_DIALOG_PUBLISH_BUTTON = 'Publish',
	CREATE_CATEGORY_DIALOG_CANCEL_BUTTON = 'Cancel',

	PRODUCTS_SCREEN_TITLE = 'Products',
	PRODUCTS_SCREEN_ADD_BUTTON = '+ Add New Product',
	PRODUCTS_SCREEN_EMPTY_STATE_DESCRIPTION = 'There are no products here, yet.',
	PRODUCTS_SCREEN_CARD_ACTION_TITLE = 'View product',

	SCHEDULE_SLOTS_SCREEN_TITLE = 'Create Sessions',
	SCHEDULE_SLOTS_SCREEN_SUBTITLE = 'Set a range of dates and times during which invitees can schedule a session.Invitees will always see maximum 7 days’ schedules.',
	SCHEDULE_SLOTS_SCREEN_SERVICE_SELECTION_TITLE = 'Service',
	SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_TITLE = 'When can invitees schedule your sessions?',
	SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_SUBTITLE = 'Set the dates for provided sessions, choose whether to make this a single session, weekly, or 30 calendar days ahead.',
	SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_SWITCH_OPT_1 = 'Date Range',
	SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_SWITCH_OPT_2 = 'Single Day',
	SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_DATE_PICKER_LABEL = 'Start Day',
	SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_DAYS_LABEL_1 = 'Invitees can schedule',
	SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_DAYS_LABEL_2 = 'in advance',
	SCHEDULE_SLOTS_SCREEN_DURATION_TITLE = 'Session duration',
	SCHEDULE_SLOTS_SCREEN_DURATION_SUBTITLE = 'Set the session duration which will be applied to all sessions',
	SCHEDULE_SLOTS_SCREEN_DAYS_TABLE_TITLE = 'Set your weekly hours',
	SCHEDULE_SLOTS_SCREEN_DAYS_TABLE_SUBTITLE = 'Choose the days you work on, set the start and end hours, and maximum amount of participants',
	SCHEDULE_SLOTS_SCREEN_DAYS_TABLE_ADD_ROW_PLACEHOLDER = 'Add another time range to this day',
	SCHEDULE_SLOTS_SCREEN_PARTICIPANTS_TILE = 'Participants',
	SCHEDULE_SLOTS_SCREEN_PARTICIPANTS_SUBTILE = 'Choose number of participants for your session',
	SCHEDULE_SLOTS_SCREEN_LOCATION_TITLE = 'Location',
	SCHEDULE_SLOTS_SCREEN_LOCATION_SUBTITLE = 'Specify where these sessions occur',
	SCHEDULE_SLOTS_SCREEN_LOCATION_EDIT = 'Edit locations',
	SCHEDULE_SLOTS_SCREEN_PARTICIPANTS_MIN_LABEL = 'Min',
	SCHEDULE_SLOTS_SCREEN_PARTICIPANTS_MAX_LABEL = 'Max',
	SCHEDULE_SLOTS_SCREEN_PUBLISH_BUTTON = 'Publish',
	SCHEDULE_SLOTS_SCREEN_CANCEL_BUTTON = 'Cancel',
	SCHEDULE_SLOTS_SCREEN_SUBSCRIBER_IS_LATE = 'The guest is late',
	SCHEDULE_SLOTS_SCREEN_SUBSCRIBER_TIME_SLOT = 'The guest booked a reservation for the following time slot. Would you like to let him in?',

	MANAGE_SLOTS_SCREEN_DATE_LABEL = 'Date to display',
	MANAGE_SLOTS_SCREEN_SCHEDULE_BUTTON = '+ Add new Sessions',
	MANAGE_SLOTS_SCREEN_SUCCESS_DELETE = 'Delete success',
	MANAGE_SLOTS_SCREEN_SUCCESS_EDIT = 'Update success',

	EDIT_SLOT_DIALOG_TITLE = 'Edit session',
	EDIT_SLOT_DIALOG_FIELD_1_LABEL = 'Session name',
	EDIT_SLOT_DIALOG_FIELD_2_LABEL = 'Location',
	EDIT_SLOT_DIALOG_FIELD_3_LABEL = 'Date',
	EDIT_SLOT_DIALOG_FIELD_4_LABEL = 'Time',
	EDIT_SLOT_DIALOG_FIELD_5_LABEL = 'Participants',
	EDIT_SLOT_DIALOG_MESSAGE_TITLE = "Write a message to the participants about the session's changes",
	EDIT_SLOT_DIALOG_MESSAGE_SUBTITLE = 'This message will be sent to your slot participants',
	EDIT_SLOT_DIALOG_MESSAGE_PLACEHOLDER = 'Yoga class has been moved to...',
	EDIT_SLOT_DIALOG_MESSAGE_BUTTON_CONFIRM = 'Update',
	EDIT_SLOT_DIALOG_MESSAGE_BUTTON_CANCEL = 'Cancel',

	NOTIFY_SUBSCRIBERS_DIALOG_MESSAGE_BUTTON_CONFIRM = 'Delete session',
	NOTIFY_SUBSCRIBERS_DIALOG_MESSAGE_BUTTON_CANCEL = 'Cancel',
	NOTIFY_SUBSCRIBERS_DIALOG_MESSAGE_TITLE = 'Write message to all the participants:',
	NOTIFY_SUBSCRIBERS_DIALOG_MESSAGE_SUBTITLE = 'This message will be sent to your session participants',
	NOTIFY_SUBSCRIBERS_DIALOG_MESSAGE_PLACEHOLDER = 'Explain to subscribers why you are deleting this session',

	ORDER_MANAGEMENT_SCREEN_TITLE = 'Order Management',
	ORDER_MANAGEMENT_SCREEN_HEADER_CELL_1 = 'Order #',
	ORDER_MANAGEMENT_SCREEN_HEADER_CELL_2 = 'Name',
	ORDER_MANAGEMENT_SCREEN_HEADER_CELL_3 = 'Dish',
	ORDER_MANAGEMENT_SCREEN_HEADER_CELL_4 = 'Date',
	ORDER_MANAGEMENT_SCREEN_HEADER_CELL_5 = 'Action',
	ORDER_MANAGEMENT_SCREEN_ACTION = 'Scan Order Ticket',

	PRODUCT_SCREEN_CREATE_TITLE = 'New Product',
	PRODUCT_SCREEN_EDIT_TITLE = 'Edit info',
	PRODUCT_SCREEN_NAME_LABEL = 'Name',
	PRODUCT_SCREEN_DESCRIPTION_LABEL = 'Description',
	PRODUCT_SCREEN_DAILY_AMOUNT_LABEL = 'Daily amount',
	PRODUCT_SCREEN_DAILY_AMOUNT_UNLIMITED_LABEL = 'Unlimited amount',
	PRODUCT_SCREEN_DAILY_AMOUNT_DESCRIPTION = 'If the daily amount is 0, the item wont be displayed in app.',
	PRODUCT_SCREEN_MAX_QUANTITY_PER_ORDER_LABEL = 'Max quantity per order',
	PRODUCT_SCREEN_PRICE_LABEL = 'Price',
	PRODUCT_SCREEN_OPTIONS_LABEL = "Products's Options",
	PRODUCT_SCREEN_OPTION_CARD_ACTION_BUTTON = 'View option',
	PRODUCT_SCREEN_OPTIONS_DESCRIPTION = 'Add a custom set of options to an item to create variations. For example, a size option set can create variations small, medium, and large.',
	PRODUCT_SCREEN_ADD_OPTIONS_BUTTON = '+ Add set of options',
	PRODUCT_SCREEN_DISH_LABEL_LABELS = 'Dish Labels:',
	PRODUCT_SCREEN_BUTTON_CONFIRM = 'Publish',
	PRODUCT_SCREEN_BUTTON_CANCEL = 'Cancel',

	PRODUCT_SCREEN_OPTIONS_SET_DIALOG_NAME_LABEL = 'Option set name',
	PRODUCT_SCREEN_OPTIONS_SET_DIALOG_CHOICE_LABEL = 'Choice',
	PRODUCT_SCREEN_OPTIONS_SET_DIALOG_NAME_LABEL_EXAMPLE = "for example 'milk type'",
	PRODUCT_SCREEN_OPTIONS_SET_DIALOG_BUTTON_CONFIRM = 'Save',
	PRODUCT_SCREEN_OPTIONS_SET_DIALOG_BUTTON_CANCEL = 'Close',
	PRODUCT_SCREEN_OPTIONS_SET_DIALOG_ADD_CHOICE_BUTTON = '+ Add choice',

	PERMISSIONS_SCREEN_TITLE = 'Permissions',
	PERMISSIONS_SCREEN_TABLE_HEADER_CELL_1 = 'Email',
	PERMISSIONS_SCREEN_TABLE_HEADER_CELL_2 = 'Permission',
	PERMISSIONS_SCREEN_TABLE_ADD_ROW_LABEL = '+ Add',

	REPORTS_SCREEN_TITLE = 'Reports',
	REPORTS_SCREEN_SUBTITLE = 'Reports will be exported as a CSV file, directly to your computer',
	REPORTS_EXPORT_ACTION_TITLE = 'Export report',
	REPORTS_SCREEN_USERS_FEEDBACK_REPORT = 'Users feedback report',
	REPORTS_SCREEN_USERS_PREFERENCES_REPORT = 'Users preferences report',
	REPORTS_SCREEN_USERS_USAGE_REPORT = 'Users usage report ',
	REPORTS_SCREEN_USERS_PENALTIES_REPORT = 'Users penalties report ',
	REPORTS_SCREEN_USERS_TAKEAWAY_REPORT = 'Users Takeaway report ',
	REPORTS_SCREEN_TAKEAWAY_DISH_COUNT_REPORT = 'Takeaway dish count reports ',

	EMPLOYEES_SCREEN_TITLE = 'Employees',
	EMPLOYEES_SCREEN_HEADER_CELL_1 = 'Name',
	EMPLOYEES_SCREEN_HEADER_CELL_2 = 'Email',
	EMPLOYEES_SCREEN_HEADER_CELL_3 = 'First Login',
	EMPLOYEES_SCREEN_HEADER_CELL_4 = 'Status',
	EMPLOYEES_SCREEN_EXPORT_BUTTON_TEXT = 'Export Page',

	ACTIVITY_LOG_SCREEN_TITLE = 'Activity Log',
	ACTIVITY_LOG_SCREEN_HEADER_CELL_1 = 'Username',
	ACTIVITY_LOG_SCREEN_HEADER_CELL_2 = 'Email',
	ACTIVITY_LOG_SCREEN_HEADER_CELL_3 = 'Permission',
	ACTIVITY_LOG_SCREEN_HEADER_CELL_4 = 'Activity',
	ACTIVITY_LOG_SCREEN_HEADER_CELL_5 = 'Date',
	ACTIVITY_LOG_SCREEN_EXPORT_BUTTON_TEXT = 'Export Page',

	SUBSCRIBER_FORM_CONTROL_REASON_TITLE = 'Reason',
	SUBSCRIBER_FORM_CONTROL_ADD_REASON_SUBTITLE = 'Please provide a reason for adding this subscriber manually',
	SUBSCRIBER_FORM_CONTROL_REMOVE_REASON_SUBTITLE = 'Please provide a reason for removing this subscriber manually',
	SUBSCRIBER_FORM_CONTROL_REASON_PLACEHOLDER = 'e.g. The employee does not have access to the app...',
	SUBSCRIBER_FORM_ADD_SUCCESS = 'The user was added to this slot',
	SUBSCRIBER_FORM_REMOVE_SUCCESS = 'The user was removed from this slot',

	LOCATIONS_FORM_ADD_SUCCESS = 'The location was added successfully',
	LOCATIONS_FORM_REMOVE_SUCCESS = 'The location was removed successfully',
}
