import React from 'react';
import { Grid } from '@material-ui/core';
import RContainer from 'components/Container/RContainer';
import { useDispatch, useSelector } from 'store';
import { BaseRoute } from 'framework/Routes/Base.route';
import { ScheduleSlotsPageRoute } from 'framework/Routes/ScheduleSlotsPage.route';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { useParams } from 'react-router-dom';
import { IVendorParams } from 'features/MyServicesPage/MyServicesPage';
import vendorActions, { performAsyncAction } from 'features/Vendor/vendor.actions';
import { FloatRight, Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import RDatePicker from 'components/DatePicker/RDatePicker';
import { Resources } from 'enum/resources.enum';
import { ISlot, RestaurantSlotFields, RestaurantSubscriberField } from 'models/slot';
import SlotRow from 'components/SlotRow/SlotRow';
import SlotDialog from 'components/SlotDialog/RSlotDialog';
import RQRDialog from 'components/QRDialog/RQRDialog';
import { SlotsApi } from 'framework/API/slots.api';
import RListGroup from 'components/ListGroup/RListGroup';
import { showSnackbar } from 'store/slices/app.slice';
import io from 'socket.io-client';
import NetworkManager from 'framework/NetworkManager';
import { SocketEvent } from 'enum/socket-events';
import { NewServicePageRoute } from 'framework/Routes/NewServicePage.route';
import { AdminApi } from 'framework/API/admin.api';
import RNotifySubscribersMessageDialog from 'components/NotifySubscribersMessageDialog/RNotifySubscribersMessageDialog';
import { useCallback } from 'react';
import { currentVendorSelector } from 'store/selectors/vendor.selectors';
import SubscriberConfirmationDialog from 'components/SubscriberConfirmationDialog/SubscriberConfirmationDialog';
import WaitingListRow from 'components/WaitingListRow/WaitingListRow';
import { WaitingList } from 'models/waitingList';
import { WaitingListsApi } from 'framework/API/waiting-lists.api';
import dateFormat from 'dateformat';

export interface IRManageSlotsPageProps {}

const ManageSlotsPage: React.FC<IRManageSlotsPageProps> = props => {
	const currentVendor = useSelector(currentVendorSelector);
	const [startDate, setStartDate] = React.useState<Date>(new Date());
	const [slotDialog, setSlotDialog] = React.useState<{ open: boolean; slot?: ISlot }>({ open: false });
	const [QRDialogOpen, setQRDialog] = React.useState<boolean>(false);
	const [notifySubscribersDialog, setNotifySubscribersDialog] = React.useState<{ open: boolean; slot?: ISlot }>({ open: false });
	const [subscriberConfirmationDialog, setSubscriberConfirmationDialog] = React.useState<{
		slot?: ISlot;
		subscriber?: RestaurantSubscriberField;
		isOpen: boolean;
	}>({ isOpen: false });
	const [slots, setSlots] = React.useState<ISlot[]>([]);
	const [waitingList, setWaitingList] = React.useState<WaitingList | null>(null);
	const [monthlySlotsDates, setMonthlySlotsDates] = React.useState<Date[]>([]);
	const dispatch = useDispatch();
	const { vendorId } = useParams<IVendorParams>();
	const scheduleNewSlots = () => {
		const slotType = currentVendor.slots_types[0];
		if (slotType) BaseRoute.navigateTo(new ScheduleSlotsPageRoute(), [slotType.vendor, slotType.id]);
		else BaseRoute.navigateTo(new NewServicePageRoute(), [vendorId]);
	};

	const fetchMonthlyDates = useCallback(
		(month?: number) => {
			new SlotsApi().getMonthlyDates(vendorId, month).then(res => {
				const dates: Date[] = (res.data as any[]).map(a => new Date(a));
				setMonthlySlotsDates(dates);
			});
		},
		[vendorId]
	);

	const fetchSlots = useCallback(
		(date: Date) => {
			performAsyncAction(dispatch, new SlotsApi().getSlots(vendorId, date), (res: ISlot[]) => {
				setSlots(
					res.map(slot => ({
						...slot,
						start_date: new Date(slot.start_date),
						end_date: new Date(slot.end_date),
					}))
				);
			});
		},
		[dispatch, vendorId]
	);

	const supportsWaitingListFeature = currentVendor?.vendor?.configuration?.is_waiting_list_feature_available ?? false;

	const fetchWaitingList = useCallback(() => {
		if (currentVendor?.vendor?.id && supportsWaitingListFeature)
			performAsyncAction(
				dispatch,
				new WaitingListsApi().findOne({ date: dateFormat(startDate, 'yyyy-mm-dd'), vendor: currentVendor.vendor.id }),
				(res: WaitingList | null) => setWaitingList(res)
			);
	}, [currentVendor?.vendor?.id, dispatch, startDate, supportsWaitingListFeature]);

	const onConfirmSubscription = (subscriberId: string) =>
		performAsyncAction(
			dispatch,
			new AdminApi().updateSlotSubscriber(subscriberId, {
				fulfilled: true,
			}),
			(res: ISlot) => {
				setSubscriberConfirmationDialog({ isOpen: false });
			}
		);

	const onRemoveSubscription = (subscriberId: string, reason: string) =>
		performAsyncAction(
			dispatch,
			new AdminApi().removeSlotSubscriber(subscriberId, { subscription_notes: reason }),
			(res: ISlot) => {
				setSubscriberConfirmationDialog({ isOpen: false });
			}
		);

	React.useEffect(() => {
		const socket = io(NetworkManager.socketUrl);
		socket.on(SocketEvent.REFRESH_SLOTS, slot => {
			fetchSlots(startDate);
			fetchWaitingList();
		});

		return () => {
			socket.disconnect();
		};
	}, [fetchSlots, startDate, fetchWaitingList]);

	React.useEffect(() => {
		if (!currentVendor.vendor) dispatch(vendorActions.getMyServicesAction(vendorId));
		else fetchSlots(startDate);

		fetchMonthlyDates();
	}, [dispatch, fetchMonthlyDates, fetchSlots, startDate, vendorId, currentVendor.vendor]);

	React.useEffect(() => {
		fetchSlots(startDate);
	}, [fetchSlots, startDate]);

	React.useEffect(() => {
		fetchWaitingList();
	}, [fetchWaitingList]);

	const handleDateChange = (date: Date) => {
		setStartDate(date);
		fetchSlots(date);
	};

	const handleMonthChange = (date: Date) => {
		fetchMonthlyDates(date.getMonth());
	};

	const onRemoveSlot = (slot: ISlot) => {
		if (slot.subscribers_amount > 0) setNotifySubscribersDialog({ open: true, slot });
		else removeSlot(slot);
	};

	const handleNotifySubscribersDialogPublished = (message: string, slot: ISlot) => {
		removeSlot(slot);
		performAsyncAction(dispatch, new AdminApi().notifySlotSubscribers(slot.id, message), () => {});
	};

	const removeSlot = (slot: ISlot) => {
		performAsyncAction(dispatch, new SlotsApi().delete(slot.id), () => {
			dispatch(showSnackbar({ isOpen: true, label: Resources.MANAGE_SLOTS_SCREEN_SUCCESS_DELETE, severity: 'success' }));
			const newArray = slots.filter(s => s.id !== slot.id);
			setSlots(newArray);
		});
	};

	const onEditSlotSuccess = () => {
		fetchSlots(startDate);
	};

	const handleScan = (userId: string) => {
		performAsyncAction(dispatch, new AdminApi().scanQRCode(vendorId, userId), (slot: ISlot & RestaurantSlotFields) => {
			if (slot.is_restaurant && (slot.subscriber?.is_late || slot.subscriber?.booked_different_slot)) {
				setSubscriberConfirmationDialog({ slot: slot, subscriber: slot.subscriber, isOpen: true });
			}
		});
	};

	const waitingListDate = React.useMemo(() => {
		const date = new Date(startDate);
		return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
	}, [startDate]);

	return (
		<RContainer>
			<Spacer />
			<RListGroup horizontal>
				<RLabel value={Resources.MANAGE_SLOTS_SCREEN_DATE_LABEL} type={RLabelTypes.BOLD_18} />
				<RDatePicker highlightDates={monthlySlotsDates} onChange={handleDateChange} onMonthChange={handleMonthChange} />
			</RListGroup>
			<FloatRight>
				<RButton label={Resources.MANAGE_SLOTS_SCREEN_SCHEDULE_BUTTON} theme={RButtonTheme.DARK} onClick={scheduleNewSlots} />
				<RButton label="QR Code" theme={RButtonTheme.DARK} onClick={() => setQRDialog(true)} />
			</FloatRight>
			<Spacer />

			<Grid container spacing={3}>
				{supportsWaitingListFeature && Boolean(slots.length) && (
					<Grid item xs={12}>
						<WaitingListRow date={waitingListDate} waitingList={waitingList} />
					</Grid>
				)}
				{slots.map((slot, index) => {
					return (
						<Grid key={index} item xs={12}>
							<SlotRow
								key={index}
								index={index}
								slot={slot}
								onDeleteSlot={onRemoveSlot}
								onEditSlot={item => setSlotDialog({ open: true, slot: item })}
								fetchSlots={() => fetchSlots(startDate)}
							/>
						</Grid>
					);
				})}
			</Grid>
			<SlotDialog
				open={slotDialog.open}
				slot={slotDialog.slot}
				onEditSuccess={onEditSlotSuccess}
				onClose={() => setSlotDialog({ open: false })}
			/>
			<RQRDialog open={QRDialogOpen} onClose={() => setQRDialog(false)} onScan={handleScan} />
			<SubscriberConfirmationDialog
				slot={subscriberConfirmationDialog.slot}
				open={subscriberConfirmationDialog.isOpen}
				onClose={() => setSubscriberConfirmationDialog({ isOpen: false })}
				onConfirm={() => {
					onConfirmSubscription(subscriberConfirmationDialog.subscriber?.id);
				}}
				onReject={() =>
					onRemoveSubscription(
						subscriberConfirmationDialog.subscriber?.id,
						`${
							subscriberConfirmationDialog.subscriber.is_late ? 'Arrived late' : 'Arrived to a different slot'
						} and rejected by the host`
					)
				}
			/>
			<RNotifySubscribersMessageDialog
				open={notifySubscribersDialog.open}
				slot={notifySubscribersDialog.slot}
				onClose={() => setNotifySubscribersDialog({ open: false })}
				onPublish={handleNotifySubscribersDialogPublished}
			/>
		</RContainer>
	);
};

export default ManageSlotsPage;
