import React, { useEffect, useState } from 'react';
import { useDispatch } from 'store';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Chip, TextField } from '@material-ui/core';
import { DoubleSpacer, SmallSpacer, Spacer } from 'components/general';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Resources } from 'enum/resources.enum';
import RContainer from 'components/Container/RContainer';
import RListGroup from 'components/ListGroup/RListGroup';
import { IProductCategory } from 'models/productCategory';
import { IProduct, IProductChoiceSet } from 'models/product';
import { IProductsParams } from 'features/ProductsPage/ProductsPage';
import { ProductsApi } from 'framework/API/products.api';
import RCheckbox from 'components/Checkbox/RCheckbox';
import { DivContainer } from 'App';
import { CategoriesApi } from 'framework/API/categories.api';
import RCard from 'components/Card/RFade';
import { makeStyles } from '@material-ui/core';
import RProductOptionSetCard from 'features/ProductPage/RProductOptionSetCard';
import RProductOptionSetDialog from 'features/ProductPage/ProductOptionSetDialog/RProductOptionSetDialog';

export const useStyles = makeStyles(theme => ({
	chip: {
		backgroundColor: 'transparent',
		borderColor: theme.palette.primary.light,
		border: '1px solid',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	selectedChip: {
		backgroundColor: theme.palette.primary.light,
		borderColor: theme.palette.primary.light,
		border: '1px solid',
		'&:hover': {
			cursor: 'pointer',
		},
	},
}));

export interface IProductParams extends IProductsParams {
	productId: string;
}

const ProductPage: React.FC = () => {
	const [currentProduct, setCurrentProduct] = useState<Partial<IProduct>>({
		id: '',
		name: 'Loading...',
		description: 'No description provided',
		price: 0,
		daily_amount: 1,
		max_amount_per_order: 1,
		choices_sets: [],
		labels: [],
		unlimited: false,
	});
	const [currentCategory, setCurrentCategory] = useState<IProductCategory | null>();
	const dispatch = useDispatch();
	const { productId, categoryId, menuId } = useParams<IProductParams>();
	const history = useHistory();
	const [optionSetDialog, setOptionSetDialog] = useState<{ open: boolean; set?: IProductChoiceSet }>({ open: false });
	const classes = useStyles();

	useEffect(() => {
		if (productId) {
			performAsyncAction(dispatch, new ProductsApi().readById(productId), (res: IProduct) => {
				setCurrentProduct(res);
			});
		}
	}, [dispatch, productId]);

	useEffect(() => {
		performAsyncAction(dispatch, new CategoriesApi().readById(categoryId, { menu: menuId }), (res: IProductCategory) => {
			setCurrentCategory(res);
		});
	}, [categoryId, dispatch, menuId]);

	return (
		<RContainer>
			<Spacer />
			<Button onClick={history.goBack}>Back</Button>
			<Spacer />
			<RCard>
				<RLabel
					value={currentProduct?.id ? Resources.PRODUCT_SCREEN_EDIT_TITLE : Resources.PRODUCT_SCREEN_CREATE_TITLE}
					type={RLabelTypes.BOLD_25}
				/>
				<Spacer />
				<RLabel value={Resources.PRODUCT_SCREEN_NAME_LABEL} type={RLabelTypes.BOLD_18} />
				<SmallSpacer />
				<RLabel value={currentProduct.name} type={RLabelTypes.REGULAR_18} />
				<Spacer />

				<RLabel value={Resources.PRODUCT_SCREEN_DESCRIPTION_LABEL} type={RLabelTypes.BOLD_18} />
				<SmallSpacer />
				<RLabel value={currentProduct.description ?? 'No description provided'} type={RLabelTypes.REGULAR_16} />
				<Spacer />

				<RLabel value={Resources.PRODUCT_SCREEN_DAILY_AMOUNT_LABEL} type={RLabelTypes.BOLD_18} />
				<RLabel value={Resources.PRODUCT_SCREEN_DAILY_AMOUNT_DESCRIPTION} />
				<SmallSpacer />
				<TextField disabled value={currentProduct.daily_amount} type="number" variant="outlined" size="small" />
				<DivContainer>
					<RCheckbox
						disabled
						isChecked={currentProduct.unlimited || false}
						label={Resources.PRODUCT_SCREEN_DAILY_AMOUNT_UNLIMITED_LABEL}
						labelType={RLabelTypes.REGULAR_16}
					/>
				</DivContainer>

				{!currentCategory?.is_single_product_per_order && (
					<DivContainer>
						<RLabel value={Resources.PRODUCT_SCREEN_MAX_QUANTITY_PER_ORDER_LABEL} />
						<SmallSpacer />
						<TextField disabled value={currentProduct.max_amount_per_order} type="number" variant="outlined" size="small" />
						<Spacer />
					</DivContainer>
				)}

				<RLabel value={Resources.PRODUCT_SCREEN_PRICE_LABEL} />
				<SmallSpacer />
				<TextField disabled value={currentProduct.price} type="number" variant="outlined" size="small" />
				<Spacer />
				{Boolean(currentProduct.choices_sets?.length) && (
					<DivContainer>
						<RLabel value={Resources.PRODUCT_SCREEN_OPTIONS_LABEL} type={RLabelTypes.BOLD_18} />
						<RLabel value={Resources.PRODUCT_SCREEN_OPTIONS_DESCRIPTION} />
						<Spacer />
						{currentProduct.choices_sets?.map((set, index) => (
							<DivContainer key={index}>
								<RProductOptionSetCard set={set} onView={() => setOptionSetDialog({ open: true, set })} />
								<SmallSpacer />
							</DivContainer>
						))}
					</DivContainer>
				)}
				<Spacer />
				{Boolean(currentProduct.labels?.length) && (
					<>
						<RLabel value={Resources.PRODUCT_SCREEN_DISH_LABEL_LABELS} type={RLabelTypes.BOLD_18} />
						<RListGroup horizontal>
							{currentProduct.labels?.map((label, index) => (
								<DivContainer>
									<Chip key={index} label={label} className={classes.chip} />
								</DivContainer>
							))}
						</RListGroup>
					</>
				)}
				<DoubleSpacer />

				<RProductOptionSetDialog {...optionSetDialog} onClose={() => setOptionSetDialog({ open: false })} />
			</RCard>
		</RContainer>
	);
};

export default ProductPage;
