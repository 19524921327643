import { makeStyles, Grid } from '@material-ui/core';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { presentTime } from 'constants/dates.constants';
import { Resources } from 'enum/resources.enum';
import { RServiceCardMoreOption } from 'features/MyServicesPage/ServiceCard/RServiceCard';
import { ISlot } from 'models/slot';
import RCard from 'components/Card/RFade';
import { RightSpacer } from 'components/general';
import { TimeIcon } from 'components/Icons/Icons';
import RImageAndLabel from 'components/ImageAndLabel/RImageAndLabel';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import RMoreButton from 'components/MoreButton/RMoreButton';
import SlotSubscribersDialog from 'components/SlotSubscribersDialog/SlotSubscribersDialog';
import { useSelector } from 'store/store';
import { roleSelector } from 'store/selectors/app.selector';
import { Role } from 'enum/role.enum';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import MenuButton from 'components/MenuButton/MenuButton';

export const useStyles = makeStyles(theme => ({
	slotDetailColumn: {
		marginTop: 'auto',
		marginBottom: 'auto',
		paddingTop: 20,
	},
	alignCenter: {
		alignItems: 'center',
	},
	textButton: {
		marginTop: 20,
	},
}));

export interface IRSlotRowProps {
	slot: ISlot;
	index: number;
	onDeleteSlot: (slot: ISlot) => void;
	onEditSlot: (slot: ISlot) => void;
	fetchSlots: VoidFunction;
}

const SlotRow: React.FC<IRSlotRowProps> = ({ slot, onEditSlot, onDeleteSlot, fetchSlots }) => {
	const roles = useSelector(roleSelector);
	const [subscribersModal, setSubscribersModal] = React.useState<{ isOpen: boolean; addMode?: boolean }>({
		isOpen: false,
	});
	const classes = useStyles();
	const canAddSubscribers = !slot.is_fully_booked && (roles.includes(Role.Admin) || roles.includes(Role.BusinessAdmin));

	const slotOptions = React.useMemo(
		(): RServiceCardMoreOption[] => [
			{ title: Resources.EDIT, onSelect: () => onEditSlot(slot) },
			{ title: Resources.DELETE, onSelect: () => onDeleteSlot(slot) },
		],
		[onDeleteSlot, onEditSlot, slot]
	);

	const subscribersOptions = React.useMemo(
		(): RServiceCardMoreOption[] => [
			{ title: Resources.ADD_SUBSCRIBER, onSelect: () => setSubscribersModal({ isOpen: true, addMode: true }) },
			{ title: Resources.VIEW, onSelect: () => setSubscribersModal({ isOpen: true }) },
		],
		[]
	);

	const onCloseModal = () => {
		fetchSlots();
		setSubscribersModal({ isOpen: false });
	};

	return (
		<>
			<RCard padding={10} height={100}>
				<Grid container>
					<Grid className={classes.slotDetailColumn} item xs={6} sm={2}>
						<RLabel value={slot.type.name} type={RLabelTypes.BOLD_18} />
						{Boolean(slot.location) && <RLabel value={slot.location} type={RLabelTypes.REGULAR_16} />}
					</Grid>
					<Grid className={classes.slotDetailColumn} item xs={6} sm={3}>
						<ListGroup className={classes.alignCenter} horizontal>
							{TimeIcon}
							<RightSpacer />
							<RLabel
								includeGutterBottom={false}
								value={[presentTime(slot.start_date), presentTime(slot.end_date)].join('-')}
								type={RLabelTypes.REGULAR_16}
							/>
						</ListGroup>
					</Grid>
					<Grid className={classes.slotDetailColumn} item xs={6} sm={3}>
						{!slot.is_fully_booked && canAddSubscribers ? (
							<MenuButton
								theme={RButtonTheme.TEXT}
								options={subscribersOptions}
								label={`${slot.subscribers_amount}/${slot.capacity} (${slot.fulfilled_amount} arrived)`}
							/>
						) : (
							<RButton
								theme={RButtonTheme.TEXT}
								disabled={!slot.subscribers_amount}
								label={`${slot.subscribers_amount}/${slot.capacity} (${slot.fulfilled_amount} arrived)`}
								onClick={() => setSubscribersModal({ isOpen: true })}
							/>
						)}
					</Grid>
					<Grid className={classes.slotDetailColumn} item xs={6} sm={3}>
						<RImageAndLabel imageUrl={slot.type.operator.picture} label={slot.type.operator.name} />
					</Grid>
					<Grid item xs={6} sm={1}>
						<RMoreButton disabled={slot.start_date < new Date()} options={slotOptions} />
					</Grid>
				</Grid>
			</RCard>
			{subscribersModal.isOpen && (
				<SlotSubscribersDialog
					open={subscribersModal.isOpen}
					initialAddMode={subscribersModal.addMode}
					slot={slot}
					onClose={onCloseModal}
				/>
			)}
		</>
	);
};

export default SlotRow;
