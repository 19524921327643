import { CardContent } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'store';
import ImageAndLabel from 'components/ImageAndLabel/RImageAndLabel';
import RMoreButton from 'components/MoreButton/RMoreButton';
import { useParams } from 'react-router-dom';
import { Resources } from 'enum/resources.enum';
import RCard from 'components/Card/RFade';
import { IServiceParams } from 'features/ServicePage/ServicePage';
import { BaseRoute } from 'framework/Routes/Base.route';
import { ServicePageRoute } from 'framework/Routes/ServicePage.route';
import vendorActions from 'features/Vendor/vendor.actions';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Spacer } from 'components/general';
import { currentVendorSelector } from 'store/selectors/vendor.selectors';

export interface RServiceCardProps {
	index: number;
	header: string;
	imageUrl: string;
	imageLabel: string;
	description: string;
}
export interface RServiceCardMoreOption {
	title: string;
	onSelect: () => void;
}

const RServiceCard: React.FC<RServiceCardProps> = (props: RServiceCardProps) => {
	const dispatch = useDispatch();
	const { vendorId } = useParams<IServiceParams>();
	const currentVendor = useSelector(currentVendorSelector, shallowEqual);

	const goToEditCard = () => {
		BaseRoute.navigateTo(new ServicePageRoute(), [vendorId, props.index.toString()]);
	};

	const deleteCard = () => {
		const slotType = currentVendor.slots_types[props.index];
		dispatch(vendorActions.deleteServiceAction(slotType));
	};
	const moreMenuOptions: RServiceCardMoreOption[] = [
		{ title: Resources.EDIT, onSelect: goToEditCard },
		{ title: Resources.DELETE, onSelect: deleteCard },
	];

	return (
		<RCard padding={10}>
			<RMoreButton options={moreMenuOptions} />
			<CardContent>
				<RLabel value={props.header} type={RLabelTypes.BOLD_25} />
				<ImageAndLabel imageUrl={props.imageUrl} label={props.imageLabel} />
				<Spacer />
				<RLabel value={props.description} />
			</CardContent>
		</RCard>
	);
};

export default RServiceCard;
