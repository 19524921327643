export function resizeImage(file: File): Promise<File> {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.onload = function (readerEvent) {
			const image = new Image();
			image.onload = function (_imageEvent) {
				// Resize the image
				const canvas = document.createElement('canvas');
				const max_size = 800;
				let width = image.width;
				let height = image.height;

				if (width > height) {
					if (width > max_size) {
						height *= max_size / width;
						width = max_size;
					}
				} else {
					if (height > max_size) {
						width *= max_size / height;
						height = max_size;
					}
				}
				canvas.width = width;
				canvas.height = height;
				canvas.getContext('2d').drawImage(image, 0, 0, width, height);
				canvas.toBlob(blob => {
					resolve(new File([blob], file.name, { lastModified: file.lastModified, type: file.type }));
				}, 'image/jpeg');
			};
			image.src = readerEvent.target.result as string;
		};
		reader.readAsDataURL(file);
	});
}
