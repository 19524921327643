import React from 'react';
import TablePaginationActions from 'components/Table/TablePaginationActions';
import TableRow from '@material-ui/core/TableRow';
import MuiTablePagination, { TablePaginationProps } from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';

const TablePagination = ({ count, rowsPerPage, page, onPageChange, onRowsPerPageChange }: TablePaginationProps) => {
	return (
		<TableFooter>
			<TableRow>
				<MuiTablePagination
					rowsPerPageOptions={[5, 10, 25, 50, 100, { label: 'All', value: count }].filter(
						option => (typeof option === 'number' ? option : option.value) <= count
					)}
					colSpan={3}
					count={count}
					rowsPerPage={rowsPerPage}
					page={page}
					SelectProps={{
						inputProps: { 'aria-label': 'rows per page' },
						native: true,
					}}
					onPageChange={onPageChange}
					onRowsPerPageChange={onRowsPerPageChange}
					ActionsComponent={TablePaginationActions}
				/>
			</TableRow>
		</TableFooter>
	);
};

export default TablePagination;
