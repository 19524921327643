import MuiTableCell, { TableCellProps } from '@material-ui/core/TableCell';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export const TableCell = withStyles(theme =>
	createStyles({
		head: {
			color: 'white',
		},
		body: {},
	})
)(MuiTableCell);

type Order = 'asc' | 'desc';

interface SortableTableProps<Data extends object> extends TableCellProps {
	order?: Order;
	orderBy: keyof Data;
	itemKey: keyof Data;
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
}

export const SortableTableCell = <Data extends object>({
	children,
	order,
	orderBy,
	onRequestSort,
	itemKey,
	...props
}: SortableTableProps<Data>) => {
	const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<TableCell {...props} sortDirection={orderBy === itemKey ? order : false}>
			<TableSortLabel
				active={orderBy === itemKey}
				direction={orderBy === itemKey ? order : 'asc'}
				onClick={createSortHandler(itemKey)}
			>
				{children}
			</TableSortLabel>
		</TableCell>
	);
};
