import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';

import RImageAndLabel from 'components/ImageAndLabel/RImageAndLabel';
import { SearchableProfile } from 'models/profile';
import { Tooltip } from '@material-ui/core';

type Props = {
	profile: SearchableProfile;
	isSelected?: boolean;
	onClick?: (profile: SearchableProfile | null) => void;
};

const SlotCandidate = ({ profile, isSelected, onClick }: Props) => {
	return (
		<Grid container spacing={3} justifyContent="flex-start">
			<Grid item xs={8}>
				<Button variant="text" onClick={() => onClick?.(profile)}>
					<RImageAndLabel imageUrl={profile.picture} label={profile.name ?? profile.email ?? 'Unnamed user'} />
				</Button>
			</Grid>
			<Grid item xs={4}>
				{isSelected && (
					<Tooltip title="Clear selection">
						<IconButton color="primary" onClick={e => onClick?.(null)}>
							<Clear />
						</IconButton>
					</Tooltip>
				)}
			</Grid>
		</Grid>
	);
};

export default SlotCandidate;
