import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import ProductsPage from 'features/ProductsPage/ProductsPage';

export class ProductsPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.PRODUCTS;
	}

	getParams(): string[] {
		return ['vendorId', 'menuId', 'categoryId'];
	}

	getComponent(): React.FC<any> {
		return ProductsPage;
	}
}
