import React from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

export type AutocompleteProps = {
	options: Option[];
	onChange: (value: string) => void;
	dense?: boolean;
	value?: Option;
};

export interface Option {
	value: any;
	label: string;
	selected?: boolean;
	disabled?: boolean;
}

const Autocomplete = ({ onChange, options, value }: AutocompleteProps) => {
	const useStyles = makeStyles(theme => ({
		input: {
			padding: '3px 5px 3px 5px',
		},
	}));

	const classes = useStyles();

	return (
		<Container>
			<MuiAutocomplete<Option, false, true, false>
				value={value}
				onChange={(_, val) => {
					if (typeof val !== 'string' && val) onChange(val.value);
				}}
				className="autocomplete"
				classes={{ input: classes.input }}
				options={options}
				disableClearable
				getOptionSelected={option => option.selected}
				getOptionDisabled={option => option?.disabled}
				getOptionLabel={option => option?.label ?? ''}
				renderInput={params => <TextField variant="outlined" {...params} />}
			/>
		</Container>
	);
};

export default Autocomplete;

const Container = styled.div`
	.MuiOutlinedInput-root {
		padding: 0 0 0 5px;
		width: 106px;
	}
`;
