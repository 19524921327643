import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { useHistory } from 'react-router-dom';
import authActions from 'features/Login/auth.actions';
import GoogleButton from 'react-google-button';
import 'features/Login/Login.scss';
import { ListGroup } from 'react-bootstrap';
import { RapydImage } from 'components/Icons/Icons';
import RLabel from 'components/Label/RLabel';
import { Resources } from 'enum/resources.enum';
import { DoubleSpacer } from 'components/general';
import { makeStyles } from '@material-ui/core';
import { isConnectedSelector } from 'store/selectors/app.selector';

const useStyles = makeStyles(theme => ({
	googleButton: {
		borderRadius: '34px !important',
		backgroundColor: `${theme.palette.primary.main} !important`,
		'& div': {
			borderRadius: '30px !important',
		},
	},
}));

const Login: React.FC = () => {
	const classes = useStyles();
	const isConnected = useSelector(isConnectedSelector);
	const dispatch = useDispatch();
	const history = useHistory();

	const onClick = async () => {
		dispatch(authActions.loginFirebaseAction());
	};

	useEffect(() => {
		if (isConnected) {
			history.push('/');
		}
	}, [isConnected, history]);

	return (
		<div className="center-screen" data-testid="Sign-in">
			<ListGroup className="textCenter">
				<div className="textCenter">{RapydImage}</div>
				<RLabel value={Resources.LOGIN_SCREEN_SUBTITLE} />
				<DoubleSpacer />
				<GoogleButton className={classes.googleButton} onClick={onClick} />
			</ListGroup>
		</div>
	);
};

export default Login;
