import React from 'react';
import { Dialog, DialogContent, Grid, makeStyles } from '@material-ui/core';
import { ListGroup } from 'react-bootstrap';
import { Resources } from 'enum/resources.enum';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { ISlot, RestaurantSubscriberField } from 'models/slot';
import { RightSpacer } from 'components/general';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
	list: {
		marginTop: 30,
		marginBottom: 30,
		flexDirection: 'row',
	},
}));

interface ISubscriberConfirmationProps {
	slot: ISlot;
	subscriber?: RestaurantSubscriberField;
	open: boolean;
	onClose: VoidFunction;
	onConfirm: VoidFunction;
	onReject: VoidFunction;
}

const SubscriberConfirmationDialog: React.FC<ISubscriberConfirmationProps> = ({
	slot,
	subscriber,
	open,
	onClose,
	onConfirm,
	onReject,
}) => {
	const classes = useStyles();

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent>
				<Grid container>
					<Grid item>
						<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_SUBSCRIBER_TIME_SLOT} />
						{subscriber?.is_late && (
							<RLabel color="error" type={RLabelTypes.BOLD_16} value={Resources.SCHEDULE_SLOTS_SCREEN_SUBSCRIBER_IS_LATE} />
						)}
						<ListGroup className={classes.list}>
							<RLabel value={`${Resources.START_DATE} ${moment(slot?.start_date).format('HH:mm')}`} />
							<RightSpacer />
							<RLabel value={`${Resources.END_DATE} ${moment(slot?.end_date).format('HH:mm')}`} />
						</ListGroup>
					</Grid>

					<Grid item>
						<RButton label={Resources.YES} theme={RButtonTheme.LIGHT} onClick={onConfirm} />
						<RButton label={Resources.NO} theme={RButtonTheme.LIGHT} onClick={onReject} />
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default SubscriberConfirmationDialog;
