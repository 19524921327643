import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import ScheduleSlotsPage from 'features/ScheduleSlotsPage/ScheduleSlotsPage';

export class ScheduleSlotsPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.SCHEDULE_SLOTS_PAGE;
	}

	getParams(): string[] {
		return ['vendorId', 'serviceId'];
	}

	getComponent(): React.FC<any> {
		return ScheduleSlotsPage;
	}
}
