import { makeStyles, Dialog } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import RCard from 'components/Card/RFade';
import { QrReader, OnResultFunction } from 'react-qr-reader';

export const useStyles = makeStyles(theme => ({}));

export interface IRQRDialogProps {
	open: boolean;
	onClose: () => void;
	onScan: (data: string) => void;
}

const RQRDialog: React.FC<IRQRDialogProps> = props => {
	useEffect(() => {}, []);

	const handleResult: OnResultFunction = (data, err) => {
		if (err) return console.log(err);
		if (data) {
			props.onScan(data.getText());
			props.onClose();
		}
	};

	const previewStyle = {
		height: 240,
		width: 320,
	};

	return (
		<Dialog onClose={props.onClose} open={props.open}>
			<RCard>
				<QrReader
					constraints={{ height: 240, width: 320, facingMode: 'environment' }}
					containerStyle={previewStyle}
					onResult={handleResult}
				/>
			</RCard>
		</Dialog>
	);
};

export default RQRDialog;
