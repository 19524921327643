import React from 'react';
import { makeStyles } from '@material-ui/core';
import RSelect, { IROption } from 'components/Select/RSelect';
import RScheduleSlotsDaysTable from 'components/ScheduleSlotsDaysTable/ScheduleSlotsDaysTable';
import { useSelector, useDispatch, shallowEqual } from 'store';
import vendorActions, { performAsyncAction } from 'features/Vendor/vendor.actions';
import { useHistory, useParams } from 'react-router-dom';
import { IVendorParams } from 'features/MyServicesPage/MyServicesPage';
import {
	getDefaultScheduleSlotsTemplateData,
	getScheduleSlotsTable,
	getTheBusinessDate,
	ISlotsScheduleTemplate,
	SlotDayProperties,
	slotsDuration,
	SlotsScheduleMethod,
	SlotsTimeSpread,
	slotsTimeSpread,
} from 'features/ScheduleSlotsPage/ScheduleSlotsPage.types';
import RContainer from 'components/Container/RContainer';
import RCard from 'components/Card/RFade';
import { Spacer } from 'components/general';
import { Resources } from 'enum/resources.enum';
import RDivider from 'components/Divider/RDivider';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import RSwitch from 'components/Switch/RSwitch';
import { ListGroup } from 'react-bootstrap';
import RDatePicker from 'components/DatePicker/RDatePicker';
import { RScheduleSlotsDayData } from 'components/ScheduleSlotsDaysTable/ScheduleSlotsDayRow/ScheduleSlotsDayRow';
import { BaseRoute } from 'framework/Routes/Base.route';
import { ScheduleSlotsPageRoute } from 'framework/Routes/ScheduleSlotsPage.route';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { ManageSlotsPageRoute } from 'framework/Routes/ManageSlotsPage.route';
import RSmallTextField from 'components/SmallTextField/RSmallTextField';
import RListGroup from 'components/ListGroup/RListGroup';
import { useCallback } from 'react';
import { currentVendorSelector } from 'store/selectors/vendor.selectors';
import EditLocationsDialog from 'components/EditLocationsDialog/EditLocationsDialog';
import { Location } from 'models/locations';
import styled from 'styled-components';
import { LocationsApi } from 'framework/API/locations.api';

const useStyles = makeStyles(theme => ({
	actionButtons: {
		float: 'right',
	},
}));

export type IServiceParams = IVendorParams & Record<'serviceId', string>;

const [dateRangeOption, singleDayOption]: IROption[] = [
	{ label: Resources.SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_SWITCH_OPT_1, value: SlotsScheduleMethod.DATE_RANGE },
	{ label: Resources.SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_SWITCH_OPT_2, value: SlotsScheduleMethod.SINGLE_DAY },
];

const ScheduleSlotsPage: React.FC = () => {
	const currentVendor = useSelector(currentVendorSelector, shallowEqual);
	const currentSlotsSchedulerTemplate = currentVendor.current_slots_schedule_template;
	const [scheduleDuration, setScheduleDuration] = React.useState<SlotsTimeSpread>(SlotsTimeSpread.WEEKLY);
	const [startDate, setStartDate] = React.useState<Date>(getTheBusinessDate());
	const [isEditingLocation, setIsEditingLocations] = React.useState<boolean>(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const { vendorId, serviceId } = useParams<IServiceParams>();
	const [currentData, setCurrentData] = React.useState<ISlotsScheduleTemplate>(getDefaultScheduleSlotsTemplateData(serviceId));
	const classes = useStyles();
	const supportsCustomLocation = currentVendor?.vendor?.configuration?.is_location_feature_available ?? false;

	const [locations, setLocations] = React.useState<Location[]>([]);

	const fetchLocations = React.useCallback(
		() =>
			performAsyncAction(dispatch, new LocationsApi().searchBy(currentVendor?.vendor?.id), (res: Location[]) =>
				setLocations(res)
			),
		[dispatch, currentVendor]
	);

	React.useEffect(() => {
		if (supportsCustomLocation) fetchLocations();
	}, [supportsCustomLocation, fetchLocations]);

	const fetchSlotsScheduleTemplate = useCallback(
		(selectedSlotTypeId: string) => {
			dispatch(vendorActions.getScheduleSlotsTemplateAction(selectedSlotTypeId));
		},
		[dispatch]
	);

	const displayOneRow = useCallback(
		(byData: ISlotsScheduleTemplate) => {
			const shouldActivateOnlyDay = startDate.getDay();
			setCurrentData({
				...byData,
				day_properties: byData.day_properties.map(item => {
					return { ...item, is_active: item.day === shouldActivateOnlyDay };
				}),
				time_spread: SlotsTimeSpread.DAILY,
			});
		},
		[startDate]
	);

	React.useEffect(() => {
		if (currentVendor.vendor) {
			const slotType = currentVendor.slots_types.find(({ id }) => id === serviceId);
			if (!slotType) history.goBack();
			else fetchSlotsScheduleTemplate(slotType.id);
		} else dispatch(vendorActions.getMyServicesAction(vendorId));
	}, [dispatch, fetchSlotsScheduleTemplate, history, serviceId, vendorId, currentVendor.slots_types, currentVendor.vendor]);

	React.useEffect(() => {
		if (currentSlotsSchedulerTemplate)
			if (currentSlotsSchedulerTemplate.time_spread === SlotsTimeSpread.DAILY) displayOneRow(currentSlotsSchedulerTemplate);
			else setCurrentData({ ...currentSlotsSchedulerTemplate });
		else setCurrentData(getDefaultScheduleSlotsTemplateData(serviceId));
	}, [currentSlotsSchedulerTemplate, displayOneRow, serviceId]);

	const handleSlotTypesOptionsChange = (value: string) => {
		BaseRoute.navigateTo(new ScheduleSlotsPageRoute(), [vendorId, value]);
		fetchSlotsScheduleTemplate(value);
	};

	const buildServicesDropdownOptions = (selectedId: string) => {
		const options: IROption[] = currentVendor.slots_types.map(slotType => {
			const isSelected = selectedId === slotType.id;
			return {
				value: slotType.id,
				label: slotType.name,
				selected: isSelected,
			};
		});
		return options;
	};

	const scheduleDurationOptions = React.useMemo(
		() =>
			slotsTimeSpread.map(item => ({
				label: `${item} calendar days`,
				value: item,
				selected: currentData.time_spread === item,
			})),
		[currentData.time_spread]
	);

	const sessionDurationOptions = React.useMemo(() => {
		const options = slotsDuration.map(item => {
			return {
				label: `${item} minutes`,
				value: item,
				selected: currentData.duration === item,
			};
		});
		return options;
	}, [currentData.duration]);

	const sessionLocationOptions: IROption[] = React.useMemo(() => {
		const options = locations.map(item => {
			return {
				label: item.name,
				value: item.name,
				selected: currentData.default_location === item.name,
			};
		});
		return [
			{
				label: 'Not specified',
				value: '',
				selected: currentData.default_location === '',
			},
			...options,
		];
	}, [currentData.default_location, locations]);

	const handleScheduleMethodChange = (option: IROption) => {
		if (option.value === SlotsScheduleMethod.DATE_RANGE)
			//display all rows
			setCurrentData(prev => ({ ...prev, time_spread: scheduleDuration }));
		//display single row
		else displayOneRow(currentData);
	};

	const daysTableData = React.useMemo(() => {
		if (currentData.time_spread !== SlotsTimeSpread.DAILY)
			return getScheduleSlotsTable(currentData.day_properties, currentData.duration);

		return getScheduleSlotsTable(
			currentData.day_properties.filter(item => item.is_active),
			currentData.duration
		).filter(row => row.isActive);
	}, [currentData]);

	const handleTableChange = (day: number, data: RScheduleSlotsDayData) =>
		setCurrentData(prev => ({
			...prev,
			day_properties: currentData.day_properties.map(dayTime =>
				dayTime.day === day ? { ...dayTime, properties: data.selectedProperties, is_active: data.isActive } : dayTime
			),
		}));

	const handleParticipantMaxChange = React.useCallback((value: string) => {
		if (Number.isNaN(+value)) return;
		setCurrentData(prev => ({
			...prev,
			maximum_subscribers: Number(value),
			day_properties: prev.day_properties.map(dayProperty => ({
				...dayProperty,
				properties: dayProperty.properties.map(property => ({
					...property,
					max_participants: prev.maximum_subscribers === property.max_participants ? +value : property.max_participants,
				})),
			})),
		}));
	}, []);

	const handleParticipantMinChange = (value: string) => {
		setCurrentData(prev => ({ ...prev, minimum_subscribers: Number(value) }));
	};

	const handleDurationChange = (value: number) => {
		setCurrentData(prev => ({ ...prev, duration: value }));
	};

	const handleLocationChange = (value: string) => {
		setCurrentData(prev => ({ ...prev, default_location: value }));
	};

	const handleScheduleDurationChange = (value: SlotsTimeSpread) => {
		setScheduleDuration(value);
		setCurrentData(prev => ({ ...prev, time_spread: value }));
	};

	const handleStartDateChange = (date: Date) => {
		setStartDate(date);
		if (currentData.time_spread === SlotsTimeSpread.DAILY) {
			const shouldActivateOnlyDay = date.getDay();
			const day_properties: SlotDayProperties[] = currentData.day_properties.map(dayTime => {
				return { ...dayTime, is_active: dayTime.day === shouldActivateOnlyDay };
			});
			setCurrentData(prev => ({ ...prev, day_properties }));
		}
	};

	const handlePublish = () => {
		dispatch(vendorActions.publishScheduleSlotsTemplateAction(vendorId, startDate, currentData));
	};

	const isFormDisabled = React.useMemo(
		() => !currentData.day_properties.find(item => item.is_active),
		[currentData.day_properties]
	);

	const onEditLocations = () => {
		setIsEditingLocations(true);
	};

	return (
		<RContainer>
			<Spacer />
			<RCard>
				{/* Create Sessions */}
				<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_TITLE} type={RLabelTypes.BOLD_25} />
				<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_SUBTITLE} type={RLabelTypes.REGULAR_16} />
				<RDivider />

				{/* Service dropdown*/}
				<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_SERVICE_SELECTION_TITLE} type={RLabelTypes.BOLD_18} />
				{currentVendor.vendor ? (
					<RSelect
						options={buildServicesDropdownOptions(serviceId)}
						onChange={value => {
							handleSlotTypesOptionsChange(value);
						}}
						minWidth={250}
					/>
				) : (
					''
				)}
				<RDivider />

				{/* When can invitees schedule your sessions? */}
				<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_TITLE} type={RLabelTypes.BOLD_18} />
				<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_SUBTITLE} type={RLabelTypes.REGULAR_16} />
				<Spacer />
				<RSwitch
					leftOption={dateRangeOption}
					rightOption={singleDayOption}
					value={currentData.time_spread === SlotsTimeSpread.DAILY}
					onChange={handleScheduleMethodChange}
				/>
				<Spacer />

				<RListGroup horizontal>
					<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_DATE_PICKER_LABEL} type={RLabelTypes.BOLD_18} />
					<RDatePicker startDate={startDate} onChange={date => handleStartDateChange(date)} />
				</RListGroup>

				<Spacer />
				{currentData.time_spread !== SlotsTimeSpread.DAILY ? (
					<RListGroup horizontal={true}>
						<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_DAYS_LABEL_1} type={RLabelTypes.BOLD_18} />
						<RSelect options={scheduleDurationOptions} onChange={handleScheduleDurationChange} minWidth={200} />
						<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_TIME_SPREAD_DAYS_LABEL_2} type={RLabelTypes.BOLD_18} />
					</RListGroup>
				) : (
					''
				)}
				<RDivider />

				{/* Session duration */}
				<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_DURATION_TITLE} type={RLabelTypes.BOLD_18} />
				<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_DURATION_SUBTITLE} type={RLabelTypes.REGULAR_16} />
				<RSelect options={sessionDurationOptions} onChange={handleDurationChange} minWidth={160} />
				<RDivider />

				{/* Participants */}

				<>
					<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_PARTICIPANTS_TILE} type={RLabelTypes.BOLD_18} />
					<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_PARTICIPANTS_SUBTILE} type={RLabelTypes.REGULAR_16} />
					<Spacer />
					<ListGroup horizontal>
						<RSmallTextField
							disabled
							label={Resources.SCHEDULE_SLOTS_SCREEN_PARTICIPANTS_MIN_LABEL}
							value={currentData.minimum_subscribers}
							onChange={handleParticipantMinChange}
						/>
						<RSmallTextField
							label={Resources.SCHEDULE_SLOTS_SCREEN_PARTICIPANTS_MAX_LABEL}
							value={currentData.maximum_subscribers}
							onChange={handleParticipantMaxChange}
						/>
					</ListGroup>
					<RDivider />
				</>

				{/* Location */}
				{supportsCustomLocation && (
					<LocationWrapper>
						<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_LOCATION_TITLE} type={RLabelTypes.BOLD_18} />
						<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_LOCATION_SUBTITLE} type={RLabelTypes.REGULAR_16} />
						<Spacer />
						<ListGroup horizontal>
							<RSelect canBeEmpty options={sessionLocationOptions} onChange={handleLocationChange} minWidth={205} />
							<RButton
								className="location-edit-button"
								label={Resources.SCHEDULE_SLOTS_SCREEN_LOCATION_EDIT}
								theme={RButtonTheme.DARK}
								onClick={onEditLocations}
							/>
						</ListGroup>
						<RDivider />
					</LocationWrapper>
				)}

				{/* Set your weekly hours */}
				<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_DAYS_TABLE_TITLE} type={RLabelTypes.BOLD_18} />
				<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_DAYS_TABLE_SUBTITLE} type={RLabelTypes.REGULAR_16} />
				<RScheduleSlotsDaysTable
					sessionDuration={currentData.duration}
					rows={daysTableData}
					onRowChange={handleTableChange}
					withLocation={supportsCustomLocation}
				/>

				<ListGroup className={classes.actionButtons} horizontal>
					<RButton
						label={Resources.SCHEDULE_SLOTS_SCREEN_CANCEL_BUTTON}
						theme={RButtonTheme.LIGHT}
						onClick={() => {
							BaseRoute.navigateTo(new ManageSlotsPageRoute(), [vendorId]);
						}}
					/>
					<RButton
						disabled={isFormDisabled}
						label={Resources.SCHEDULE_SLOTS_SCREEN_PUBLISH_BUTTON}
						theme={RButtonTheme.DARK}
						onClick={handlePublish}
					/>
				</ListGroup>
			</RCard>
			{supportsCustomLocation && isEditingLocation && (
				<EditLocationsDialog
					vendor={currentVendor.vendor}
					open={isEditingLocation}
					onClose={() => {
						setIsEditingLocations(false);
						fetchLocations();
					}}
				/>
			)}
		</RContainer>
	);
};

export default ScheduleSlotsPage;

const LocationWrapper = styled.div.attrs({ className: 'location-wrapper' })`
	.location-edit-button {
		margin: 0 5px;
		border-radius: 45px;
		height: 40px;
		width: width;
		font-size: 0.875rem;
		text-transform: 'initial';
		min-width: 100;
	}
`;
