import { BaseRoute } from 'framework/Routes/Base.route';
import { MyServicesPageRoute } from 'framework/Routes/MyServicesPage.route';
import { MenusPageRoute } from 'framework/Routes/MenusPage.route';
import { CategoriesPageRoute } from 'framework/Routes/CategoriesPage.route';
import { LoginPageRoute } from 'framework/Routes/LoginPage.route';
import { DashboardPageRoute } from 'framework/Routes/DashoboardPage.route';
import { NewServicePageRoute } from 'framework/Routes/NewServicePage.route';
import { ManageSlotsPageRoute } from 'framework/Routes/ManageSlotsPage.route';
import { ServicePageRoute } from 'framework/Routes/ServicePage.route';
import { ScheduleSlotsPageRoute } from 'framework/Routes/ScheduleSlotsPage.route';
import { OrderManagementPageRoute } from 'framework/Routes/OrdersManagementPage.route';
import { FoodDashboardPageRoute } from 'framework/Routes/FoodDashboardPage.route';
import { ProductsPageRoute } from 'framework/Routes/ProductsPage.route';
import { ProductPageRoute } from 'framework/Routes/ProductPage.route';
import { NewProductPageRoute } from 'framework/Routes/NewProductPage.route';
import { PermissionsPageRoute } from 'framework/Routes/PermissionsPage.route';
import { ReportsPageRoute } from 'framework/Routes/ReportsPage.route';
import { EmployeesPageRoute } from 'framework/Routes/EmployeesPage.route';
import { ActivityLogPageRoute } from 'framework/Routes/ActivityLogPage.route';

export enum ROUTES {
	MY_SERVICES = '/vendor/:vendorId/services',
	MENUS = '/vendor/:vendorId/menus',
	CATEGORIES = '/vendor/:vendorId/menus/:menuId/categories',
	PRODUCTS = '/vendor/:vendorId/menus/:menuId/categories/:categoryId/products',
	PRODUCT_PAGE = '/vendor/:vendorId/menus/:menuId/categories/:categoryId/products/:productId',
	NEW_PRODUCT_PAGE = '/vendor/:vendorId/menus/:menuId/categories/:categoryId/create_product',
	SERVICE_PAGE = '/vendor/:vendorId/services/:index',
	NEW_SERVICE_PAGE = '/vendor/:vendorId/create_service',
	SCHEDULE_SLOTS_PAGE = '/vendor/:vendorId/services/:serviceId/schedule_slots',
	MANAGE_SLOTS = '/vendor/:vendorId/manage_slots',
	ORDER_MANAGEMENT = '/vendor/orders_management',
	LOGIN = '/login',
	DASHBOARD = '/dashboard',
	FOOD_DASHBOARD = '/dashboard/food',
	PERMISSIONS = '/dashboard/permissions',
	REPORTS = '/dashboard/reports',
	EMPLOYEES = '/dashboard/employees',
	ACTIVITY_LOG = '/dashboard/activity-log',
	SAFETY = '/',
}

export const AppRoutes: BaseRoute[] = [
	new LoginPageRoute(),
	new DashboardPageRoute(),
	new FoodDashboardPageRoute(),
	new MyServicesPageRoute(),
	new MenusPageRoute(),
	new CategoriesPageRoute(),
	new ProductsPageRoute(),
	new ProductPageRoute(),
	new NewProductPageRoute(),
	new ServicePageRoute(),
	new NewServicePageRoute(),
	new ManageSlotsPageRoute(),
	new ScheduleSlotsPageRoute(),
	new OrderManagementPageRoute(),
	new PermissionsPageRoute(),
	new ReportsPageRoute(),
	new EmployeesPageRoute(),
	new ActivityLogPageRoute(),
];
