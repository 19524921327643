import { makeStyles, Grid } from '@material-ui/core';
import React from 'react';
import { Resources } from 'enum/resources.enum';
import { RServiceCardMoreOption } from 'features/MyServicesPage/ServiceCard/RServiceCard';
import RCard from 'components/Card/RFade';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { TimeIcon } from 'components/Icons/Icons';
import { useSelector } from 'store/store';
import { roleSelector } from 'store/selectors/app.selector';
import { Role } from 'enum/role.enum';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import MenuButton from 'components/MenuButton/MenuButton';
import { WaitingList } from 'models/waitingList';
import WaitingListSubscribersDialog from 'components/WaitingListSubscribersDialog/WaitingListSubscribersDialog';
import dateFormat from 'dateformat';
import { RightSpacer } from 'components/general';

export const useStyles = makeStyles(theme => ({
	slotDetailColumn: {
		marginTop: 'auto',
		marginBottom: 'auto',
		paddingTop: 20,
	},
	alignCenter: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 0,
		marginBottom: 0,
		borderRadius: '0.25rem',
	},
	textButton: {
		marginTop: 20,
	},
}));

export interface WaitingListRowProps {
	waitingList: WaitingList | null;
	date: Date;
}

const WaitingListRow = ({ waitingList, date }: WaitingListRowProps) => {
	const roles = useSelector(roleSelector);
	const [subscribersModal, setSubscribersModal] = React.useState<{ isOpen: boolean; addMode?: boolean }>({
		isOpen: false,
	});
	const classes = useStyles();
	const canAddSubscribers = !waitingList?.is_fully_booked && (roles.includes(Role.Admin) || roles.includes(Role.BusinessAdmin));

	const subscribersOptions = React.useMemo(
		(): RServiceCardMoreOption[] => [
			{ title: Resources.ADD_SUBSCRIBER, onSelect: () => setSubscribersModal({ isOpen: true, addMode: true }) },
			{ title: Resources.VIEW, onSelect: () => setSubscribersModal({ isOpen: true }) },
		],
		[]
	);

	const onCloseModal = () => {
		setSubscribersModal({ isOpen: false });
	};

	return (
		<>
			<RCard padding={10} height={100}>
				<Grid container>
					<Grid className={classes.slotDetailColumn} item xs={6} sm={2}>
						<RLabel value="Waiting List" type={RLabelTypes.BOLD_18} />
					</Grid>
					<Grid className={classes.slotDetailColumn} item xs={6} sm={3}>
						<div className={classes.alignCenter}>
							{TimeIcon}
							<RightSpacer />
							<RLabel
								includeGutterBottom={false}
								type={RLabelTypes.REGULAR_16}
								value={dateFormat(waitingList?.date ?? date, 'dd/mm/yyyy')}
							/>
						</div>
					</Grid>
					<Grid className={classes.slotDetailColumn} item xs={6} sm={3}>
						{!waitingList?.is_fully_booked && canAddSubscribers ? (
							<MenuButton
								theme={RButtonTheme.TEXT}
								options={subscribersOptions}
								label={`${waitingList?.unfulfilled_subscribers_amount ?? 0}/5 Subscribers`}
							/>
						) : (
							<RButton
								theme={RButtonTheme.TEXT}
								disabled={!waitingList?.unfulfilled_subscribers_amount}
								label={`${waitingList?.unfulfilled_subscribers_amount ?? 0}/5 Subscribers`}
								onClick={() => setSubscribersModal({ isOpen: true })}
							/>
						)}
					</Grid>
					<Grid className={classes.slotDetailColumn} item xs={6} sm={3} />
					<Grid item xs={6} sm={1} />
				</Grid>
			</RCard>
			{subscribersModal.isOpen && (
				<WaitingListSubscribersDialog
					open={subscribersModal.isOpen}
					initialAddMode={subscribersModal.addMode}
					waitingList={waitingList}
					date={date}
					onClose={onCloseModal}
				/>
			)}
		</>
	);
};

export default WaitingListRow;
