import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as Time } from 'assets/icons/time-circle.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as RapydLogo } from 'assets/rapyd-logo.svg';
import { ReactComponent as MagicHat } from 'assets/magic-hat.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as Restaurant } from 'assets/restaurant.svg';

export const Calendar = <CalendarIcon />;

export const TimeIcon = <Time />;

export const ArrowRightIcon = <ArrowRight />;

export const RapydImage = <RapydLogo />;

export const EmptyImage = <MagicHat />;

export const Trash = <TrashIcon />;

export const Upload = <UploadIcon />;

export const RestaurantImage = <Restaurant />;
