import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { ListGroup } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';

export const useStyles = makeStyles(theme => ({
	root: { alignItems: 'center' },
	textfield: { margin: 10 },
}));

export interface IRSmallTextField {
	className?: string;
	disabled?: boolean;
	label?: string | React.ReactNode;
	value: unknown;
	onChange: (value: string) => void;
	onBlur?: VoidFunction;
	dense?: boolean;
	placeholder?: string;
	width?: number;
	onKeyPress?: React.KeyboardEventHandler<HTMLDivElement>;
}

const RSmallTextField = React.forwardRef<HTMLInputElement, IRSmallTextField>(
	(
		{ className = 'small-text-field', label, value, onChange, onBlur, disabled, dense, placeholder, width = 77, onKeyPress },
		ref
	) => {
		const { textfield, ...classes } = useStyles();
		return (
			<Container dense={dense}>
				<ListGroup className={classes.root} horizontal>
					{label && <RLabel value={label} type={RLabelTypes.REGULAR_18} />}
					<TextField
						inputRef={ref}
						className={[textfield, className].join(' ')}
						style={{ width }}
						classes={classes}
						disabled={disabled}
						onKeyPress={onKeyPress}
						value={value}
						onChange={e => onChange(e.target.value)}
						onBlur={onBlur}
						variant="outlined"
						placeholder={placeholder}
					/>
				</ListGroup>
			</Container>
		);
	}
);

export default RSmallTextField;

const Container = styled.div.attrs({ className: 'small-field-textfield-container' })<{ dense?: boolean }>`
	.MuiOutlinedInput-input {
		${({ dense }) =>
			dense &&
			css`
				padding: 8.5px 14px;
			`};
	}
`;
