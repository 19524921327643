import React from 'react';

type Props<T extends object> = {
	items: T[];
	renderItem: (item: T) => void;
	emptyState: React.ReactNode;
};

const List = <T extends object>({ items, renderItem, emptyState }: Props<T>) => {
	return <>{items?.length ? items.map(renderItem) : <>{emptyState}</>}</>;
};

export default List;
