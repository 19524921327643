import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { useDispatch as useReduxDispatch, shallowEqual, createSelectorHook } from 'react-redux';
import app from 'store/slices/app.slice';
import vendors from 'store/slices/vendor.slice';

const reducer = combineReducers({ app, vendors });
const store = configureStore({ reducer });

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector = createSelectorHook<RootState>();
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export { shallowEqual };
export default store;
