import React from 'react';
import { SmallSpacer } from 'components/general';
import { EmptyImage } from 'components/Icons/Icons';
import RLabel from 'components/Label/RLabel';
import RListGroup from 'components/ListGroup/RListGroup';

export interface REmptyViewProps {
	description: string;
}

const REmptyCategoryView: React.FC<REmptyViewProps> = (props: REmptyViewProps) => {
	return (
		<div>
			<RListGroup horizontal={false}>
				{EmptyImage}
				<SmallSpacer />
				<RLabel value={props.description} />
			</RListGroup>
		</div>
	);
};

export default REmptyCategoryView;
