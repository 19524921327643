import dateFormat from 'dateformat';
import moment from 'moment';
import { IROption } from 'components/Select/RSelect';

export const presentTime = (date: Date, format: string = 'HH:MM') => {
	return dateFormat(date, format);
};

export const isToday = (date: Date) => {
	return moment(date).isSame(new Date(), 'day');
};

export const BEAUTIFUL_DISPLAY_DATE_FORMAT = 'dddd, mmm d';

export const MONTHS_OPTIONS: IROption[] = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
].map((month, index) => {
	return { label: month, value: index + 1, selected: index === 0 };
});

export const YEAR_OPTIONS: IROption[] = [moment().year(), moment().subtract(1, 'y').year()].map((year, index) => {
	return { label: year.toString(), value: year, selected: index === 0 };
});
