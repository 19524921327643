import { CardContent, makeStyles } from '@material-ui/core';
import React from 'react';
import RButton from 'components/Button/RButton';
import { useParams } from 'react-router-dom';
import { Resources } from 'enum/resources.enum';
import RCard from 'components/Card/RFade';
import { BaseRoute } from 'framework/Routes/Base.route';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { FlexSpaceBetween } from 'components/general';
import { IProduct } from 'models/product';
import { DocumentStatus } from 'models/misc';
import { ProductPageRoute } from 'framework/Routes/ProductPage.route';
import { IProductsParams } from 'features/ProductsPage/ProductsPage';
import RListGroup from 'components/ListGroup/RListGroup';

export const useStyles = makeStyles(theme => ({
	indicator: {
		backgroundColor: theme.palette.error.light,
		borderRadius: 4,
		color: 'white',
		padding: 5,
	},
}));
export interface RProductCardProps {
	product: IProduct;
}

const RProductCard: React.FC<RProductCardProps> = ({ product }: RProductCardProps) => {
	const { menuId, vendorId } = useParams<IProductsParams>();
	const classes = useStyles();

	const navigateToProductPage = () => {
		BaseRoute.navigateTo(new ProductPageRoute(), [vendorId, menuId, product.category as string, product.id]);
	};

	return (
		<RCard height={100} padding={20}>
			<CardContent>
				<FlexSpaceBetween>
					<RLabel value={product.name} type={RLabelTypes.BOLD_18} />
					<RListGroup horizontal>
						{product.status === DocumentStatus.DELETED && (
							<div className={classes.indicator}>
								<RLabel value={Resources.INACTIVE} />
							</div>
						)}
						<RButton
							label={Resources.PRODUCTS_SCREEN_CARD_ACTION_TITLE}
							onClick={navigateToProductPage}
							disabled={product.status === DocumentStatus.DELETED}
						/>
					</RListGroup>
				</FlexSpaceBetween>
			</CardContent>
		</RCard>
	);
};

export default RProductCard;
