import { Container } from '@material-ui/core';
import React from 'react';
import RFade from 'components/Fade/RFade';

export interface RContainerProps {
	in?: boolean;
	timeout?: number;
}
const RContainer: React.FC<RContainerProps> = props => {
	return (
		<Container maxWidth="md">
			<RFade>
				<div>{props.children}</div>
			</RFade>
		</Container>
	);
};

export default RContainer;
