import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import PermissionsPage from 'features/PermissionsPage/PermissionsPage';

export class PermissionsPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.PERMISSIONS;
	}

	getParams(): string[] {
		return [];
	}

	getComponent(): React.FC<any> {
		return PermissionsPage;
	}

	isAdminRoute(): boolean {
		//TODO:- Should be true
		return false;
	}
}
