import { DocumentStatus } from 'models/misc';

export enum IProductCategoryMenu {
	RESTAURANT = 'Restaurant',
	TAKEAWAY = 'Take Away',
	SHOP = 'Shop',
	COFFEE = 'Cafe',
	FRESH_BAR = 'Fresh Bar',
}

export interface IProductCategory {
	id: string;
	name: string;
	is_single_product_per_order: boolean;
	//True will indicates this category contains products which will be care under recurring order
	is_recurring_products: boolean;
	menu: IProductCategoryMenu;
	status: DocumentStatus;
}
