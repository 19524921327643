import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { RapydImage } from 'components/Icons/Icons';
import { Avatar, AvatarProps, Container, Menu, MenuItem } from '@material-ui/core';
import { BaseRoute } from 'framework/Routes/Base.route';
import { useDispatch, useSelector } from 'store';
import { useEffect } from 'react';
import authActions from 'features/Login/auth.actions';
import { FloatRight } from 'components/general';
import { Role } from 'enum/role.enum';
import { PermissionsPageRoute } from 'framework/Routes/PermissionsPage.route';
import { ReportsPageRoute } from 'framework/Routes/ReportsPage.route';
import { useCallback } from 'react';
import { roleSelector } from 'store/selectors/app.selector';
import { EmployeesPageRoute } from 'framework/Routes/EmployeesPage.route';
import { ActivityLogPageRoute } from 'framework/Routes/ActivityLogPage.route';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appbar: {
			zIndex: 5,
			position: 'fixed',
			width: '100%',
			backgroundColor: '#FFFFFF',
			color: '#09121C',
		},
		placeholder: {
			height: 64,
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		small: {
			width: theme.spacing(4),
			height: theme.spacing(4),
			marginRight: 10,
		},
	})
);

export type RAppBarTab = {
	label: string;
	onChange: () => void;
	selected: boolean;
};

export interface RAppBarProps {
	tabs: RAppBarTab[];
	avatarProps: AvatarProps;
}

const RAppBar: React.FC<RAppBarProps> = ({ tabs, avatarProps }: RAppBarProps) => {
	const role = useSelector(roleSelector);
	const classes = useStyles();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const getSelectedTabIndex = useCallback(() => {
		const index = tabs.findIndex(t => t.selected);
		return index >= 0 ? index : 0;
	}, [tabs]);

	const [currentTabIndex, setTabValue] = React.useState(getSelectedTabIndex());

	useEffect(() => {
		setTabValue(getSelectedTabIndex());
	}, [getSelectedTabIndex, tabs]);

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleLogout = React.useCallback(() => {
		dispatch(authActions.logoutAction());
	}, [dispatch]);

	const handleLogoClicked = () => {
		BaseRoute.navigateHome();
	};

	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTabValue(newValue);
		tabs[newValue].onChange();
	};

	const menuItems = React.useMemo(
		() => [
			...(role.includes(Role.Admin) || role.includes(Role.BusinessAdmin)
				? [
						role.includes(Role.Admin)
							? {
									onClick: () => {
										handleClose();
										BaseRoute.navigateTo(new PermissionsPageRoute());
									},
									children: 'Permissions',
							  }
							: null,
						{
							onClick: () => {
								handleClose();
								BaseRoute.navigateTo(new ReportsPageRoute());
							},
							children: 'Reports',
						},
						role.includes(Role.Admin)
							? {
									onClick: () => {
										handleClose();
										BaseRoute.navigateTo(new EmployeesPageRoute());
									},
									children: 'Employees',
							  }
							: null,
						role.includes(Role.Admin)
							? {
									onClick: () => {
										handleClose();
										BaseRoute.navigateTo(new ActivityLogPageRoute());
									},
									children: 'Activity Log',
							  }
							: null,
				  ].filter(Boolean)
				: []),
			{
				onClick: handleLogout,
				children: 'Logout',
			},
		],
		[handleLogout, role]
	);

	return (
		<div>
			<AppBar className={classes.appbar}>
				<Container maxWidth="md">
					<Toolbar>
						<IconButton onClick={handleLogoClicked} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
							{RapydImage}
						</IconButton>
						<Tabs value={currentTabIndex} onChange={handleTabChange} aria-label="simple tabs example">
							{tabs.map((tab, index) => {
								return <Tab key={index} label={tab.label} />;
							})}
						</Tabs>
						<FloatRight>
							<IconButton
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								color="inherit"
							>
								<Avatar {...avatarProps} className={classes.small} />
							</IconButton>
							<Menu id="menu-appbar" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
								{menuItems.map(({ onClick, children }, i) => {
									return (
										<MenuItem key={i.toString()} onClick={onClick}>
											{children}
										</MenuItem>
									);
								})}
							</Menu>
						</FloatRight>
					</Toolbar>
				</Container>
			</AppBar>
			<div className={classes.placeholder}></div>
		</div>
	);
};
export default RAppBar;
