import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'store';
import { onLoadingSelector } from 'store/selectors/app.selector';

export enum RButtonTheme {
	DARK = 'contained',
	LIGHT = 'outlined',
	TEXT = 'text',
}

export interface RButtonProps {
	disabled?: boolean;
	label?: string | React.ReactNode;
	theme?: RButtonTheme;
	className?: string;
	startIcon?: React.ReactNode;
	type?: 'submit' | 'reset' | 'button';
	height?: number;
	width?: number;
	removeMargin?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const RButton: React.FC<RButtonProps> = ({
	height,
	width,
	removeMargin,
	type,
	theme,
	className,
	startIcon,
	onClick,
	disabled,
	label,
}) => {
	const useStyles = makeStyles(styleTheme => ({
		root: {
			borderRadius: 45,
			height: height || 40,
			width: width,
			...styleTheme.typography.subtitle1,
			textTransform: 'initial',
			minWidth: width || 100,
			margin: removeMargin ? 0 : 5,
		},
	}));

	const classes = useStyles();
	const onLoading = useSelector(onLoadingSelector);

	return (
		<Button
			type={type}
			className={className ? className : classes.root}
			color="primary"
			variant={theme || RButtonTheme.LIGHT}
			startIcon={startIcon}
			onClick={onClick}
			disabled={disabled || onLoading || false}
		>
			{label}
		</Button>
	);
};

export default RButton;
