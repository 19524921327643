import React from 'react';
import styled from 'styled-components';
import { Resources } from 'enum/resources.enum';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { SearchableProfile } from 'models/profile';
import { useDispatch } from 'store/store';
import useDebounce from 'hooks/useDebounce';
import { AdminApi } from 'framework/API/admin.api';
import RContainer from 'components/Container/RContainer';
import RCheckbox from 'components/Checkbox/RCheckbox';
import { Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import RSmallTextField from 'components/SmallTextField/RSmallTextField';
import Table from 'components/Table';
import { DocumentStatus } from 'models/misc';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { CSVLink } from 'react-csv';

const headers = [
	{ key: 'name', label: Resources.EMPLOYEES_SCREEN_HEADER_CELL_1, sortable: true },
	{ key: 'email', label: Resources.EMPLOYEES_SCREEN_HEADER_CELL_2, sortable: true },
	{ key: 'first_login', label: Resources.EMPLOYEES_SCREEN_HEADER_CELL_3, sortable: true },
	{ key: 'status', label: Resources.EMPLOYEES_SCREEN_HEADER_CELL_4, sortable: false },
];

const EmployeesPage = () => {
	const [search, setSearch] = React.useState<string>('');
	const [orderBy, setOrderBy] = React.useState<keyof SearchableProfile>('name');
	const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
	const q = useDebounce(search);
	const [employees, setEmployees] = React.useState<{ profiles: SearchableProfile[]; count: number } | null>(null);
	const [page, setPage] = React.useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, (employees?.count ?? 0) - page * rowsPerPage);

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	const dispatch = useDispatch();

	const fetchEmployees = React.useCallback(
		() =>
			performAsyncAction(
				dispatch,
				new AdminApi().searchProfiles({
					q,
					orderBy,
					direction: direction === 'asc' ? 1 : -1,
					size: rowsPerPage,
					page,
				}),
				(res: { profiles: SearchableProfile[]; count: number }) => setEmployees(res)
			),
		[direction, dispatch, orderBy, page, q, rowsPerPage]
	);

	const updateEmployeeStatus = (id: string, status: DocumentStatus) => {
		performAsyncAction(dispatch, new AdminApi().updateEmployeeStatus(id, status), () => {
			fetchEmployees();
		});
	};

	React.useEffect(() => {
		fetchEmployees();
	}, [fetchEmployees]);

	const onRequestSort = (_event: React.MouseEvent<unknown>, property: keyof SearchableProfile) => {
		setDirection(prev => (property !== orderBy || prev === 'desc' ? 'asc' : 'desc'));
		setOrderBy(property);
		setPage(0);
	};

	const onSearch = (value: string) => {
		setSearch(value);
	};

	React.useEffect(() => {
		setPage(0);
	}, [q]);

	return (
		<RContainer>
			<Spacer />
			<RLabel value={Resources.EMPLOYEES_SCREEN_TITLE} type={RLabelTypes.BOLD_25} />
			<Spacer />
			<SearchContainer>
				<RSmallTextField
					dense
					onChange={onSearch}
					value={search}
					placeholder="Search by name, email, or first login"
					width={200}
				/>

				<RButton
					disabled={!employees?.profiles?.length}
					removeMargin
					theme={RButtonTheme.DARK}
					label={
						<CSVLink
							style={{ color: 'inherit', textDecoration: 'none' }}
							filename={`Employees page ${page + 1} (${employees?.profiles?.length ?? 0} records) - ${new Date().toDateString()}`}
							data={employees?.profiles ?? []}
						>
							{Resources.REPORTS_EXPORT_ACTION_TITLE}
						</CSVLink>
					}
				/>
			</SearchContainer>
			<Spacer />
			<Table.Container>
				<Table.Head>
					<Table.HeadRow>
						{headers.map(header =>
							header.sortable ? (
								<Table.SortableCell
									key={header.key}
									orderBy={orderBy}
									order={direction}
									itemKey={header.key}
									style={{ width: '25%' }}
									onRequestSort={onRequestSort}
								>
									<RLabel type={RLabelTypes.BOLD_16} value={header.label} />
								</Table.SortableCell>
							) : (
								<Table.Cell key={header.label} style={{ width: '20%' }}>
									<RLabel type={RLabelTypes.BOLD_16} value={header.label} />
								</Table.Cell>
							)
						)}
					</Table.HeadRow>
				</Table.Head>
				<Table.Body>
					{employees?.profiles?.map(employee => (
						<Table.Row key={employee.id}>
							<Table.Cell>{employee.name}</Table.Cell>
							<Table.Cell>{employee.email}</Table.Cell>
							<Table.Cell>{employee.first_login}</Table.Cell>
							<Table.Cell>
								<RCheckbox
									label="Active"
									labelType={RLabelTypes.REGULAR_16}
									isChecked={employee.status === DocumentStatus.ACTIVE}
									onChange={() =>
										updateEmployeeStatus(
											employee.id,
											employee.status === DocumentStatus.ACTIVE ? DocumentStatus.DELETED : DocumentStatus.ACTIVE
										)
									}
								/>
							</Table.Cell>
						</Table.Row>
					))}
					{emptyRows > 0 && (
						<Table.Row style={{ height: 53 * emptyRows }}>
							<Table.Cell colSpan={6} />
						</Table.Row>
					)}
				</Table.Body>
				<Table.Pagination
					count={employees?.count ?? 0}
					page={page}
					rowsPerPage={rowsPerPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Table.Container>
		</RContainer>
	);
};

export default EmployeesPage;

const SearchContainer = styled.div.attrs({ className: 'search-container' })`
	display: flex;
	align-items: center;
	justify-content: space-between;
	.small-text-field {
		width: 100% !important;
		align-items: flex-start;
		margin: 0;

		.small-field-textfield-container {
			> .list-group-horizontal {
				align-items: flex-start !important;
			}
			width: 100% !important;
		}
		input {
			width: 400px !important;
		}
	}
`;
