import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Subscriber } from 'models/slot';
import SlotSubscriber from './SlotSubscriber';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { AdminApi } from 'framework/API/admin.api';
import { useDispatch } from 'store';
import NetworkManager from 'framework/NetworkManager';
import { SocketEvent } from 'enum/socket-events';
import { io } from 'socket.io-client';
import List from 'components/List';
import { IProfile, SearchableProfile } from 'models/profile';

type Props = {
	subscribers: Subscriber[];
	canRemoveSubscriber: boolean;
	onRemove: (profile: SearchableProfile) => void;
	slotId: string;
	q: string;
};

const SubscribersList = ({ subscribers: initialData = [], canRemoveSubscriber, onRemove, q, slotId }: Props) => {
	const dispatch = useDispatch();
	const [subscribers, setSubscribers] = React.useState<Subscriber[]>(initialData);

	const fetchSubscribers = React.useCallback(
		() =>
			performAsyncAction(dispatch, new AdminApi().getSlotSubscribers(slotId, { q }), (res: Subscriber[]) => {
				setSubscribers(res);
			}),
		[dispatch, q, slotId]
	);

	const onChange = (subscriberId: string, isChecked: boolean) =>
		performAsyncAction(
			dispatch,
			new AdminApi().updateSlotSubscriber(subscriberId, { fulfilled: isChecked }),
			(res: Subscriber) => {
				setSubscribers(prev => prev.map(subscriber => (subscriber._id === res._id ? res : subscriber)));
			}
		);

	React.useEffect(() => {
		fetchSubscribers();
	}, [fetchSubscribers]);

	React.useEffect(() => {
		const socket = io(NetworkManager.socketUrl);
		socket.on(SocketEvent.REFRESH_SLOTS, () => {
			fetchSubscribers();
		});

		return () => {
			socket.disconnect();
		};
	}, [fetchSubscribers]);

	return (
		<List
			items={subscribers}
			renderItem={subscriber => (
				<SlotSubscriber
					key={(subscriber.profile as IProfile)._id}
					subscriber={subscriber}
					onChange={onChange}
					canRemoveSubscriber={canRemoveSubscriber}
					onRemove={onRemove}
				/>
			)}
			emptyState={
				<Grid container spacing={3}>
					<Grid item xs={6}>
						No subscribers found
					</Grid>
				</Grid>
			}
		/>
	);
};

export default SubscribersList;
