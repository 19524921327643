import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import React from 'react';

export interface IROption {
	value: any;
	label: string;
	selected?: boolean;
	disabled?: boolean;
}

export interface IRSelect {
	options: IROption[];
	onChange: (value: any) => void;
	minWidth?: number;
	multiple?: boolean;
	canBeEmpty?: boolean;
	dense?: boolean;
}
const RSelect: React.FC<IRSelect> = ({
	options,
	onChange,
	minWidth = 112,
	multiple = false,
	canBeEmpty = false,
	dense = false,
}) => {
	const useStyles = makeStyles(theme => ({
		root: {
			borderColor: '#9B9B9B',
			minWidth: minWidth,
			height: dense ? 33 : 45,
		},
		formControl: {
			maxWidth: minWidth,
		},
		menuPaper: {
			maxHeight: 150,
		},
	}));

	const getSelectedValue = React.useCallback(() => {
		const selectedValues = options.filter(o => o.selected);
		if (multiple) {
			return selectedValues.length ? selectedValues.map(({ value }) => value) : canBeEmpty ? [] : [options[0]?.value];
		}

		return selectedValues[0]?.value || options[0]?.value;
	}, [canBeEmpty, multiple, options]);

	const classes = useStyles();
	const [value, setValue] = React.useState(getSelectedValue());

	const handleChange = (event: any) => {
		const newValue = event.target.value;
		setValue(newValue);
		onChange(newValue);
	};

	React.useEffect(() => {
		setValue(getSelectedValue());
	}, [getSelectedValue, options]);

	return (
		<FormControl className={classes.formControl}>
			<Select
				className={classes.root}
				variant="outlined"
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={value}
				onChange={handleChange}
				multiple={multiple}
				displayEmpty={canBeEmpty}
				MenuProps={{
					classes: { paper: classes.menuPaper },
					disableScrollLock: false,
					anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
					transformOrigin: { vertical: 'top', horizontal: 'center' },
					getContentAnchorEl: null,
				}}
			>
				{options.map((option, index) => {
					return (
						<MenuItem disabled={option.disabled} key={index} {...option}>
							{option.label}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default RSelect;
