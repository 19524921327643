import React from 'react';
import RContainer from 'components/Container/RContainer';
import { useDispatch } from 'store';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Spacer } from 'components/general';
import { Resources } from 'enum/resources.enum';
import { useState } from 'react';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { CSVDownload, CSVLink } from 'react-csv';
import { IFeedback } from 'models/feedback';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { AdminApi } from 'framework/API/admin.api';
import { IProfileOrderData, IProfileUsage } from 'models/profileUsage';
import RDatePicker from 'components/DatePicker/RDatePicker';
import RListGroup from 'components/ListGroup/RListGroup';
import { IProfile } from 'models/profile';
import RSelect from 'components/Select/RSelect';
import { MONTHS_OPTIONS, YEAR_OPTIONS } from 'constants/dates.constants';
import { IProfileMisuse } from 'models/profileMisuse';
import moment from 'moment';
import { Link } from '@material-ui/core';

const ReportsPage: React.FC = () => {
	const dispatch = useDispatch();
	const [usersFeedbackData, setUsersFeedbackData] = useState<IFeedback[]>();
	const [feedbackStartDate, setFeedbackStartDate] = useState<Date>(new Date());
	const [feedbackEndDate, setFeedbackEndDate] = useState<Date>(new Date());
	const [usersPreferencesData, setUsersPreferencesData] = useState<IProfile[]>();
	const [usersUsageData, setUsersUsageData] = useState<IProfileUsage[]>();
	const [usageStartDate, setUsageStartDate] = useState<Date>(new Date());
	const [usageEndDate, setUsageEndDate] = useState<Date>(new Date());
	const [usersPenaltiesData, setUsersPenaltiesData] = useState<IProfileMisuse[]>();
	const [penaltiesMonth, setPenaltiesMonth] = useState(MONTHS_OPTIONS[0].value);
	const [penaltiesYear, setPenaltiesYear] = useState(YEAR_OPTIONS[0].value);
	const [usersTakeawayData, setUsersTakeawayData] = useState<IProfileOrderData[]>();
	const [takeawayStartDate, setTakeawayStartDate] = useState<Date>(new Date());
	const [takeawayEndDate, setTakeawayEndDate] = useState<Date>(new Date());
	const [usersTakeawayDishCountData, setUsersTakeawayDishCountData] = useState<Record<string, Record<string, number>> | null>(
		null
	);
	const [takeawayDishCountStartDate, setTakeawayDishCountStartDate] = useState<Date>(new Date());
	const [takeawayDishCountEndDate, setTakeawayDishCountEndDate] = useState<Date>(new Date());
	const emptyReportData: any = [{ 'No data': 0 }];

	const handleExportUserFeedback = () => {
		performAsyncAction(
			dispatch,
			new AdminApi().getProfilesFeedback(feedbackStartDate.toISOString(), feedbackEndDate.toISOString()),
			(res: IFeedback[]) => {
				setUsersFeedbackData(res.length > 0 ? res : emptyReportData);
				setUsersFeedbackData(undefined);
			}
		);
	};

	const handleExportUserPenalties = () => {
		performAsyncAction(dispatch, new AdminApi().getProfilesPenalties(penaltiesMonth, penaltiesYear), (res: IProfileMisuse[]) => {
			const data = [];
			//adjust res data
			res.forEach(item => {
				data.push({
					name: item.name,
					email: item.email,
					...item.misuse[0],
				});
				for (let i = 1; i < item.misuse.length; i++) {
					const misuse = item.misuse[i];
					data.push({
						name: '',
						email: '',
						...misuse,
					});
				}
				data.push({
					total_charge: item.total_charge,
				});
			});
			setUsersPenaltiesData(data.length > 0 ? data : emptyReportData);
			setUsersPenaltiesData(undefined);
		});
	};

	const handleExportUserPreferences = () => {
		performAsyncAction(dispatch, new AdminApi().getProfilesPreferences(), (res: IProfile[]) => {
			const data = [];
			//adjust res data
			res.forEach(profile => {
				data.push({
					name: profile.name,
					email: profile.email,
					'User Status': profile.status ? 'Active' : 'Inactive',
					'allergens - glutens': profile.questionnaire.allergens.glutens,
					'allergens - lactose': profile.questionnaire.allergens.lactose,
					'allergens - peanuts': profile.questionnaire.allergens.peanuts,
					'allergens - other': profile.questionnaire.allergens.other,
					breakfast: profile.questionnaire.breakfast,
					preferred_food: profile.questionnaire.preferred_food,
					...profile.questionnaire.preferred_vendors,
				});
			});
			setUsersPreferencesData(data.length > 0 ? data : emptyReportData);
			setUsersPreferencesData(undefined);
		});
	};

	const handleExportUsersUsage = (startDate: Date, endDate: Date) => {
		performAsyncAction(
			dispatch,
			new AdminApi().getProfilesUsage(startDate.toISOString(), endDate.toISOString()),
			(res: IProfileUsage[]) => {
				const data: Partial<IProfileUsage & Record<'price', number>>[] = [];
				//adjust res data
				res.forEach(item => {
					data.push({
						name: item.name,
						email: item.email,
						...item.usage[0],
						price: item.usage[0] && item.usage[0].price,
					});
					for (let i = 1; i < item.usage.length; i++) {
						const usage = item.usage[i];
						data.push({
							name: '',
							email: '',
							...usage,
						});
					}
					data.push({
						total_price: item.total_price,
					});
				});
				setUsersUsageData(data.length > 0 ? data : emptyReportData);
				setUsersUsageData(undefined);
			}
		);
	};
	const handleExportUsersTakeawayData = (startDate: Date, endDate: Date) => {
		performAsyncAction(
			dispatch,
			new AdminApi().getProfilesTakeawayData(startDate.toISOString(), endDate.toISOString()),
			(data: IProfileOrderData[]) => {
				setUsersTakeawayData(data.length > 0 ? data : emptyReportData);
				setUsersTakeawayData(undefined);
			}
		);
	};

	const handleExportUsersTakeawayDishCountData = (startDate: Date, endDate: Date) => {
		performAsyncAction(
			dispatch,
			new AdminApi().getTakeawayDishCountData(startDate.toISOString(), endDate.toISOString()),
			(data: Record<string, Record<string, number>>) => {
				setUsersTakeawayDishCountData(Object.keys(data ?? {}).length > 0 ? data : null);
			}
		);
	};

	return (
		<RContainer>
			<Spacer />
			<RLabel value={Resources.REPORTS_SCREEN_TITLE} type={RLabelTypes.BOLD_25} />
			<Spacer />
			<RLabel value={Resources.REPORTS_SCREEN_SUBTITLE} type={RLabelTypes.REGULAR_18} />

			<Spacer />

			<RLabel value={Resources.REPORTS_SCREEN_USERS_USAGE_REPORT} type={RLabelTypes.REGULAR_25} />
			<Spacer />
			<RListGroup horizontal>
				<RLabel value={Resources.FROM} type={RLabelTypes.BOLD_18} />
				<RDatePicker
					onChange={setUsageStartDate}
					//prevent from selecting future date
					filterDate={date => moment(date).isBefore(usageEndDate)}
				/>

				<RLabel value={Resources.TO} type={RLabelTypes.BOLD_18} />
				<RDatePicker
					onChange={setUsageEndDate}
					//prevent from selecting future date
					filterDate={date => moment(date).isSameOrAfter(usageStartDate, 'day')}
				/>

				<RButton
					onClick={() => {
						handleExportUsersUsage(usageStartDate, usageEndDate);
					}}
					removeMargin
					theme={RButtonTheme.DARK}
					label={Resources.REPORTS_EXPORT_ACTION_TITLE}
				/>
			</RListGroup>

			<Spacer />

			<RLabel value={Resources.REPORTS_SCREEN_USERS_TAKEAWAY_REPORT} type={RLabelTypes.REGULAR_25} />
			<Spacer />
			<RListGroup horizontal>
				<RLabel value={Resources.FROM} type={RLabelTypes.BOLD_18} />
				<RDatePicker
					onChange={setTakeawayStartDate}
					//prevent from selecting future date
					filterDate={date => moment(date).isBefore(new Date()) && moment(date).isBefore(takeawayEndDate)}
				/>

				<RLabel value={Resources.TO} type={RLabelTypes.BOLD_18} />
				<RDatePicker
					onChange={setTakeawayEndDate}
					//prevent from selecting future date
					filterDate={date =>
						moment(date).isSameOrAfter(takeawayStartDate, 'day') && moment(date).isSameOrBefore(new Date(), 'day')
					}
				/>

				<RButton
					onClick={() => {
						handleExportUsersTakeawayData(takeawayStartDate, takeawayEndDate);
					}}
					removeMargin
					theme={RButtonTheme.DARK}
					label={Resources.REPORTS_EXPORT_ACTION_TITLE}
				/>
			</RListGroup>

			<Spacer />

			<RLabel value={Resources.REPORTS_SCREEN_TAKEAWAY_DISH_COUNT_REPORT} type={RLabelTypes.REGULAR_25} />
			<Spacer />
			<RListGroup horizontal>
				<RLabel value={Resources.FROM} type={RLabelTypes.BOLD_18} />
				<RDatePicker
					onChange={setTakeawayDishCountStartDate}
					//prevent from selecting future date
					filterDate={date => moment(date).isBefore(new Date()) && moment(date).isBefore(takeawayDishCountEndDate)}
				/>

				<RLabel value={Resources.TO} type={RLabelTypes.BOLD_18} />
				<RDatePicker
					onChange={setTakeawayDishCountEndDate}
					//prevent from selecting future date
					filterDate={date =>
						moment(date).isSameOrAfter(takeawayDishCountStartDate, 'day') && moment(date).isSameOrBefore(new Date(), 'day')
					}
				/>

				<RButton
					onClick={() => {
						handleExportUsersTakeawayDishCountData(takeawayDishCountStartDate, takeawayDishCountEndDate);
					}}
					removeMargin
					theme={RButtonTheme.DARK}
					label={Resources.REPORTS_EXPORT_ACTION_TITLE}
				/>
			</RListGroup>

			<Spacer />

			{usersTakeawayDishCountData && (
				<div className="dish-count-reports">
					{Object.entries(usersTakeawayDishCountData).map(([date, values], i) => {
						const data = Object.entries(values).map(([name, count]) => ({
							name,
							count,
						}));
						return (
							<Link color="primary" component={CSVLink} key={date} filename={`Takeaway dish count - ${date}`} data={data}>
								Download dish report for {date}.csv <br />
							</Link>
						);
					})}
				</div>
			)}

			<Spacer />

			<RLabel value={Resources.REPORTS_SCREEN_USERS_PENALTIES_REPORT} type={RLabelTypes.REGULAR_25} />
			<Spacer />
			<RListGroup horizontal>
				<RLabel value={Resources.MONTH} type={RLabelTypes.BOLD_18} />
				<RSelect options={MONTHS_OPTIONS} onChange={setPenaltiesMonth} minWidth={150} />

				<RLabel value={Resources.YEAR} type={RLabelTypes.BOLD_18} />
				<RSelect options={YEAR_OPTIONS} onChange={setPenaltiesYear} />
				<Spacer />
				<RButton
					onClick={() => {
						handleExportUserPenalties();
					}}
					removeMargin
					theme={RButtonTheme.DARK}
					label={Resources.REPORTS_EXPORT_ACTION_TITLE}
				/>
			</RListGroup>

			<Spacer />

			<RLabel value={Resources.REPORTS_SCREEN_USERS_FEEDBACK_REPORT} type={RLabelTypes.REGULAR_25} />
			<Spacer />
			<RListGroup horizontal>
				<RLabel value={Resources.FROM} type={RLabelTypes.BOLD_18} />
				<RDatePicker
					onChange={setFeedbackStartDate}
					//prevent from selecting future date
					filterDate={date => moment(date).isBefore(new Date()) && moment(date).isBefore(feedbackEndDate)}
				/>

				<RLabel value={Resources.TO} type={RLabelTypes.BOLD_18} />
				<RDatePicker
					onChange={setFeedbackEndDate}
					//prevent from selecting future date
					filterDate={date =>
						moment(date).isSameOrAfter(feedbackStartDate, 'day') && moment(date).isSameOrBefore(new Date(), 'day')
					}
				/>
				<RButton
					onClick={handleExportUserFeedback}
					removeMargin
					theme={RButtonTheme.DARK}
					label={Resources.REPORTS_EXPORT_ACTION_TITLE}
				/>
			</RListGroup>

			<Spacer />

			<RLabel value={Resources.REPORTS_SCREEN_USERS_PREFERENCES_REPORT} type={RLabelTypes.REGULAR_25} />
			<Spacer />
			<RButton
				onClick={handleExportUserPreferences}
				removeMargin
				theme={RButtonTheme.DARK}
				label={Resources.REPORTS_EXPORT_ACTION_TITLE}
			/>

			<Spacer />
			{usersFeedbackData && <CSVDownload data={usersFeedbackData} target="_blank" />}
			{usersUsageData && <CSVDownload data={usersUsageData} target="_blank" />}
			{usersPreferencesData && <CSVDownload data={usersPreferencesData} target="_blank" />}
			{usersPenaltiesData && <CSVDownload data={usersPenaltiesData} target="_blank" />}
			{usersTakeawayData && <CSVDownload data={usersTakeawayData} target="_blank" />}
		</RContainer>
	);
};

export default ReportsPage;
