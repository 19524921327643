import { makeStyles, Divider } from '@material-ui/core';
import React from 'react';
import { Spacer } from 'components/general';
import styled from 'styled-components';

export interface RDividerProps {
	color?: string;
}

export const VerticalDivider = styled.div`
	border: 1px solid #616161;
	height: 17px;
	margin-right: 10px;
	margin-left: 10px;
`;

const RDivider: React.FC<RDividerProps> = props => {
	const useStyles = makeStyles(theme => ({
		root: {
			backgroundColor: props.color || theme.palette.primary.light,
			height: '2px !important',
		},
	}));
	const classes = useStyles();
	return (
		<div>
			<Spacer />
			<Divider className={classes.root} />
			<Spacer />
		</div>
	);
};

export default RDivider;
