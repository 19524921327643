import axios from 'axios';
import BaseApi from 'framework/base.api';
import NetworkManager from 'framework/NetworkManager';

export class ScheduleSlotsApi extends BaseApi {
	constructor() {
		super();
		this.route = this.getRoute();
	}

	getRoute(): string {
		return `${NetworkManager.apiEndpoint}/slots-scheduler-templates`;
	}

	publishTemplate(id: string, start_date: Date) {
		return axios.post(`${this.route}/${id}/schedule-slots`, { start_date });
	}
}
