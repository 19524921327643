/**
 * This layer is responsible to manage data from local storage or any use of db
 */
/* eslint-disable */
export default abstract class BaseModel<Type, PlanObject> {
	protected tableName: string;

	abstract getTableName(): string;

	constructor() {
		this.tableName = this.getTableName();
	}

	readBy(key: string) {
		return localStorage.getItem(key);
	}

	insert(key: string, item: any) {
		localStorage.setItem(key, item);
	}

	removeItem(key: string) {
		localStorage.removeItem(key);
	}

	clear() {
		localStorage.clear();
	}
}
