import React, { useEffect } from 'react';
import { Link, TextField } from '@material-ui/core';
import RContainer from 'components/Container/RContainer';
import { useDispatch } from 'store';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { HoverItem, Spacer } from 'components/general';
import Table from 'components/Table';
import { Trash } from 'components/Icons/Icons';
import { Resources } from 'enum/resources.enum';
import { useState } from 'react';
import { IAdmin } from 'models/admin';
import { AdminApi } from 'framework/API/admin.api';
import { Role, roleToString } from 'enum/role.enum';
import RSelect, { IROption } from 'components/Select/RSelect';
import RListGroup from 'components/ListGroup/RListGroup';

const PermissionsPage: React.FC = () => {
	const dispatch = useDispatch();
	const [admins, setAdmins] = useState<IAdmin[]>([]);
	const [currentNewAdmin, setCurrentNewAdmin] = useState<IAdmin>();

	useEffect(() => {
		performAsyncAction(dispatch, new AdminApi().read(), (res: IAdmin[]) => {
			setAdmins(res);
		});
	}, [dispatch]);

	const buildOptionsForRow = (admin: IAdmin): IROption[] => {
		return Object.values(Role)
			.filter(a => {
				return a !== 1 && Number(a);
			})
			.map((role: Role) => {
				return { label: roleToString(role as Role), value: role, selected: admin.role.includes(role) };
			});
	};

	const handleRowChangedForAdmin = (admin: IAdmin, isSelectedNoneRole = false) => {
		let role = admin.role;
		if (!role.length || isSelectedNoneRole) role = [Role.NONE];
		else if (role.length > 1) role = role.filter(item => item !== Role.NONE);

		performAsyncAction(
			dispatch,
			admin.id
				? new AdminApi().update(admin.id, { role, email: admin.email })
				: new AdminApi().create({ role, email: admin.email }),
			(res: IAdmin) => {
				const editingAdminIndex = admins.findIndex(a => {
					return a.id === res.id;
				});
				if (editingAdminIndex > -1) {
					//means we are editing a set
					const newArr = [...admins];
					newArr[editingAdminIndex].role = res.role;
					setAdmins([...newArr]);
				} else {
					//means we are adding new one
					admins.push(res);
					setAdmins([...admins]);
					setCurrentNewAdmin(undefined);
				}
			}
		);
	};

	const handleRemoveAdmin = (admin: IAdmin, index: number) => {
		if (admin.id) {
			//means we are deleting an admin
			performAsyncAction(dispatch, new AdminApi().delete(admin.id), (res: IAdmin[]) => {
				admins.splice(index, 1);
				setAdmins([...admins]);
			});
		} else {
			//means we are deleting some row
			admins.splice(index, 1);
			setAdmins([...admins]);
		}
	};

	const handleAddRow = () => {
		setCurrentNewAdmin({ email: '', role: [Role.Barber], id: '' });
	};

	return (
		<RContainer>
			<Spacer />
			<RLabel value={Resources.PERMISSIONS_SCREEN_TITLE} type={RLabelTypes.BOLD_25}></RLabel>
			<Spacer />
			<Table.Container>
				<Table.Head>
					<Table.HeadRow>
						<Table.Cell style={{ width: '60%' }}>
							<RLabel value={Resources.PERMISSIONS_SCREEN_TABLE_HEADER_CELL_1} type={RLabelTypes.BOLD_16} />
						</Table.Cell>
						<Table.Cell>
							<RLabel value={Resources.PERMISSIONS_SCREEN_TABLE_HEADER_CELL_2} type={RLabelTypes.BOLD_16} />
						</Table.Cell>
						<Table.Cell />
					</Table.HeadRow>
				</Table.Head>
				<Table.Body>
					{admins.map((admin, index) => (
						<Table.Row key={admin.email}>
							<Table.Cell>{admin.email}</Table.Cell>

							<Table.Cell>
								<RListGroup horizontal>
									<RSelect
										minWidth={220}
										multiple
										options={buildOptionsForRow(admin)}
										onChange={value => {
											const isSelectedNoneRole = value.includes(Role.NONE) && !admin.role.includes(Role.NONE);
											handleRowChangedForAdmin({ ...admin, role: value }, isSelectedNoneRole);
										}}
									/>
									<HoverItem
										onClick={() => {
											handleRemoveAdmin(admin, index);
										}}
									>
										{Trash}
									</HoverItem>
								</RListGroup>
							</Table.Cell>
							<Table.Cell />
						</Table.Row>
					))}

					{currentNewAdmin ? (
						<Table.Row>
							<Table.Cell>
								<TextField
									value={currentNewAdmin.email || ''}
									onBlur={e => {
										handleRowChangedForAdmin({ ...currentNewAdmin, email: e.target.value });
									}}
									variant="outlined"
									size="small"
									onChange={e => {
										setCurrentNewAdmin({ ...currentNewAdmin, email: e.target.value });
									}}
								/>
							</Table.Cell>
							<Table.Cell>
								<RListGroup horizontal>
									<RSelect
										multiple
										minWidth={220}
										options={buildOptionsForRow(currentNewAdmin)}
										onChange={value => {
											setCurrentNewAdmin({ ...currentNewAdmin, role: value });
										}}
									/>
									<HoverItem
										onClick={() => {
											setCurrentNewAdmin(undefined);
										}}
									>
										{Trash}
									</HoverItem>
								</RListGroup>
							</Table.Cell>
						</Table.Row>
					) : null}

					<Table.Row>
						<Table.Cell>
							<HoverItem>
								<Link
									onClick={() => {
										handleAddRow();
									}}
								>
									<RLabel value={Resources.PERMISSIONS_SCREEN_TABLE_ADD_ROW_LABEL} type={RLabelTypes.BOLD_16} />
								</Link>
							</HoverItem>
						</Table.Cell>
						<Table.Cell />
					</Table.Row>
				</Table.Body>
			</Table.Container>
		</RContainer>
	);
};

export default PermissionsPage;
