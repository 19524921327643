import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import ReportsPage from 'features/ReportsPage/ReportsPage';

export class ReportsPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.REPORTS;
	}

	getParams(): string[] {
		return [];
	}

	getComponent(): React.FC<any> {
		return ReportsPage;
	}

	isAdminRoute(): boolean {
		return true;
	}
}
