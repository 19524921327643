import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import DashboardPage from 'features/DashboardPage/DashboardPage';

export class DashboardPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.DASHBOARD;
	}

	getParams(): string[] {
		return [];
	}

	getComponent(): React.FC<any> {
		return DashboardPage;
	}

	isAdminRoute(): boolean {
		return true;
	}
}
