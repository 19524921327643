// authProvider.js
import firebase from 'firebase/app';
import 'firebase/auth';

const getConfig = () => {
	return {
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_KEY,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	};
};
const config = getConfig();
firebase.initializeApp(config);
export class AuthProvider {
	static auth = firebase.auth();

	static googleProvider = new firebase.auth.GoogleAuthProvider();

	static async signInWithGoogle() {
		try {
			const res = await AuthProvider.auth.signInWithPopup(AuthProvider.googleProvider);
			return res;
		} catch (error) {
			console.log(error.message);
		}
	}

	static getAccessToken = () => {
		return AuthProvider.auth.currentUser?.getIdToken();
	};

	static logout() {
		console.log('logged out by function');
		AuthProvider.auth.signOut();
	}
}
