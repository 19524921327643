import React from 'react';
import { getSelectedTime, HourAndMinutes } from 'features/ScheduleSlotsPage/ScheduleSlotsPage.types';
import Autocomplete, { Option } from 'components/Autocomplete/Autocomplete';
import Grid from '@material-ui/core/Grid';

export interface RStartEndSelectTimesProps {
	index: number;
	startOptions: Option[];
	endOptions: Option[];
	onStartOptionsChange: (index: number, options: Option[]) => void;
	onEndOptionsChange: (index: number, options: Option[]) => void;
	duration?: number;
}

export const RStartEndSelectTimes: React.FC<RStartEndSelectTimesProps> = ({
	index,
	startOptions,
	endOptions,
	onStartOptionsChange,
	onEndOptionsChange,
	duration,
}) => {
	const handleStartOptionsChange = (value: string) => {
		onStartOptionsChange(index, getNewOptions(startOptions, value));
	};

	const handleEndOptionsChange = (value: string) => {
		onEndOptionsChange(index, getNewOptions(endOptions, value));
	};

	const getNewOptions = (options: Option[], selectOptionValue: string) => {
		const newStartOptions = options.map(a => {
			return { ...a, selected: a.value === selectOptionValue };
		});
		return newStartOptions;
	};

	const processedStartOptions = React.useMemo(
		() =>
			startOptions.map(o => {
				const endSelectedTime = getSelectedTime(endOptions);
				const [hours, minutes] = (o.value ?? '00:00').split(':').map(Number);
				const optionalStartTime = new HourAndMinutes(hours, minutes);
				optionalStartTime.addDuration(duration || 0);
				return { ...o, disabled: optionalStartTime.gt(endSelectedTime) };
			}),
		[duration, endOptions, startOptions]
	);

	const processedEndOptions = React.useMemo(
		() =>
			endOptions.map(o => {
				const startSelectedTime = getSelectedTime(startOptions);
				const [hours, minutes] = (o.value ?? '00:00').split(':').map(Number);
				const optionalEndTime = new HourAndMinutes(hours, minutes);

				return { ...o, disabled: optionalEndTime.lte(startSelectedTime) };
			}),
		[endOptions, startOptions]
	);

	return (
		<Grid container>
			<Grid item>
				<Autocomplete
					dense
					options={processedStartOptions}
					onChange={handleStartOptionsChange}
					value={processedStartOptions.find(({ selected }) => selected)}
				/>
			</Grid>
			<Grid item>
				<div style={{ padding: '0 25px' }}>-</div>
			</Grid>
			<Grid item>
				<Autocomplete
					dense
					options={processedEndOptions}
					onChange={handleEndOptionsChange}
					value={processedEndOptions.find(({ selected }) => selected)}
				/>
			</Grid>
		</Grid>
	);
};
