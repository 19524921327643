import BaseApi from 'framework/base.api';
import axios from 'axios';
import NetworkManager from 'framework/NetworkManager';

export class LoginApi extends BaseApi {
	constructor() {
		super();
		this.route = this.getRoute();
	}

	getRoute(): string {
		return NetworkManager.adminEndpoint;
	}

	signIn(fbToken: string | null) {
		return axios.post(`/${this.route}/auth/sign-in`, {}, { headers: { Authorization: `Bearer ${fbToken}` } });
	}

	getRelatedVendor() {
		return axios.get(`/${this.route}/vendors/associated`);
	}
}
