import { makeStyles, Grid, Dialog, DialogTitle, DialogContent, TextField } from '@material-ui/core';
import dateFormat from 'dateformat';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'store';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { showSnackbar } from 'store/slices/app.slice';
import { BEAUTIFUL_DISPLAY_DATE_FORMAT } from 'constants/dates.constants';
import { Resources } from 'enum/resources.enum';
import { Role } from 'enum/role.enum';
import { SocketEvent } from 'enum/socket-events';
import { SlotsApi } from 'framework/API/slots.api';
import { AdminApi } from 'framework/API/admin.api';
import { IServiceParams } from 'features/ScheduleSlotsPage/ScheduleSlotsPage';
import {
	buildHoursSelectOptions,
	getDefaultDayProperties,
	getSelectedTime,
	HourAndMinutes,
} from 'features/ScheduleSlotsPage/ScheduleSlotsPage.types';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import NetworkManager from 'framework/NetworkManager';
import { IAdmin } from 'models/admin';
import { ISlot } from 'models/slot';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import RDatePicker from 'components/DatePicker/RDatePicker';
import RDivider from 'components/Divider/RDivider';
import { FloatRight, SmallSpacer } from 'components/general';
import RImageAndLabel from 'components/ImageAndLabel/RImageAndLabel';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { RStartEndSelectTimes } from 'components/ScheduleSlotsDaysTable/ScheduleSlotsDayRow/StartEndSelectTimes';
import RSelect, { IROption } from 'components/Select/RSelect';
import RSmallTextField from 'components/SmallTextField/RSmallTextField';
import { roleSelector } from 'store/selectors/app.selector';
import { currentVendorSelector } from 'store/selectors/vendor.selectors';
import { Location } from 'models/locations';
import { LocationsApi } from 'framework/API/locations.api';
import styled from 'styled-components';

export const useStyles = makeStyles(theme => ({
	root: {},
}));

export interface IRSlotDialogProps {
	slot?: ISlot;
	open: boolean;
	onClose: () => void;
	onEditSuccess: (slot: ISlot) => void;
}

const SlotDialog: React.FC<IRSlotDialogProps> = props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const currentVendor = useSelector(currentVendorSelector, shallowEqual);
	const { vendorId } = useParams<IServiceParams>();
	const role = useSelector(roleSelector);
	const [currentData, setCurrentData] = React.useState<ISlot | undefined>(props.slot);
	const [startOptions, setStartOptions] = React.useState<IROption[]>([]);
	const [endOptions, setEndOptions] = React.useState<IROption[]>([]);
	const [datePickerValue, setDatePickerValue] = React.useState<Date>(props.slot?.start_date || new Date());
	const [notificationData, setNotificationData] = React.useState('');
	const supportsCustomLocation = currentVendor?.vendor?.configuration?.is_location_feature_available ?? false;

	const [vendorAdmins, setVendorAdmins] = React.useState<IAdmin[]>([]);

	const [locations, setLocations] = React.useState<Location[]>([]);

	const fetchLocations = React.useCallback(
		() =>
			performAsyncAction(dispatch, new LocationsApi().searchBy(currentVendor?.vendor?.id), (res: Location[]) =>
				setLocations(res)
			),
		[dispatch, currentVendor]
	);

	React.useEffect(() => {
		fetchLocations();
	}, [fetchLocations]);

	const sessionLocationOptions = React.useMemo(() => {
		const options = locations.map(item => {
			return {
				label: item.name,
				value: item.name,
				selected: currentData?.location === item.name,
			};
		});
		return [
			{
				label: 'Not specified',
				value: '',
				selected: currentData?.location === '',
			},
			...options,
		];
	}, [currentData?.location, locations]);

	React.useEffect(() => {
		if (role.includes(Role.Admin)) {
			performAsyncAction(dispatch, new AdminApi().getAssociatedAdmins(vendorId), (res: IAdmin[]) => {
				setVendorAdmins(res);
			});
		}
		setNotificationData('');
	}, [role, dispatch, props.slot, vendorId]);

	const saveSlot = () => {
		if (currentData) {
			performAsyncAction(dispatch, new SlotsApi().update(currentData.id, currentData), () => {
				dispatch(showSnackbar({ isOpen: true, label: Resources.MANAGE_SLOTS_SCREEN_SUCCESS_EDIT, severity: 'success' }));
				//Send notification to slot subscribers
				if (currentData.subscribers.length > 0) {
					performAsyncAction(dispatch, new AdminApi().notifySlotSubscribers(currentData.id, notificationData), () => {});
				}
				props.onEditSuccess(currentData);
				props.onClose();
			});
		}
	};

	React.useEffect(() => {
		const socket = io(NetworkManager.socketUrl);
		socket.on(SocketEvent.REFRESH_SLOTS, slot => {
			const updatedSlot = slot as ISlot;
			if (updatedSlot?.id === props.slot?.id && props.open) {
				setCurrentData(prev => ({
					...prev,
					subscribers: updatedSlot.subscribers,
					subscribers_amount: updatedSlot.subscribers_amount,
				}));
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [props.slot, currentData, props.open]);

	React.useEffect(() => {
		setCurrentData(props.slot);
		setDatePickerValue(props.slot?.start_date || new Date());
		if (props.slot) {
			setStartOptions(
				buildHoursSelectOptions(getDefaultDayProperties(), [
					new HourAndMinutes(props.slot.start_date.getHours(), props.slot.start_date.getMinutes()),
				])
			);
			setEndOptions(
				buildHoursSelectOptions(getDefaultDayProperties(), [
					new HourAndMinutes(props.slot.end_date.getHours(), props.slot.end_date.getMinutes()),
				])
			);
		}
	}, [props.slot]);

	const handleDateChange = (date: Date) => {
		setDatePickerValue(date);
		if (!currentData) return;
		const start_date = new Date(date);
		start_date.setHours(currentData.start_date.getHours(), currentData.start_date.getMinutes(), 0, 0);
		const end_date = new Date(date);
		end_date.setHours(currentData.end_date.getHours(), currentData.end_date.getMinutes(), 0, 0);
		setCurrentData(prev => ({
			...prev,
			start_date,
			end_date,
			display_day: dateFormat(start_date, BEAUTIFUL_DISPLAY_DATE_FORMAT),
		}));
	};

	const handleEndTimeChange = (date: Date) => {
		setCurrentData(prev => prev && { ...prev, end_date: date });
	};

	const handleStartTimeChange = (date: Date) => {
		setCurrentData(prev => prev && { ...prev, start_date: date });
	};

	const handleTimeOptionsChange = (options: IROption[], startTime: boolean) => {
		const time = getSelectedTime(options);
		const date = new Date(datePickerValue);
		date.setHours(time.hour, time.minutes, 0, 0);
		if (startTime) setStartOptions(options);
		else setEndOptions(options);

		if (startTime) handleStartTimeChange(date);
		else handleEndTimeChange(date);
	};

	const handleParticipantMaxChange = (value: string) => {
		setCurrentData(prev => prev && { ...prev, capacity: Number(value) });
	};

	const handleParticipantMinChange = (value: string) => {
		setCurrentData(prev => prev && { ...prev, minimum_subscribers: Number(value) });
	};

	const handleSessionNameChange = (value: string) => {
		setCurrentData(prev => prev && { ...prev, type: { ...prev.type, name: value } });
	};

	const handleLocationChange = (value: string) => {
		setCurrentData(prev => prev && { ...prev, location: value });
	};

	return (
		<Dialog className={classes.root} fullWidth maxWidth={'md'} onClose={props.onClose} open={props.open}>
			<DialogTitle id="max-width-dialog-title">
				<RLabel value={Resources.EDIT_SLOT_DIALOG_TITLE} type={RLabelTypes.BOLD_18} />
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={3} alignItems="center">
					<Grid item xs={6}>
						{/* Service Name Field */}
						<RLabel value={Resources.EDIT_SLOT_DIALOG_FIELD_1_LABEL} type={RLabelTypes.BOLD_18} />
						<SmallSpacer />
						<FieldWrapper>
							<TextField
								variant="outlined"
								fullWidth
								defaultValue={currentData?.type.name}
								onChange={e => {
									handleSessionNameChange(e.target.value);
								}}
								placeholder={Resources.SERVICE_SCREEN_FIELD_1_PLACEHOLDER}
							/>
						</FieldWrapper>
					</Grid>
					<Grid item xs={6}>
						{supportsCustomLocation && (
							<>
								<RLabel value={Resources.SCHEDULE_SLOTS_SCREEN_LOCATION_TITLE} type={RLabelTypes.BOLD_18} />
								<SmallSpacer />
								<RSelect dense canBeEmpty options={sessionLocationOptions} onChange={handleLocationChange} minWidth={256} />
							</>
						)}
					</Grid>
					<Grid item xs={6} style={{ marginTop: 3 }}>
						<RLabel value={Resources.SERVICE_SCREEN_FIELD_2_LABEL} type={RLabelTypes.BOLD_18} />
						<SmallSpacer />
						{role.includes(Role.Admin) && vendorAdmins.length > 0 ? (
							<RSelect
								dense
								onChange={operator => {
									setCurrentData(prev => prev && { ...prev, type: { ...prev.type, operator } });
								}}
								minWidth={250}
								options={vendorAdmins.map(a => {
									return {
										label: a.profile?.name || '',
										value: a.profile,
										selected: currentData?.type.operator?._id === a.profile?._id,
									};
								})}
							/>
						) : (
							<RImageAndLabel imageUrl={currentData?.type.operator.picture} label={currentData?.type.operator.name} />
						)}
					</Grid>

					<Grid item xs={6}>
						<RLabel value={Resources.EDIT_SLOT_DIALOG_FIELD_5_LABEL} type={RLabelTypes.BOLD_18} />
						<SmallSpacer />
						<Grid container>
							<Grid item>
								<FieldWrapper>
									<RSmallTextField
										dense
										label={Resources.SCHEDULE_SLOTS_SCREEN_PARTICIPANTS_MIN_LABEL}
										value={currentData?.minimum_subscribers}
										onChange={handleParticipantMinChange}
									/>
								</FieldWrapper>
							</Grid>
							<Grid item>
								<FieldWrapper>
									<RSmallTextField
										dense
										label={Resources.SCHEDULE_SLOTS_SCREEN_PARTICIPANTS_MAX_LABEL}
										value={currentData?.capacity}
										onChange={handleParticipantMaxChange}
									/>
								</FieldWrapper>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={6}>
						<RLabel value={Resources.EDIT_SLOT_DIALOG_FIELD_3_LABEL} type={RLabelTypes.BOLD_18} />
						<FieldWrapper>
							<RDatePicker startDate={datePickerValue} onChange={handleDateChange} />
						</FieldWrapper>
					</Grid>

					<Grid item xs={6}>
						<RLabel value={Resources.EDIT_SLOT_DIALOG_FIELD_4_LABEL} type={RLabelTypes.BOLD_18} />
						<SmallSpacer />
						<FieldWrapper>
							<RStartEndSelectTimes
								index={0}
								startOptions={startOptions}
								endOptions={endOptions}
								onEndOptionsChange={(index, options) => {
									handleTimeOptionsChange(options, false);
								}}
								onStartOptionsChange={(index, options) => {
									handleTimeOptionsChange(options, true);
								}}
							/>
						</FieldWrapper>
					</Grid>

					<Grid item style={{ padding: 0 }} xs={12}>
						<RDivider />
					</Grid>

					<Grid item xs={12}>
						<RLabel value={Resources.EDIT_SLOT_DIALOG_MESSAGE_TITLE} type={RLabelTypes.BOLD_18} />
						<RLabel value={Resources.EDIT_SLOT_DIALOG_MESSAGE_SUBTITLE} />
						<SmallSpacer />
						<TextField
							variant="outlined"
							onChange={e => {
								setNotificationData(e.target.value);
							}}
							multiline
							fullWidth
							placeholder={Resources.EDIT_SLOT_DIALOG_MESSAGE_PLACEHOLDER}
							minRows={3}
						/>
					</Grid>

					<Grid item xs={12}>
						<FloatRight>
							<RButton
								label={Resources.EDIT_SLOT_DIALOG_MESSAGE_BUTTON_CANCEL}
								theme={RButtonTheme.LIGHT}
								onClick={e => {
									props.onClose();
								}}
							/>
							<RButton
								label={Resources.EDIT_SLOT_DIALOG_MESSAGE_BUTTON_CONFIRM}
								theme={RButtonTheme.DARK}
								disabled={currentData?.subscribers.length > 0 ? notificationData.length === 0 : false}
								onClick={e => {
									saveSlot();
								}}
							/>
						</FloatRight>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default SlotDialog;

const FieldWrapper = styled.div`
	.MuiOutlinedInput-input {
		padding: 7px 14px;
	}

	.small-text-field {
		margin: 0 10px;
	}

	.react-datepicker__input-container input {
		height: 38px;
		padding-left: 12px;
	}

	.autocomplete {
		.MuiOutlinedInput-input {
			padding-top: 7px;
			padding-bottom: 7px;
		}
	}
`;
