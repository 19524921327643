import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import MenusPage from 'features/MenusPage/MenusPage';

export class MenusPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.MENUS;
	}

	getParams(): string[] {
		return ['vendorId'];
	}

	getComponent(): React.FC<any> {
		return MenusPage;
	}
}
