import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import ServicePage from 'features/ServicePage/ServicePage';

export class NewServicePageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.NEW_SERVICE_PAGE;
	}

	getParams(): string[] {
		return ['vendorId'];
	}

	getComponent(): React.FC<any> {
		return ServicePage;
	}
}
