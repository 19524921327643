import axios from 'axios';
import NetworkManager from 'framework/NetworkManager';

/**
 * This layer is responsible to interact with remote api
 */
export default abstract class BaseApi {
	protected route: string;

	abstract getRoute(): string;

	constructor() {
		NetworkManager.init();
		this.route = this.getRoute();
	}

	readById<T extends object>(id: string, params?: T) {
		return axios.get(`/${this.route}/${id}`, { params });
	}

	read() {
		return axios.get(`/${this.route}/`);
	}

	create(data: {}) {
		return axios.post(`/${this.route}/`, data);
	}

	update(id: string, data: {}) {
		return axios.put(`/${this.route}/${id}`, data);
	}

	delete(id: string) {
		return axios.delete(`/${this.route}/${id}`);
	}
}
