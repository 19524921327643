import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import RListGroup from 'components/ListGroup/RListGroup';
import RImageAndLabel from 'components/ImageAndLabel/RImageAndLabel';
import { IProfile, SearchableProfile } from 'models/profile';
import { WaitingListSubscriber } from 'models/waitingList';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';

type Props = {
	subscriber: WaitingListSubscriber;
	canRemoveSubscriber: boolean;
	onRemove: (profile: SearchableProfile) => void;
};

const WaitingListSubscriberItem = ({ subscriber, canRemoveSubscriber, onRemove }: Props) => {
	const profile = subscriber.profile as IProfile;

	return (
		<Grid container spacing={3} alignItems="center">
			<Grid item xs={12}>
				<RListGroup horizontal>
					{canRemoveSubscriber && (
						<IconButton
							color="primary"
							onClick={() =>
								onRemove({
									id: subscriber._id ?? subscriber?.id,
									name: profile.name,
									email: profile.email,
									status: profile.status,
									picture: profile.picture,
									first_login: '',
								})
							}
						>
							<ClearIcon />
						</IconButton>
					)}
					<RImageAndLabel imageUrl={profile.picture} label={profile.name ?? profile.email ?? 'Unnamed user'} />
					{subscriber?.subscription_notes ? (
						<Tooltip title={subscriber?.subscription_notes}>
							<Info color="primary" />
						</Tooltip>
					) : (
						''
					)}
				</RListGroup>
			</Grid>
		</Grid>
	);
};

export default WaitingListSubscriberItem;
