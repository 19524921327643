import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from 'components/List';
import { SearchableProfile } from 'models/profile';
import SlotCandidate from './SlotCandidate';

type Props = {
	items: SearchableProfile[];
	onSelect?: (profile: SearchableProfile | null) => void;
	onSuccess?: VoidFunction;
	slotId: string;
	selectedProfile: SearchableProfile | null;
};

const EmployeesList = ({ items = [], onSelect, selectedProfile }: Props) => {
	return (
		<List
			items={items}
			renderItem={profile => (
				<SlotCandidate key={profile.id} profile={profile} isSelected={profile.id === selectedProfile?.id} onClick={onSelect} />
			)}
			emptyState={
				<Grid container spacing={3}>
					<Grid item xs={6}>
						No employees found
					</Grid>
				</Grid>
			}
		/>
	);
};

export default EmployeesList;
