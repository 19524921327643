import { RootState } from 'store';

const appSelector = (state: RootState) => state.app;

export const isConnectedSelector = (state: RootState) => appSelector(state).isConnected;
export const onLoadingSelector = (state: RootState) => appSelector(state).onLoading;
export const relatedVendorSelector = (state: RootState) => appSelector(state).related_vendor;
export const roleSelector = (state: RootState) => appSelector(state).role;
export const snackbarSelector = (state: RootState) => appSelector(state).snackbar;
export const userSelector = (state: RootState) => appSelector(state).user;
