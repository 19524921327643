import React from 'react';
import { useDispatch } from 'store';
import { useParams } from 'react-router-dom';
import { Grid, TextField } from '@material-ui/core';
import { Spacer } from 'components/general';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { BaseRoute } from 'framework/Routes/Base.route';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Resources } from 'enum/resources.enum';
import RContainer from 'components/Container/RContainer';
import { Form } from 'react-bootstrap';
import RMenuCard, { RMenuCardProps } from 'features/MenusPage/RMenuCard';
import { IProductCategoryMenu } from 'models/productCategory';
import { IVendor, VendorConfiguration, VendorType } from 'models/vendor';
import { VendorsApi } from 'framework/API/vendor.api';
import { GetVendorResponse } from 'store/slices/vendor.slice';
import { IVendorParams } from 'features/MyServicesPage/MyServicesPage';
import { ROUTES } from 'constants/routes';
import { Upload } from 'components/Icons/Icons';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { MyServicesPageRoute } from 'framework/Routes/MyServicesPage.route';
import { DivContainer } from 'App';
import RBreadcrumbs from 'components/Breadcrumbs/RBreadcrumbs';
import { useRef } from 'react';
import { AdminApi } from 'framework/API/admin.api';
import { showSnackbar } from 'store/slices/app.slice';
import { buildHoursSelectOptions, HourAndMinutes } from 'features/ScheduleSlotsPage/ScheduleSlotsPage.types';
import RSelect from 'components/Select/RSelect';

const MenusPage: React.FC = () => {
	const [currentVendor, setCurrentVendor] = React.useState<IVendor | null>();
	const [vendorDescription, setVendorDescription] = React.useState<string>('');
	const [vendorCancellationTime, setVendorCancellationTime] = React.useState<number>(0);
	const [vendorMaxOrdersInTimeRange, setVendorMaxOrdersInTimeRange] = React.useState<number>(0);
	const [orderScheduleTimeRange, setOrderScheduleTimeRange] = React.useState<string[]>([]);
	const dispatch = useDispatch();
	const { vendorId } = useParams<IVendorParams>();
	const uploadInput = useRef<HTMLInputElement>(null);

	React.useEffect(() => {
		if (!currentVendor) {
			performAsyncAction(dispatch, new VendorsApi().readById(vendorId), (res: GetVendorResponse) => {
				setCurrentVendor(res.vendor);
				setVendorDescription(res.vendor?.configuration?.description || '');
				setVendorCancellationTime(res.vendor?.configuration?.cancelation_minutes_before_schdeule || 0);
				setVendorMaxOrdersInTimeRange(res.vendor?.configuration?.maximum_orders_in_time_range || 0);
				setOrderScheduleTimeRange(res.vendor?.configuration.order_schedule_time_range);
			});
		}
	}, [currentVendor, dispatch, vendorId]);

	const updateVendorDescription = () => {
		if (currentVendor) {
			const newConfiguration: VendorConfiguration = { ...currentVendor.configuration, description: vendorDescription };
			updateVendorConfiguration(newConfiguration);
		}
	};

	const updateVendorCancellationTime = () => {
		if (currentVendor) {
			const newConfiguration: VendorConfiguration = {
				...currentVendor.configuration,
				cancelation_minutes_before_schdeule: vendorCancellationTime,
			};
			updateVendorConfiguration(newConfiguration);
		}
	};

	const updateMaximumOrdersInTimeRange = () => {
		if (currentVendor) {
			const newConfiguration: VendorConfiguration = {
				...currentVendor.configuration,
				maximum_orders_in_time_range: vendorMaxOrdersInTimeRange,
			};
			updateVendorConfiguration(newConfiguration);
		}
	};

	const updateOrderTimeIntervals = (value: string[]) => {
		if (currentVendor) {
			const newConfiguration: VendorConfiguration = {
				...currentVendor.configuration,
				order_schedule_time_range: value.slice().sort((a, b) => (a > b ? 1 : -1)),
			};
			updateVendorConfiguration(newConfiguration);
		}
	};

	const updateVendorConfiguration = (configuration: VendorConfiguration) => {
		performAsyncAction(dispatch, new VendorsApi().update(vendorId, { configuration }), (vendor: IVendor) => {
			setCurrentVendor(vendor);
			setVendorDescription(vendor.configuration.description);
			setVendorCancellationTime(vendor.configuration.cancelation_minutes_before_schdeule);
			setVendorMaxOrdersInTimeRange(vendor.configuration.maximum_orders_in_time_range);
			setOrderScheduleTimeRange(vendor?.configuration.order_schedule_time_range);
		});
	};

	const menuOptions: RMenuCardProps[] = React.useMemo(
		() =>
			({
				[VendorType.CAFE]: [
					{ menu: IProductCategoryMenu.COFFEE, button: { label: Resources.MENUS_SCREEN_MENUS_SECTION_CARD_ACTION_TITLE } },
				],
				[VendorType.RESTAURANT]: [
					{
						menu: IProductCategoryMenu.RESTAURANT,
						button: {
							startIcon: Upload,
							theme: RButtonTheme.DARK,
							onClick: () => {
								if (uploadInput.current) {
									uploadInput.current.click();
								}
							},
							label: Resources.MENUS_SCREEN_MENUS_SECTION_CARD_UPLOAD_FILE,
						},
						link: { href: currentVendor?.menu_file?.url || '', label: currentVendor?.menu_file?.name || '' },
					},
					{
						menu: IProductCategoryMenu.TAKEAWAY,
						button: { label: Resources.MENUS_SCREEN_MENUS_SECTION_CARD_ACTION_TITLE },
					},
				],
				[VendorType.FRESH_BAR]: [
					{ menu: IProductCategoryMenu.FRESH_BAR, button: { label: Resources.MENUS_SCREEN_MENUS_SECTION_CARD_ACTION_TITLE } },
				],
			}[currentVendor?.type] ?? []),
		[currentVendor?.menu_file?.name, currentVendor?.menu_file?.url, currentVendor?.type]
	);

	const orderIntervalOptions = React.useMemo(
		() =>
			buildHoursSelectOptions(
				{
					start_time: new HourAndMinutes(0, 0),
					end_time: new HourAndMinutes(24, 0),
					max_participants: 0,
					location: null,
				},
				orderScheduleTimeRange.map(slot => {
					const [hour, minutes] = slot.split(':').map(Number);
					return new HourAndMinutes(hour, minutes);
				})
			),
		[orderScheduleTimeRange]
	);

	const handleManageSeatingsAction = () => {
		BaseRoute.navigateTo(new MyServicesPageRoute(), [vendorId]);
	};

	const onChangeFile = (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		var file = event.target.files[0];
		performAsyncAction(dispatch, new AdminApi().uploadMenu(vendorId, file), (res: IVendor) => {
			dispatch(showSnackbar({ isOpen: true, label: 'Upload success', severity: 'success' }));
			setCurrentVendor(res);
		});
	};

	return (
		<RContainer>
			<Spacer />
			<RBreadcrumbs links={[{ href: ROUTES.FOOD_DASHBOARD, label: Resources.MENUS_SCREEN_CURRENT_DIR_LINK }]} />
			<RLabel value={currentVendor?.configuration?.display_name || ''} type={RLabelTypes.BOLD_41} />
			<Form>
				<Form.Group>
					<Form.Label>
						<RLabel value={Resources.MENUS_SCREEN_SUBTITLE} type={RLabelTypes.REGULAR_16} />
					</Form.Label>
					<Form.Control
						value={vendorDescription}
						onBlur={updateVendorDescription}
						maxLength={150}
						onChange={e => setVendorDescription(e.target.value)}
						as="textarea"
						placeholder={Resources.MENUS_SCREEN_DESCRIPTION_FIELD_PLACE_HOLDER}
					/>
				</Form.Group>
			</Form>
			<Spacer />

			{/* Set Cancellation duration */}
			<RLabel value={Resources.MENUS_SCREEN_CANCELLATION_TITLE} type={RLabelTypes.BOLD_18} />
			<RLabel value={Resources.MENUS_SCREEN_CANCELLATION_SUBTITLE} type={RLabelTypes.REGULAR_16} />
			<Spacer />
			<TextField
				value={vendorCancellationTime}
				type="number"
				onBlur={updateVendorCancellationTime}
				variant="outlined"
				size="small"
				onChange={e => Number(e.target.value) >= 0 && setVendorCancellationTime(Number(e.target.value))}
			/>
			<Spacer />

			{/* Set max orders per 15 minutes slot */}
			<RLabel
				value={
					currentVendor?.type === VendorType.RESTAURANT
						? Resources.MENUS_SCREEN_MAX_TA_ORDERS_TITLE
						: Resources.MENUS_SCREEN_MAX_ORDERS_TITLE
				}
				type={RLabelTypes.BOLD_18}
			/>
			<RLabel value={Resources.MENUS_SCREEN_MAX_ORDERS_SUBTITLE} type={RLabelTypes.REGULAR_16} />
			<Spacer />
			<TextField
				value={vendorMaxOrdersInTimeRange}
				type="number"
				onBlur={updateMaximumOrdersInTimeRange}
				variant="outlined"
				size="small"
				onChange={e => Number(e.target.value) >= 0 && setVendorMaxOrdersInTimeRange(Number(e.target.value))}
			/>
			<Spacer />
			<RLabel value={Resources.MENUS_SCREEN_TA_ORDER_SCHEDULE_TITLE} type={RLabelTypes.BOLD_18} />
			<RLabel value={Resources.MENUS_SCREEN_TA_ORDER_SCHEDULE_SUBTITLE} type={RLabelTypes.REGULAR_16} />
			<Spacer />
			<RSelect options={orderIntervalOptions} onChange={updateOrderTimeIntervals} minWidth={350} multiple canBeEmpty />
			<Spacer />

			{/* Seating arrangements ONLY for Restaurant */}
			{currentVendor?.type === VendorType.RESTAURANT ? (
				<DivContainer>
					<RLabel value={Resources.MENUS_SCREEN_SITTING_ARRANGEMENT_TITLE} type={RLabelTypes.BOLD_25} />
					<RLabel value={Resources.MENUS_SCREEN_SITTING_ARRANGEMENT_SUBTITLE} type={RLabelTypes.REGULAR_16} />
					<Spacer />
					<RButton
						onClick={handleManageSeatingsAction}
						removeMargin
						label={Resources.MENUS_SCREEN_SITTING_ARRANGEMENT_ACTION_BUTTON}
						theme={RButtonTheme.DARK}
					/>
					<Spacer />
				</DivContainer>
			) : (
				''
			)}

			<RLabel value={Resources.MENUS_SCREEN_MENUS_SECTION_TITLE} type={RLabelTypes.BOLD_25} />
			<Spacer />
			<Grid container spacing={3}>
				{menuOptions.map((item, index) => (
					<Grid key={index} item xs={12} sm={12}>
						<RMenuCard key={index} {...item} />
					</Grid>
				))}
			</Grid>
			<input
				id="myInput"
				type="file"
				ref={uploadInput}
				style={{ display: 'none' }}
				accept="image/png, image/jpeg, .pdf, .docx"
				onChange={onChangeFile}
			/>
		</RContainer>
	);
};

export default MenusPage;
