import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Form, ListGroup } from 'react-bootstrap';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Resources } from 'enum/resources.enum';
import { FloatRight, Spacer } from 'components/general';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import type { WaitingListSubscriber } from 'models/waitingList';

type Props = {
	onSubmit?: (data: Pick<WaitingListSubscriber, 'subscription_notes'>) => void;
};

const AddSubscriberForm = ({ onSubmit }: Props) => {
	const [subscriptionNotes, setSubscriptionNotes] = React.useState<string>('');

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<ListGroup>
						<RLabel value={Resources.SUBSCRIBER_FORM_CONTROL_REASON_TITLE} type={RLabelTypes.BOLD_18} />
						<RLabel value={Resources.SUBSCRIBER_FORM_CONTROL_ADD_REASON_SUBTITLE} />
						<Form.Control
							maxLength={125}
							onChange={e => setSubscriptionNotes(e.target.value)}
							as="textarea"
							value={subscriptionNotes}
							placeholder={Resources.SUBSCRIBER_FORM_CONTROL_REASON_PLACEHOLDER}
							rows={3}
						/>
					</ListGroup>
				</Grid>
			</Grid>
			<Spacer />

			<Grid item xs={12}>
				<FloatRight>
					<RButton
						label={Resources.ADD}
						theme={RButtonTheme.DARK}
						disabled={!subscriptionNotes.length}
						onClick={e => {
							onSubmit({ subscription_notes: subscriptionNotes });
						}}
					/>
				</FloatRight>
			</Grid>
		</>
	);
};

export default AddSubscriberForm;
