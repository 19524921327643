import React from 'react';
import Grid from '@material-ui/core/Grid';
import WaitingListSubscriberItem from './WaitingListSubscriber';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { AdminApi } from 'framework/API/admin.api';
import { shallowEqual, useDispatch, useSelector } from 'store';
import NetworkManager from 'framework/NetworkManager';
import { SocketEvent } from 'enum/socket-events';
import { io } from 'socket.io-client';
import List from 'components/List';
import type { IProfile, SearchableProfile } from 'models/profile';
import type { WaitingListSubscriber } from 'models/waitingList';
import dateFormat from 'dateformat';
import { currentVendorSelector } from 'store/selectors/vendor.selectors';

type Props = {
	subscribers: WaitingListSubscriber[];
	canRemoveSubscriber: boolean;
	onRemove: (profile: SearchableProfile) => void;
	date: Date;
	q: string;
};

const SubscribersList = ({ subscribers: initialData = [], canRemoveSubscriber, onRemove, q, date }: Props) => {
	const currentVendor = useSelector(currentVendorSelector, shallowEqual);
	const dispatch = useDispatch();
	const [subscribers, setSubscribers] = React.useState<WaitingListSubscriber[]>(initialData);

	const fetchSubscribers = React.useCallback(() => {
		performAsyncAction(
			dispatch,
			new AdminApi().getWaitingListSubscribers({ q, date: dateFormat(date, 'yyyy-mm-dd'), vendorId: currentVendor?.vendor?.id }),
			(res: WaitingListSubscriber[]) => {
				setSubscribers(res);
			}
		);
	}, [dispatch, q, currentVendor?.vendor?.id, date]);

	React.useEffect(() => {
		fetchSubscribers();
	}, [fetchSubscribers]);

	React.useEffect(() => {
		const socket = io(NetworkManager.socketUrl);
		socket.on(SocketEvent.REFRESH_SLOTS, () => {
			fetchSubscribers();
		});

		return () => {
			socket.disconnect();
		};
	}, [fetchSubscribers]);

	return (
		<List
			items={subscribers}
			renderItem={subscriber => (
				<WaitingListSubscriberItem
					key={(subscriber.profile as IProfile)._id}
					subscriber={subscriber}
					canRemoveSubscriber={canRemoveSubscriber}
					onRemove={onRemove}
				/>
			)}
			emptyState={
				<Grid container spacing={3}>
					<Grid item xs={6}>
						No subscribers found
					</Grid>
				</Grid>
			}
		/>
	);
};

export default SubscribersList;
