import { makeStyles, Grid, Dialog, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import { Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import RSmallTextField from 'components/SmallTextField/RSmallTextField';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { useDispatch, useSelector } from 'store/store';
import { onLoadingSelector } from 'store/selectors/app.selector';
import styled from 'styled-components';
import { showSnackbar } from 'store/slices/app.slice';
import { Resources } from 'enum/resources.enum';
import { IVendor } from 'models/vendor';
import { LocationsApi } from 'framework/API/locations.api';
import { Location } from 'models/locations';
import List from 'components/List/List';

export const useStyles = makeStyles(theme => ({
	root: {
		padding: 30,
	},
}));

export interface EditLocationsDialogProps {
	vendor: IVendor;
	open: boolean;
	onClose: VoidFunction;
}

const EditLocationsDialog: React.FC<EditLocationsDialogProps> = ({ vendor, open, onClose }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isLoading = useSelector(onLoadingSelector);
	const inputRef = React.useRef<HTMLInputElement | null>(null);

	const [locations, setLocations] = React.useState<Location[]>([]);
	const [newLocation, setNewLocation] = React.useState<string>('');

	const fetchLocations = React.useCallback(
		() => performAsyncAction(dispatch, new LocationsApi().searchBy(vendor.id), (res: Location[]) => setLocations(res)),
		[dispatch, vendor]
	);

	const isDuplicateLocation = locations?.some(({ name }) => name.toLowerCase() === newLocation.trim().toLowerCase());

	const addLocation = () => {
		performAsyncAction(dispatch, new LocationsApi().create({ vendor: vendor.id, name: newLocation }), () => {
			dispatch(showSnackbar({ isOpen: true, label: Resources.LOCATIONS_FORM_ADD_SUCCESS, severity: 'success' }));
			setNewLocation('');
			fetchLocations();
			inputRef?.current?.focus();
		});
	};

	const removeLocation = (locationId: string) =>
		performAsyncAction(dispatch, new LocationsApi().delete(locationId), () => {
			fetchLocations();
			dispatch(showSnackbar({ isOpen: true, label: Resources.LOCATIONS_FORM_REMOVE_SUCCESS, severity: 'success' }));
		});

	React.useEffect(() => {
		fetchLocations();
	}, [fetchLocations]);

	return (
		<Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
			<Container className={classes.root}>
				<RLabel value={`${vendor.type ?? ''} Locations`} type={RLabelTypes.BOLD_18} />
				<Spacer />

				<List
					items={locations}
					renderItem={location => (
						<Grid container spacing={1} key={location.id}>
							<Grid item xs={10}>
								{location.name}
							</Grid>
							<Grid item xs={2}>
								<Tooltip title={`Remove ${location.name}`}>
									<IconButton
										disabled={isLoading}
										aria-label={`Remove ${location.name}`}
										color="primary"
										onClick={() => removeLocation(location.id)}
									>
										<ClearIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					)}
					emptyState={
						<Grid container spacing={1}>
							<Grid item xs={6}>
								No Locations available for this vendor
							</Grid>
						</Grid>
					}
				/>
				<Grid container spacing={1}>
					<Grid item xs={10}>
						<RSmallTextField
							ref={inputRef}
							dense
							placeholder="Add location"
							onChange={setNewLocation}
							value={newLocation}
							width={200}
							onKeyPress={e => {
								if (e.key === 'Enter' && !isLoading && !isDuplicateLocation) addLocation();
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<Tooltip title="Add location">
							<IconButton
								disabled={!newLocation.trim() || isDuplicateLocation || isLoading}
								color="primary"
								onClick={() => addLocation()}
							>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
				{isDuplicateLocation && <RLabel color="error" value={`${newLocation} already exists`} type={RLabelTypes.REGULAR_16} />}
				<Spacer />
			</Container>
		</Dialog>
	);
};

export default EditLocationsDialog;

const Container = styled.div`
	.small-field-textfield-container .small-text-field {
		margin-left: 0;
	}
`;
