import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import ActivityLogPage from 'features/ActivityLogPage';

export class ActivityLogPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.ACTIVITY_LOG;
	}

	getParams(): string[] {
		return [];
	}

	getComponent(): React.FC<any> {
		return ActivityLogPage;
	}

	isAdminRoute(): boolean {
		return true;
	}
}
