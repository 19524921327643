import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles(theme => ({}));

export enum RLabelTypes {
	REGULAR_16 = 'subtitle2',
	BOLD_16 = 'subtitle1',
	REGULAR_18 = 'h6',
	BOLD_18 = 'h5',
	REGULAR_25 = 'h4',
	BOLD_25 = 'h3',
	REGULAR_41 = 'h2',
	BOLD_41 = 'h1',
}

export interface IRLabelProps {
	value: string | React.ReactNode;
	type?: RLabelTypes;
	includeGutterBottom?: boolean;
	color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
}

const RLabel: React.FC<IRLabelProps> = props => {
	return (
		<Typography color={props.color} variant={props.type || RLabelTypes.REGULAR_16} gutterBottom={props.includeGutterBottom}>
			{props.value}
		</Typography>
	);
};

export default RLabel;
