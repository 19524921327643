export interface IVendor {
	id: string;
	configuration: VendorConfiguration;
	category: VendorCategory;
	type: VendorType;
	thumbnail_url: string;
	menu_file: { url: string; name: string };
}

export type VendorConfiguration = {
	description: string;
	display_name: string;
	is_app_display_slot_type: boolean;
	is_guest_feature_available: boolean;
	booking_policy: VendorBookingPolicy;
	//can cancel booking (order/slot) until {number} minutes before order/slot time
	cancelation_minutes_before_schdeule: number;
	// List of available time slots for orders (format: HH:mm)
	order_schedule_time_range: string[];
	//in a given time range, {number} of orders can be scheduled. For example 10 orders every 15 minutes
	maximum_orders_in_time_range: number;
	order_schedule_start_hour?: number;
	order_schedule_end_hour?: number;
	is_location_feature_available: boolean;
	/**
	 * Indicates whether the vendor supports the waiting list feature
	 */
	is_waiting_list_feature_available: boolean;
	/**
	 * Specifies the grace duration in minutes for waiting list (only if the feature is supported for the vendor)
	 */
	waiting_list_grace_minutes: number;
};

export enum VendorBookingPolicy {
	/**
	 * Can book one slot a day, e.g. Barber
	 */
	SINGLE_DAY = 1,
	/**
	 * Can book one slot a month, e.g. Spa
	 */
	SINGLE_MONTH = 2,
	/**
	 * Can book multiple times, e.g. Bowling, Climbing, Pilates, Gym, Yoga
	 */
	MULTI = 3,
	/**
	 * Can book every two weeks, e.g. Manicure, Pedicure
	 */
	BI_WEEKLY = 4,
}

export const vendorBookingPolicyLabels: Record<VendorBookingPolicy, string> = {
	[VendorBookingPolicy.SINGLE_DAY]: 'Once a Day',
	[VendorBookingPolicy.SINGLE_MONTH]: 'Once a Month',
	[VendorBookingPolicy.BI_WEEKLY]: 'Every two weeks',
	[VendorBookingPolicy.MULTI]: 'Multiple times',
};

export enum VendorCategory {
	FITNESS = 'Fitness',
	FOOD = 'Food',
	LIFESTYLE = 'Lifestyle',
}

export enum VendorType {
	//Fitness
	YOGA = 'Yoga',
	PILATES = 'Pilates',
	GYM = 'Gym',
	BASKETBALL = 'Basketball',

	//Lifestyle
	BARBER = 'Barber',
	SPA = 'Spa',
	BOWLING = 'Bowling',
	CLIMBING = 'Climbing',
	MANICURE = 'Manicure',

	//Food
	CAFE = 'Cafe',
	FRESH_BAR = 'FreshBar',
	RESTAURANT = 'Restaurant',
}
