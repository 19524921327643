export enum Role {
	NONE = 1,
	Admin = 2,
	Yoga = 3,
	Spa = 4,
	Pilates = 5,
	Gym = 6,
	Barber = 7,
	Manicure = 8,
	FoodAdmin = 12,
	Basketball = 13,
	Climbing = 14,
	Bowling = 15,
	Pedicure = 16,
	Concierge = 17,
	BusinessAdmin = 18,
	Outdoor = 19,
}

export const roleToString = (role: Role) => {
	switch (role) {
		case Role.Admin:
			return 'Admin';
		case Role.Yoga:
			return 'Yoga';
		case Role.Spa:
			return 'Spa';
		case Role.Pilates:
			return 'Pilates';
		case Role.Gym:
			return 'Gym';
		case Role.Barber:
			return 'Barber';
		case Role.Manicure:
			return 'Manicure';
		case Role.Basketball:
			return 'Basketball Admin';
		case Role.Climbing:
			return 'Climbing Admin';
		case Role.Bowling:
			return 'Bowling Admin';
		case Role.Pedicure:
			return 'Pedicure Admin';
		case Role.Concierge:
			return 'Concierge Admin';
		case Role.FoodAdmin:
			return 'Food Admin';
		case Role.BusinessAdmin:
			return 'Business Admin';
		case Role.Outdoor:
			return 'Outdoor Admin';
		default:
			return 'NONE';
	}
};
