import React from 'react';
import { ListGroup } from 'react-bootstrap';
import RDivider from 'components/Divider/RDivider';
import RScheduleSlotsDayRow, {
	RScheduleSlotsDayData,
	RScheduleSlotsDayRowProps,
} from 'components/ScheduleSlotsDaysTable/ScheduleSlotsDayRow/ScheduleSlotsDayRow';

export interface IRScheduleSlotsDaysTableProps {
	sessionDuration: number;
	rows: RScheduleSlotsDayRowProps[];
	onRowChange: (day: number, data: RScheduleSlotsDayData) => void;
	withLocation?: boolean;
}
const RScheduleSlotsDaysTable: React.FC<IRScheduleSlotsDaysTableProps> = ({
	sessionDuration,
	rows,
	onRowChange,
	withLocation,
}) => {
	return (
		<div>
			{rows.map((row, index) => (
				<ListGroup key={index.toString()}>
					<RScheduleSlotsDayRow
						sessionDuration={sessionDuration}
						disableCheckbox={rows.length === 1}
						key={index}
						onDataChange={data => onRowChange(row.day, data)}
						isActive={row.isActive}
						day={row.day}
						selectedProperties={row.selectedProperties}
						withLocation={withLocation}
					/>
					<RDivider color="#CBCBCB" />
				</ListGroup>
			))}
		</div>
	);
};

export default RScheduleSlotsDaysTable;
