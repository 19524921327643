import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Form, ListGroup } from 'react-bootstrap';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Resources } from 'enum/resources.enum';
import { Subscriber } from 'models/slot';
import RCheckbox from 'components/Checkbox/RCheckbox';
import { FloatRight, Spacer } from 'components/general';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { shallowEqual, useSelector } from 'store';
import { currentVendorSelector } from 'store/selectors/vendor.selectors';

type Props = {
	onSubmit?: (data: Partial<Subscriber>) => void;
};

const AddSubscriberForm = ({ onSubmit }: Props) => {
	const currentVendor = useSelector(currentVendorSelector, shallowEqual);

	const [subscriptionNotes, setSubscriptionNotes] = React.useState<string>('');
	const [withGuest, setWithGuest] = React.useState<boolean>(false);
	const [arrived, setArrived] = React.useState<boolean>(false);

	const isGuestFeatureAvailable = currentVendor?.vendor?.configuration?.is_guest_feature_available;

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={isGuestFeatureAvailable ? 6 : 12}>
					<RCheckbox isChecked={arrived} label="Arrived" onChange={isChecked => setArrived(isChecked)} />
				</Grid>
				{isGuestFeatureAvailable && (
					<Grid item xs={6}>
						<RCheckbox
							isChecked={withGuest}
							label="With guest"
							onChange={isChecked => setWithGuest(isGuestFeatureAvailable ? isChecked : false)}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<ListGroup>
						<RLabel value={Resources.SUBSCRIBER_FORM_CONTROL_REASON_TITLE} type={RLabelTypes.BOLD_18} />
						<RLabel value={Resources.SUBSCRIBER_FORM_CONTROL_ADD_REASON_SUBTITLE} />
						<Form.Control
							maxLength={125}
							onChange={e => setSubscriptionNotes(e.target.value)}
							as="textarea"
							value={subscriptionNotes}
							placeholder={Resources.SUBSCRIBER_FORM_CONTROL_REASON_PLACEHOLDER}
							rows={3}
						/>
					</ListGroup>
				</Grid>
			</Grid>
			<Spacer />

			<Grid item xs={12}>
				<FloatRight>
					<RButton
						label={Resources.ADD}
						theme={RButtonTheme.DARK}
						disabled={!subscriptionNotes.length}
						onClick={e => {
							onSubmit({ with_guest: withGuest, fulfilled: arrived, subscription_notes: subscriptionNotes });
						}}
					/>
				</FloatRight>
			</Grid>
		</>
	);
};

export default AddSubscriberForm;
