import React from 'react';
import ReactDOM from 'react-dom';
import 'index.scss';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { Provider } from 'react-redux';
import store from 'store/store';
import { ThemeProvider } from '@material-ui/core';
import theme from 'components/theme';
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

serviceWorker.unregister();
