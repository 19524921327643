import axios from 'axios';

export interface ApiSuccessResponse {
	status: 'OK';
}

export interface ApiErrorResponse {
	status: number;
	error_custom_code: number;
	message: string;
}

export default class NetworkManager {
	static isInitiate = false;

	static setNewToken(token: string | null) {
		axios.defaults.headers.common.Authorization = `Bearer ${token}`;
	}

	static baseUrl = process.env.REACT_APP_API_BASE_URL;

	static apiEndpoint = `api/v1`;

	static adminEndpoint = `admin`;

	static socketUrl = process.env.REACT_APP_API_SOCKET_URL;

	static init() {
		if (NetworkManager.isInitiate) return;

		NetworkManager.isInitiate = true;
		const token = localStorage.getItem('token');

		//set base url
		axios.defaults.baseURL = NetworkManager.baseUrl;
		//set header token
		if (token) NetworkManager.setNewToken(token);
		// axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		axios.interceptors.response.use(
			response => {
				return response;
			},
			error => {
				// This function is part of a promise chain.
				// It needs to return a value or another promise so the caller knows when it
				// has completed.

				// Pass all non 401s back to the caller.
				return Promise.reject(error);
			}
		);
	}
}
