import BaseModel from 'framework/base.model';
import BaseApi from 'framework/base.api';

/**
 * This layer is responsible to implement logic and mange data from local or remote storage
 */
/* eslint-disable */
export default abstract class BaseRepository<M extends BaseModel<any, any>, Api extends BaseApi> {
	protected model: M; // Interact with local data
	protected api: Api; // Interact with remote data

	protected abstract getModel(): M;
	protected abstract getApi(): Api;

	constructor() {
		this.model = this.getModel();
		this.api = this.getApi();
	}

	public getById(id: string) {
		return this.api.readById(id);
	}

	public getAll() {
		return this.api.read();
	}

	public insert(data: any) {
		return this.api.create(data);
	}

	public delete(id: string) {
		return this.api.delete(id);
	}
}
