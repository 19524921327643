import { makeStyles, Dialog, TextField, RadioGroup } from '@material-ui/core';
import React from 'react';
import { DivContainer } from 'App';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { FloatRight, Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import RListGroup from 'components/ListGroup/RListGroup';
import { Resources } from 'enum/resources.enum';
import { IProductChoiceSet } from 'models/product';

export const useStyles = makeStyles(theme => ({
	root: {
		padding: 30,
	},
}));

export interface RProductOptionSetDialogProps {
	set?: IProductChoiceSet;
	open: boolean;
	onClose: () => void;
}

const RProductOptionSetDialog: React.FC<RProductOptionSetDialogProps> = ({ set, open, onClose }) => {
	const classes = useStyles();

	return (
		<Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
			<DivContainer className={classes.root}>
				<RLabel value={Resources.PRODUCT_SCREEN_OPTIONS_LABEL} type={RLabelTypes.BOLD_25} />

				<Spacer />

				{Boolean(set?.set.length) && (
					<RadioGroup name="defaultChoice" value={set.default_choice}>
						<RListGroup horizontal={true}>
							<RLabel value={Resources.PRODUCT_SCREEN_OPTIONS_SET_DIALOG_NAME_LABEL} type={RLabelTypes.BOLD_18} />
							<TextField disabled inputProps={{ maxLength: 10 }} size="small" value={set?.name ?? ''} variant="outlined" />
						</RListGroup>
						<Spacer />

						{set?.set.map((choice, index) => {
							return (
								<RListGroup key={choice._id} horizontal={true}>
									<RLabel
										value={`${Resources.PRODUCT_SCREEN_OPTIONS_SET_DIALOG_CHOICE_LABEL} ${index + 1}`}
										type={RLabelTypes.BOLD_18}
									/>
									<TextField disabled inputProps={{ maxLength: 10 }} size="small" value={choice.name} variant="outlined" />
								</RListGroup>
							);
						})}
					</RadioGroup>
				)}

				<DivContainer />
				<Spacer />
				<FloatRight>
					<RButton
						onClick={onClose}
						label={Resources.PRODUCT_SCREEN_OPTIONS_SET_DIALOG_BUTTON_CANCEL}
						theme={RButtonTheme.LIGHT}
					/>
				</FloatRight>
			</DivContainer>
		</Dialog>
	);
};

export default RProductOptionSetDialog;
