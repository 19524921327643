import React from 'react';
import RContainer from 'components/Container/RContainer';
import { Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Resources } from 'enum/resources.enum';
import Table from 'components/Table';
import { IconButton, TableCell, Tooltip } from '@material-ui/core';
import { IOrder, OrderStage, SearchableOrder } from 'models/order';
import RCheckbox from 'components/Checkbox/RCheckbox';
import { AdminApi } from 'framework/API/admin.api';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { useDispatch } from 'store/store';
import RSmallTextField from 'components/SmallTextField/RSmallTextField';
import useDebounce from 'hooks/useDebounce';
import styled from 'styled-components';
import { io } from 'socket.io-client';
import NetworkManager from 'framework/NetworkManager';
import { SocketEvent } from 'enum/socket-events';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { showSnackbar } from 'store/slices/app.slice';
import { resizeImage } from 'utils/files';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const headers = [
	{ key: 'order_number', label: Resources.ORDER_MANAGEMENT_SCREEN_HEADER_CELL_1, sortable: true },
	{ key: 'name', label: Resources.ORDER_MANAGEMENT_SCREEN_HEADER_CELL_2, sortable: true },
	{ key: 'dish', label: Resources.ORDER_MANAGEMENT_SCREEN_HEADER_CELL_3, sortable: true },
	{ key: 'date', label: Resources.ORDER_MANAGEMENT_SCREEN_HEADER_CELL_4, sortable: true },
	{ key: 'action', label: Resources.ORDER_MANAGEMENT_SCREEN_HEADER_CELL_5, sortable: false },
];

const OrderManagementPage = () => {
	const dispatch = useDispatch();
	const [search, setSearch] = React.useState<string>('');
	const [orderBy, setOrderBy] = React.useState<keyof SearchableOrder>('order_number');
	const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
	const q = useDebounce(search);
	const [orders, setOrders] = React.useState<SearchableOrder[]>([]);
	const inputRef = React.useRef<HTMLInputElement | null>(null);
	const labelRef = React.useRef<HTMLLabelElement | null>(null);

	const fetchOrders = React.useCallback(
		() =>
			performAsyncAction(
				dispatch,
				new AdminApi().searchOrders({ q, orderBy, direction: direction === 'asc' ? 1 : -1 }),
				(res: SearchableOrder[]) => setOrders(res)
			),
		[direction, dispatch, orderBy, q]
	);

	const updateOrderStage = (id: string, stage: OrderStage = OrderStage.ReadyForPickup) =>
		performAsyncAction(dispatch, new AdminApi().updateOrderStage(id, stage), () => {
			fetchOrders();
		});

	React.useEffect(() => {
		fetchOrders();
	}, [fetchOrders]);

	const onRequestSort = (_event: React.MouseEvent<unknown>, property: keyof SearchableOrder) => {
		setDirection(prev => (property !== orderBy || prev === 'desc' ? 'asc' : 'desc'));
		setOrderBy(property);
	};

	const bulkMarkAsReady = () =>
		performAsyncAction(
			dispatch,
			new AdminApi().bulkUpdateOrderStage({
				ids: orders?.filter(order => order.stage === OrderStage.Received).map(({ id }) => id),
				stage: OrderStage.ReadyForPickup,
			}),
			() => {
				fetchOrders();
			}
		);

	React.useEffect(() => {
		const socket = io(NetworkManager.socketUrl);
		socket.on(SocketEvent.REFRESH_ORDERS, () => {
			fetchOrders();
		});

		return () => {
			socket.disconnect();
		};
	}, [fetchOrders]);

	const onScan = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		try {
			if (!file) return;
			if (!file.type.startsWith('image')) throw new Error('Invalid file, please provide a valid image');
			const resizedImage = await resizeImage(file);
			console.log({ file, resized: resizedImage });
			const formData = new FormData();
			formData.append('image', resizedImage);
			performAsyncAction(dispatch, new AdminApi().updateOrderStageByImage(formData), (order: IOrder) => {
				fetchOrders();
				dispatch(
					showSnackbar({
						isOpen: true,
						label: `Successfully updated ${order.profile.name}s' order(#${order.order_number}) to "Ready for pickup"`,
					})
				);
			});
		} catch (error) {
			dispatch(showSnackbar({ isOpen: true, label: (error as Error).message, severity: 'error' }));
		} finally {
			if (inputRef.current) inputRef.current.value = null;
		}
	};

	const isUpdateAllDisabled = React.useMemo(
		() => orders?.every(order => order.stage === OrderStage.ReadyForPickup) ?? true,
		[orders]
	);

	return (
		<RContainer>
			<Spacer />
			<RLabel value={Resources.ORDER_MANAGEMENT_SCREEN_TITLE} type={RLabelTypes.BOLD_25} />
			<Spacer />
			<SearchContainer>
				<RSmallTextField
					dense
					onChange={value => setSearch(value)}
					value={search}
					placeholder="Search by order number, name, dish, or time"
					width={200}
				/>
				<RButton
					removeMargin
					theme={RButtonTheme.DARK}
					label={
						<>
							<InputLabel ref={labelRef} onClick={e => e.stopPropagation()}>
								{Resources.ORDER_MANAGEMENT_SCREEN_ACTION}
								<ImageInput ref={inputRef} onChange={onScan} />
							</InputLabel>
						</>
					}
					onClick={e => {
						e.stopPropagation();
						labelRef.current?.click?.();
					}}
				/>
			</SearchContainer>
			<Spacer />
			<Table.Container>
				<Table.Head>
					<Table.HeadRow>
						{headers.map(header =>
							header.sortable ? (
								<Table.SortableCell
									key={header.key}
									orderBy={orderBy}
									order={direction}
									itemKey={header.key}
									style={{ width: '20%' }}
									onRequestSort={onRequestSort}
								>
									<RLabel type={RLabelTypes.BOLD_16} value={header.label} />
								</Table.SortableCell>
							) : (
								<Table.Cell key={header.label} style={{ width: '20%' }}>
									<ActionCell>
										<RLabel type={RLabelTypes.BOLD_16} value={header.label} />
										{header.key === 'action' && (
											<Tooltip title="Mark all as Ready">
												<IconButton disabled={isUpdateAllDisabled} onClick={bulkMarkAsReady}>
													<DoneAllIcon />
												</IconButton>
											</Tooltip>
										)}
									</ActionCell>
								</Table.Cell>
							)
						)}
					</Table.HeadRow>
				</Table.Head>
				<Table.Body>
					{orders.map(order => (
						<Table.Row key={order.id}>
							<TableCell>{order.order_number}</TableCell>
							<TableCell>{order.name}</TableCell>
							<TableCell>{order.dish}</TableCell>
							<TableCell>{order.date}</TableCell>
							<TableCell>
								<RCheckbox
									label="Ready"
									labelType={RLabelTypes.REGULAR_16}
									isChecked={[OrderStage.ReadyForPickup, OrderStage.Delivered, OrderStage.Undelivered].includes(order.stage)}
									onChange={() =>
										updateOrderStage(
											order.id,
											order.stage === OrderStage.ReadyForPickup ? OrderStage.Received : OrderStage.ReadyForPickup
										)
									}
								/>
							</TableCell>
						</Table.Row>
					))}
				</Table.Body>
			</Table.Container>
		</RContainer>
	);
};

export default OrderManagementPage;

const SearchContainer = styled.div.attrs({ className: 'search-container' })`
	display: flex;
	align-items: center;
	justify-content: space-between;
	.small-text-field {
		width: 100% !important;
		align-items: flex-start;
		margin: 0;

		.small-field-textfield-container {
			> .list-group-horizontal {
				align-items: flex-start !important;
			}
			width: 100% !important;
		}
		input {
			width: 400px !important;
		}
	}
`;

const InputLabel = styled.label.attrs({ htmlFor: 'order-ticket-image' })`
	cursor: pointer;
	height: 100%;
	display: block;
`;

const ImageInput = styled.input.attrs({
	type: 'file',
	id: 'order-ticket-image',
	name: 'file',
	capture: 'environment',
	accept: 'image/*',
})`
	visibility: hidden;
	position: absolute;
`;

const ActionCell = styled.div.attrs({ className: 'actionCell' })`
	display: flex;
	align-items: center;
`;
