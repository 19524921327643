import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'store';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import RServiceCard from 'features/MyServicesPage/ServiceCard/RServiceCard';
import RCreateServiceCard from 'features/MyServicesPage/ServiceCard/RCreateServiceCard';
import RMyServicesHeader from 'features/MyServicesPage/RMyServicesHeader';
import { Spacer } from 'components/general';
import vendorActions from 'features/Vendor/vendor.actions';
import { BaseRoute } from 'framework/Routes/Base.route';
import { NewServicePageRoute } from 'framework/Routes/NewServicePage.route';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Resources } from 'enum/resources.enum';
import RContainer from 'components/Container/RContainer';
import RListGroup from 'components/ListGroup/RListGroup';
import REmptyServicesView from 'features/MyServicesPage/REmptyServicesView';
import { currentVendorSelector } from 'store/selectors/vendor.selectors';
import { isConnectedSelector } from 'store/selectors/app.selector';

export type IVendorParams = Record<'vendorId', string>;

const MyServicesPage: React.FC = () => {
	const currentVendor = useSelector(currentVendorSelector, shallowEqual);
	const isConnected = useSelector(isConnectedSelector);
	const dispatch = useDispatch();
	const { vendorId } = useParams<IVendorParams>();

	useEffect(() => {
		if (isConnected) {
			dispatch(vendorActions.getMyServicesAction(vendorId));
		}
	}, [isConnected, dispatch, vendorId]);

	const handleCreateService = () => {
		BaseRoute.navigateTo(new NewServicePageRoute(), [vendorId]);
	};

	return (
		<RContainer>
			<Spacer />
			{Boolean(currentVendor.vendor) && (
				<RMyServicesHeader
					title={currentVendor.vendor.configuration.display_name}
					description={currentVendor.vendor.configuration.description}
					bookingPolicy={currentVendor.vendor.configuration.booking_policy}
				/>
			)}
			<Spacer />
			<RLabel value={Resources.MY_SERVICE_SCREEN_TITLE} type={RLabelTypes.BOLD_25} />
			<RListGroup horizontal={true}>
				<RLabel value={Resources.MY_SERVICE_SCREEN_SUBTITLE} type={RLabelTypes.REGULAR_16} />
				<RLabel value={`${currentVendor?.vendor?.configuration.display_name || ''} ${'vendor'}`} type={RLabelTypes.BOLD_16} />
			</RListGroup>
			<Spacer />
			{currentVendor.slots_types.length > 0 ? (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<RCreateServiceCard onClick={handleCreateService} />
					</Grid>
					{currentVendor.slots_types.map((item, index) => {
						return (
							<Grid key={index} item xs={12} sm={6}>
								<RServiceCard
									index={index}
									header={item.name}
									imageUrl={item.operator?.picture}
									imageLabel={item.operator?.name}
									description={item.description}
									key={index}
								/>
							</Grid>
						);
					})}
				</Grid>
			) : (
				<REmptyServicesView
					onClick={handleCreateService}
					description={Resources.MY_SERVICE_SCREEN_EMPTY_STATE_DESCRIPTION}
					actionDescription={`+ ${Resources.SERVICE_CARD_CREATE_BUTTON}`}
				/>
			)}
		</RContainer>
	);
};

export default MyServicesPage;
