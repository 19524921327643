import { ROUTES } from 'constants/routes';
import history from 'services/history';

export abstract class BaseRoute {
	protected path: ROUTES;

	protected connected: boolean;

	protected exact: boolean;

	abstract getPath(): ROUTES;
	abstract getComponent(): React.FC<any>;
	abstract getParams(): string[];

	constructor() {
		this.path = this.getPath();
		this.connected = this.isConnected();
		this.exact = this.getIsExact();
	}

	isConnected(): boolean {
		return true;
	}

	isAdminRoute(): boolean {
		return false;
	}

	getIsExact(): boolean {
		return true;
	}

	getQueryParams(): string[] {
		return [];
	}

	getParsedPath(values: string[]): string {
		let parsedPath = this.getPath().toString();
		const params = this.getParams();
		const queryParams = this.getQueryParams();
		params.forEach((param, index) => {
			parsedPath = parsedPath.replace(`:${param}`, values[index]);
		});
		queryParams.forEach((param, index) => {
			const initial = index === 0 ? '?' : index !== queryParams.length - 1 ? '&' : '';
			parsedPath += `${initial}${param}`;
		});
		return parsedPath;
	}

	static navigateTo(route: BaseRoute, params: string[] = []) {
		history.push(route.getParsedPath(params));
	}

	static navigateHome() {
		history.push('/');
	}
}
