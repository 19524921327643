import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import RContainer from 'components/Container/RContainer';
import { useDispatch, useSelector, shallowEqual } from 'store';
import vendorActions from 'features/Vendor/vendor.actions';
import RCard from 'components/Card/RFade';
import RImageAndLabel from 'components/ImageAndLabel/RImageAndLabel';
import { RLabelTypes } from 'components/Label/RLabel';
import { HoverItem, Spacer } from 'components/general';
import { BaseRoute } from 'framework/Routes/Base.route';
import { resetCurrentVendor } from 'store/slices/vendor.slice';
import { VendorCategory, VendorType } from 'models/vendor';
import { MenusPageRoute } from 'framework/Routes/MenusPage.route';
import { ListGroup } from 'react-bootstrap';
import { vendorsByCategorySelector } from 'store/selectors/vendor.selectors';
import { OrderManagementPageRoute } from 'framework/Routes/OrdersManagementPage.route';

type Feature = {
	thumbnail_url?: string;
	label: string;
	onClick: () => void;
};

const FoodDashboardPage: React.FC = () => {
	const vendorsByCategory = useSelector(vendorsByCategorySelector, shallowEqual);
	const dispatch = useDispatch();

	useEffect(() => {
		if (vendorsByCategory.length === 0) {
			dispatch(vendorActions.getAllVendorsAction());
		}
		dispatch(resetCurrentVendor());
	}, [dispatch, vendorsByCategory]);

	const handleVendorClick = (vendorId: string) => {
		BaseRoute.navigateTo(new MenusPageRoute(), [vendorId]);
	};

	const cafe = vendorsByCategory
		.find(({ category }) => category === VendorCategory.FOOD)
		?.data.find(({ type }) => type === VendorType.CAFE);
	const freshBar = vendorsByCategory
		.find(({ category }) => category === VendorCategory.FOOD)
		?.data.find(({ type }) => type === VendorType.FRESH_BAR);
	const restaurant = vendorsByCategory
		.find(({ category }) => category === VendorCategory.FOOD)
		?.data.find(({ type }) => type === VendorType.RESTAURANT);

	const cafeFeature: Feature | null = cafe
		? {
				thumbnail_url: cafe.thumbnail_url,
				label: cafe.configuration.display_name || '',
				onClick: () => {
					handleVendorClick(cafe.id || '');
				},
		  }
		: null;
	const freshBarFeature: Feature | null = cafe
		? {
				thumbnail_url: freshBar.thumbnail_url,
				label: freshBar.configuration.display_name || '',
				onClick: () => {
					handleVendorClick(freshBar?.id || '');
				},
		  }
		: null;
	const restaurantFeature: Feature | null = restaurant
		? {
				thumbnail_url: restaurant.thumbnail_url,
				label: restaurant.configuration.display_name || '',
				onClick: () => {
					handleVendorClick(restaurant?.id || '');
				},
		  }
		: null;

	const orderManagementFeature: Feature = {
		label: 'Order Management',
		onClick: () => {
			BaseRoute.navigateTo(new OrderManagementPageRoute());
		},
	};

	const firstColumn = [restaurantFeature, orderManagementFeature].filter(Boolean);
	const secondColumn = [cafeFeature].filter(Boolean);
	const thirdColumn = [freshBarFeature].filter(Boolean);
	const features = [firstColumn, secondColumn, thirdColumn];
	return (
		<RContainer>
			<Spacer />
			<Grid container spacing={3}>
				{features.map((list, index) => {
					return (
						<Grid key={index} item xs={4} sm={4}>
							<ListGroup>
								{list.map((feature, i) => {
									return (
										<HoverItem key={i}>
											<RCard onClick={feature.onClick} height={90}>
												<RImageAndLabel label={feature.label} imageUrl={feature.thumbnail_url} type={RLabelTypes.BOLD_16} />
											</RCard>
											<Spacer />
										</HoverItem>
									);
								})}
							</ListGroup>
						</Grid>
					);
				})}
			</Grid>
		</RContainer>
	);
};

export default FoodDashboardPage;
