import { LoginModel } from 'features/Login/Login.model';
import BaseRepository from 'framework/base.repository';
import { LoginApi } from 'framework/API/Login.api';
import { AuthProvider } from 'framework/AuthProvider';
import { GetLoginServerResponse } from 'features/Login/Login.types';
import NetworkManager from 'framework/NetworkManager';

class LoginRepository extends BaseRepository<LoginModel, LoginApi> {
	protected getModel() {
		return new LoginModel();
	}

	protected getApi() {
		return new LoginApi();
	}

	async setFBToken(token: string) {
		this.model.insert('fb_token', token);
	}

	async signIn() {
		const fbToken = this.model.readBy('fb_token');
		const res: GetLoginServerResponse = (await this.api.signIn(fbToken)).data;
		if (res.access_token) {
			this.model.insert('token', res.access_token);
			NetworkManager.setNewToken(res.access_token);
		}
		return res;
	}

	async getRelatedVendor() {
		return this.api.getRelatedVendor();
	}

	getAPIToken() {
		return this.model.readBy('token');
	}

	async logout() {
		this.model.clear();
		AuthProvider.logout();
	}
}

export const LoginRepo = new LoginRepository();
