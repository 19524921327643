import React from 'react';
import MuiTable, { TableProps as MuiTableProps } from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { TableRow, HeaderTableRow } from 'components/Table/TableRow';
import { TableCell, SortableTableCell } from 'components/Table/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from 'components/Table/TablePagination';

type TableProps = React.PropsWithChildren<{}> & MuiTableProps;

const Table = ({ children, ...props }: TableProps) => {
	return (
		<TableContainer component={Paper}>
			<MuiTable style={{ minWidth: 700 }} aria-label="Table" {...props}>
				{children}
			</MuiTable>
		</TableContainer>
	);
};

export default {
	Container: Table,
	Head: TableHead,
	HeadRow: HeaderTableRow,
	Row: TableRow,
	Cell: TableCell,
	SortableCell: SortableTableCell,
	Body: TableBody,
	Pagination: TablePagination,
};
