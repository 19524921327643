import axios from 'axios';
import BaseApi from 'framework/base.api';
import NetworkManager from 'framework/NetworkManager';

export class SlotsTypesApi extends BaseApi {
	constructor() {
		super();
		this.route = this.getRoute();
	}

	getRoute(): string {
		return `${NetworkManager.apiEndpoint}/slots-types`;
	}

	getSlotsSchedulerTemplate(slotTypeId: string) {
		return axios.get(`${this.route}/${slotTypeId}/slots-scheduler-templates`);
	}
}
