import { makeStyles, Card } from '@material-ui/core';
import React from 'react';

export interface RCardProps {
	height?: number;
	padding?: number;
	onClick?: () => void;
}

const RCard: React.FC<RCardProps> = props => {
	const classes = makeStyles(theme => ({
		root: {
			minHeight: `${props.height || 250}px`,
			borderColor: theme.palette.primary.light,
			borderRadius: 6,
			boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.13)',
			'& .MuiCardContent-root:last-child': {
				paddingBottom: 16,
			},
		},
	}))();

	return (
		<Card onClick={props.onClick} style={{ padding: `${props.padding || 30}px` }} className={classes.root} variant="outlined">
			{props.children}
		</Card>
	);
};

export default RCard;
