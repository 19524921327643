import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISlotType } from 'models/slotType';
import { IVendor, VendorCategory } from 'models/vendor';
import { ISlotsScheduleTemplate } from 'features/ScheduleSlotsPage/ScheduleSlotsPage.types';

export type GetVendorResponse = {
	vendor: IVendor | null;
	slots_types: ISlotType[];
};

export type GetVendorsResponse = VendorsGroupedByCategory[];

export type VendorsGroupedByCategory = {
	category: VendorCategory;
	data: IVendor[];
	index: number;
};

export interface IVendorState {
	current_vendor: {
		vendor: IVendor | null;
		slots_types: ISlotType[];
		current_slots_schedule_template: ISlotsScheduleTemplate | null;
	};
	vendorsByCategory: GetVendorsResponse;
}

const defaultState: IVendorState = {
	current_vendor: {
		vendor: null,
		slots_types: [],
		current_slots_schedule_template: null,
	},
	vendorsByCategory: [],
};

export const vendorSlice = createSlice({
	name: 'vendor',
	initialState: defaultState,
	reducers: {
		disconnect: (state: IVendorState) => {
			state.current_vendor = {
				vendor: null,
				slots_types: [],
				current_slots_schedule_template: null,
			};
			state.vendorsByCategory = [];
		},
		setVendors: (state: IVendorState, action: PayloadAction<GetVendorsResponse>) => {
			state.vendorsByCategory = action.payload;
		},
		setCurrentVendor: (state: IVendorState, action: PayloadAction<GetVendorResponse>) => {
			state.current_vendor = {
				...state.current_vendor,
				vendor: action.payload.vendor,
				slots_types: action.payload.slots_types,
			};
		},
		resetCurrentVendor: (state: IVendorState) => {
			state.current_vendor = {
				...defaultState.current_vendor,
			};
		},
		updateSlotsTypes: (state: IVendorState, action: PayloadAction<ISlotType>) => {
			const index = state.current_vendor.slots_types.findIndex(slot => slot.id === action.payload.id); //finding index of the item
			const newArray = [...state.current_vendor.slots_types]; //making a new array
			if (index < 0 || index === undefined) {
				newArray.push(action.payload);
			} else {
				newArray[index] = action.payload;
			}
			state.current_vendor.slots_types = newArray;
		},
		removeSlotType: (state: IVendorState, action: PayloadAction<ISlotType>) => {
			const newArray = state.current_vendor.slots_types.filter(slot => slot.id !== action.payload.id);
			state.current_vendor.slots_types = newArray;
		},
		setCurrentSlotsScheduler: (state: IVendorState, action: PayloadAction<ISlotsScheduleTemplate>) => {
			state.current_vendor.current_slots_schedule_template = action.payload;
		},
	},
});

export const {
	disconnect,
	setVendors,
	setCurrentVendor,
	resetCurrentVendor,
	updateSlotsTypes,
	removeSlotType,
	setCurrentSlotsScheduler,
} = vendorSlice.actions;

export default vendorSlice.reducer;
