import { ROUTES } from 'constants/routes';
import ProductPage from 'features/ProductPage/ProductPage';
import { BaseRoute } from 'framework/Routes/Base.route';

export class ProductPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.PRODUCT_PAGE;
	}

	getParams(): string[] {
		return ['vendorId', 'menuId', 'categoryId', 'productId'];
	}

	getComponent(): React.FC<any> {
		return ProductPage;
	}
}
