import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTableRow from '@material-ui/core/TableRow';

export const TableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: 'white',
		},
	})
)(MuiTableRow);

export const HeaderTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
		},
	})
)(MuiTableRow);
