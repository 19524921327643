import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import CategoriesPage from 'features/CategoriesPage/CategoriesPage';

export class CategoriesPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.CATEGORIES;
	}

	getParams(): string[] {
		return ['vendorId', 'menuId'];
	}

	getComponent(): React.FC<any> {
		return CategoriesPage;
	}
}
