import axios from 'axios';
import BaseApi from 'framework/base.api';
import NetworkManager from 'framework/NetworkManager';

export class LocationsApi extends BaseApi {
	constructor() {
		super();
		this.route = this.getRoute();
	}

	getRoute(): string {
		return `${NetworkManager.apiEndpoint}/locations`;
	}

	searchBy(vendor: string, name?: string) {
		return axios.post(`${this.route}/search`, { vendor, name: name || undefined });
	}
}
