import { makeStyles } from '@material-ui/core';
import React from 'react';
import { RServiceCardMoreOption } from 'features/MyServicesPage/ServiceCard/RServiceCard';
import Menu from 'components/Menu/Menu';
import RButton, { RButtonProps } from 'components/Button/RButton';

const useStyles = makeStyles(theme => ({
	button: {
		color: theme.palette.primary.main,
		fontSize: '16px',
		fontWeight: 500,
		fontFamily: 'Montserrat-Regular',
	},
}));

export interface MenuButtonProps extends RButtonProps {
	options: RServiceCardMoreOption[];
	disabled?: boolean;
}

const MenuButton = ({ options, disabled, ...buttonProps }: MenuButtonProps) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = (option: RServiceCardMoreOption) => {
		setAnchorEl(null);
		option.onSelect();
	};

	return (
		<div>
			<RButton
				className={classes.button}
				aria-controls="simple-menu"
				aria-haspopup="true"
				{...buttonProps}
				onClick={handleClick}
			/>
			<Menu id="simple-menu" anchorEl={anchorEl} handleClose={handleClose} handleSelect={handleSelect} options={options} />
		</div>
	);
};

export default MenuButton;
