import styled from 'styled-components';

export const Spacer = styled.div`
	margin-bottom: 1.5em;
	display: flex;
`;
export const SmallSpacer = styled.div.attrs({ className: 'small-spacer' })`
	margin-bottom: 0.5em;
	display: flex;
`;

export const RightSpacer = styled.div`
	margin-right: 1em;
	display: flex;
`;

export const CenterAlignItems = styled.div`
	align-items: center;
`;

export const DoubleSpacer = styled.div`
	margin-bottom: 4em;
	display: flex;
`;

export const FloatRight = styled.div`
	float: right;
`;

export const HoverItem = styled.div`
	&:hover {
		cursor: pointer;
	}
`;

export const FlexSpaceBetween = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
