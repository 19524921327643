import { Fade } from '@material-ui/core';
import React from 'react';

export interface RFadeProps {
	in?: boolean;
	timeout?: number;
	wrapperClassName?: string;
}
const RFade: React.FC<RFadeProps> = ({ in: fadeIn, timeout, children, wrapperClassName = 'fade-wrapper' }) => {
	return (
		<Fade in={fadeIn || true} timeout={timeout || 1000}>
			<div className={wrapperClassName}>{children}</div>
		</Fade>
	);
};

export default RFade;
