import { makeStyles, Grid, Dialog, DialogContent } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import { Resources } from 'enum/resources.enum';
import { ISlot } from 'models/slot';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { FloatRight, Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';

export const useStyles = makeStyles(theme => ({
	root: {},
}));

export interface IRNotifySubscribersMessageDialogProps {
	open: boolean;
	onClose: () => void;
	onPublish: (message: string, slot: ISlot) => void;
	slot?: ISlot;
}

const RNotifySubscribersMessageDialog: React.FC<IRNotifySubscribersMessageDialogProps> = props => {
	const classes = useStyles();
	const [notificationData, setNotificationData] = useState('');

	useEffect(() => {
		setNotificationData('');
	}, [props.slot]);

	return (
		<Dialog className={classes.root} fullWidth maxWidth={'sm'} onClose={props.onClose} open={props.open}>
			<DialogContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<ListGroup>
							<RLabel value={Resources.NOTIFY_SUBSCRIBERS_DIALOG_MESSAGE_TITLE} type={RLabelTypes.BOLD_18} />
							<RLabel value={Resources.NOTIFY_SUBSCRIBERS_DIALOG_MESSAGE_SUBTITLE} />
							<Spacer />
							<Form.Control
								maxLength={125}
								onChange={e => {
									setNotificationData(e.target.value);
								}}
								as="textarea"
								placeholder={Resources.NOTIFY_SUBSCRIBERS_DIALOG_MESSAGE_PLACEHOLDER}
								rows={3}
							/>
						</ListGroup>
					</Grid>

					<Grid item xs={12}>
						<FloatRight>
							<RButton
								label={Resources.NOTIFY_SUBSCRIBERS_DIALOG_MESSAGE_BUTTON_CANCEL}
								theme={RButtonTheme.LIGHT}
								onClick={e => {
									props.onClose();
								}}
							/>
							<RButton
								label={Resources.NOTIFY_SUBSCRIBERS_DIALOG_MESSAGE_BUTTON_CONFIRM}
								theme={RButtonTheme.DARK}
								disabled={notificationData.length === 0}
								onClick={e => {
									if (props.slot) {
										props.onPublish(notificationData, props.slot);
										props.onClose();
									}
								}}
							/>
						</FloatRight>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default RNotifySubscribersMessageDialog;
