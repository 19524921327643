import BaseApi from 'framework/base.api';
import NetworkManager from 'framework/NetworkManager';

export class VendorsApi extends BaseApi {
	constructor() {
		super();
		this.route = this.getRoute();
	}

	getRoute(): string {
		return `${NetworkManager.apiEndpoint}/vendors`;
	}
}
