import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import { RightSpacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { ArrowRightIcon } from 'components/Icons/Icons';
import RListGroup from 'components/ListGroup/RListGroup';

type LinkProp = {
	href?: string;
	label: string;
};
export interface RBreadcrumbsProps {
	links: LinkProp[];
}

const RBreadcrumbs: React.FC<RBreadcrumbsProps> = props => {
	const location = useLocation();
	return (
		<div>
			<RListGroup horizontal={true}>
				{props.links.map((link, index) => {
					return (
						<Link key={link.label} to={link.href ?? location.pathname}>
							<RListGroup horizontal={true}>
								<RLabel value={link.label} type={RLabelTypes.REGULAR_16} color="primary" />
								<RightSpacer />
								{index === props.links.length - 1 ? '' : ArrowRightIcon}
							</RListGroup>
						</Link>
					);
				})}
			</RListGroup>
		</div>
	);
};

export default RBreadcrumbs;
