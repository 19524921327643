import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import FoodDashboardPage from 'features/FoodDashboardPage/FoodDashboardPage';

export class FoodDashboardPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.FOOD_DASHBOARD;
	}

	getParams(): string[] {
		return [];
	}

	getComponent(): React.FC<any> {
		return FoodDashboardPage;
	}
}
