import { makeStyles, FormControlLabel } from '@material-ui/core';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { useEffect } from 'react';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { onLoadingSelector } from 'store/selectors/app.selector';
import { useSelector } from 'store/store';

export const useStyles = makeStyles(theme => ({
	root: {},
}));

export interface IRCheckbox {
	isChecked: boolean;
	onChange?: (isChecked: boolean) => void;
	label: string;
	labelType?: RLabelTypes;
	disabled?: boolean;
}
const RCheckbox: React.FC<IRCheckbox> = ({ isChecked, onChange, label, labelType = RLabelTypes.BOLD_16, disabled = false }) => {
	const classes = useStyles();
	const [checked, setChecked] = React.useState<boolean>(isChecked);
	const onLoading = useSelector(onLoadingSelector);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		onChange?.(event.target.checked);
	};

	useEffect(() => {
		setChecked(isChecked);
	}, [isChecked]);

	return (
		<FormControlLabel
			className={classes.root}
			control={
				<Checkbox
					color="primary"
					disabled={onLoading || disabled}
					className={classes.root}
					checked={checked}
					onChange={handleChange}
					name={label}
				/>
			}
			label={<RLabel includeGutterBottom={false} value={label} type={labelType} />}
		/>
	);
};

export default RCheckbox;
