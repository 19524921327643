import axios from 'axios';
import BaseApi from 'framework/base.api';
import NetworkManager from 'framework/NetworkManager';
export class ProductsApi extends BaseApi {
	constructor() {
		super();
		this.route = this.getRoute();
	}

	getRoute(): string {
		return `${NetworkManager.apiEndpoint}/products`;
	}

	searchBy(vendorId: string, categoryId: string) {
		return axios.post(`${this.route}/search`, {
			category: [categoryId],
			vendor: [vendorId],
		});
	}
}
