import { IProductCategoryMenu } from 'models/productCategory';
import { IProfile } from 'models/profile';
import { IVendor } from 'models/vendor';

export type IOrderProduct = {
	dishId: string;
	name: string;
	amount: number;
	options: Record<'name' | 'value', string>[];
};

export enum OrderStage {
	Pending = 1,
	ReadyForPickup = 2,
	Delivered = 3,
	Undelivered = 4,
	Canceled = 5,
	Received = 6,
}

export interface IRecurringOrder {
	original_order: IOrder;
	pick_up_date: Date;
}

export interface IOrder {
	id: string;
	stage: OrderStage;
	price: number;
	products: IOrderProduct[];
	pick_up_date: Date;
	created_date: Date;
	recurring_order: boolean;
	profile: IProfile;
	vendor: IVendor;
	order_number?: string;
	menu: IProductCategoryMenu;
	recurring_order_ref: IRecurringOrder;
}

export interface SearchableOrder {
	id: string;
	stage: OrderStage;
	date: string;
	name: string;
	order_number: string;
	dish: string;
}
