import axios from 'axios';
import BaseApi from 'framework/base.api';
import NetworkManager from 'framework/NetworkManager';
import { WaitingList } from 'models/waitingList';

export class WaitingListsApi extends BaseApi {
	constructor() {
		super();
		this.route = this.getRoute();
	}

	getRoute(): string {
		return `${NetworkManager.apiEndpoint}/waiting-lists`;
	}

	async findOne(params: { vendor: string; date: string }) {
		return axios.get<WaitingList | null>(`${this.route}/find-by-date`, { params });
	}
}
