import React, { useEffect } from 'react';
import 'App.scss';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'store';
import { PrivateRoute } from 'components/ProtectedRoute';
import { ROUTES, AppRoutes } from 'constants/routes';
import authActions from 'features/Login/auth.actions';
import AppBar from 'components/AppBar';
import history from 'services/history';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Role } from 'enum/role.enum';
import { MyServicesPageRoute } from 'framework/Routes/MyServicesPage.route';
import { ManageSlotsPageRoute } from 'framework/Routes/ManageSlotsPage.route';
import { BaseRoute } from 'framework/Routes/Base.route';
import NotFoundPage from 'features/NotFoundPage/NotFoundPage';
import RSnackbar from 'components/Snackbar/RSnackbar';
import { FoodDashboardPageRoute } from 'framework/Routes/FoodDashboardPage.route';
import * as AppSelector from 'store/selectors/app.selector';
import { currentVendorSelector } from 'store/selectors/vendor.selectors';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.default,
	},
}));

const App = () => {
	const classes = useStyles();
	const isConnected = useSelector(AppSelector.isConnectedSelector);
	const role = useSelector(AppSelector.roleSelector);
	const relatedVendor = useSelector(AppSelector.relatedVendorSelector, shallowEqual);
	const user = useSelector(AppSelector.userSelector, shallowEqual);
	const onLoading = useSelector(AppSelector.onLoadingSelector);
	const currentVendor = useSelector(currentVendorSelector, shallowEqual);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(authActions.checkIsLoggedInAction());
	}, [isConnected, dispatch]);

	const appBarTabs = React.useMemo(() => {
		const regularVendorAppBarTabs = [
			{
				label: 'Services',
				selected: history.location.pathname.includes('services'),
				onChange: () => {
					BaseRoute.navigateTo(new MyServicesPageRoute(), [currentVendor.vendor?.id || '']);
				},
			},
			{
				label: 'Manage sessions',
				selected: history.location.pathname.includes('manage_slots'),
				onChange: () => {
					BaseRoute.navigateTo(new ManageSlotsPageRoute(), [currentVendor.vendor?.id || '']);
				},
			},
		];
		if (role.includes(Role.Admin))
			return history.location.pathname === ROUTES.DASHBOARD ? [] : currentVendor.vendor ? regularVendorAppBarTabs : [];
		return currentVendor.vendor ? regularVendorAppBarTabs : [];
	}, [role, currentVendor.vendor]);

	const detectUserHomeRouteRedirect = (): string => {
		let redirectRoute = '';
		if (!isConnected) redirectRoute = ROUTES.LOGIN;
		else if (role.includes(Role.FoodAdmin) && role.length === 1) redirectRoute = new FoodDashboardPageRoute().getParsedPath([]);
		else if (role.includes(Role.Admin) || role.includes(Role.BusinessAdmin) || role.length > 1) redirectRoute = ROUTES.DASHBOARD;
		else if (relatedVendor.length) redirectRoute = new MyServicesPageRoute().getParsedPath([relatedVendor[0]?.id]);

		return redirectRoute;
	};

	return (
		<div className={classes.root}>
			{isConnected ? <AppBar tabs={appBarTabs} avatarProps={{ src: user?.picture, alt: user?.name }} /> : ''}
			{onLoading ? <LinearProgress /> : <div style={{ height: 4 }} />}
			<RSnackbar isOpen={onLoading} />
			<Router history={history}>
				<Switch>
					<Redirect exact from="/" to={detectUserHomeRouteRedirect()} />
					{AppRoutes.map((route, index) =>
						route.isConnected() ? (
							<PrivateRoute
								key={index}
								exact={route.getIsExact()}
								path={route.getPath()}
								component={route.getComponent()}
								adminRoute={route.isAdminRoute()}
								role={role}
							/>
						) : (
							<Route key={index} exact={route.getIsExact()} path={route.getPath()} component={route.getComponent()} />
						)
					)}
					<Route key={AppRoutes.length} component={NotFoundPage} />
				</Switch>
			</Router>
		</div>
	);
};

export default App;

export const DivContainer = styled.div``;

export const VDivContainer = styled.div`
	display: flex;
	align-items: center;
`;
