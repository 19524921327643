import { makeStyles } from '@material-ui/core';
import React from 'react';
import { styled } from '@material-ui/core/styles';
import { IROption } from 'components/Select/RSelect';
import RLabel from 'components/Label/RLabel';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export const useStyles = makeStyles(theme => ({}));

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	height: 45,
	backgroundColor: '#F2F2F2',
	borderRadius: 30,
	'& .Mui-selected': {
		backgroundColor: 'white',
		borderRadius: '30px !important',
	},
	'& .Mui-selected:hover': {
		backgroundColor: 'white !important',
	},
	'& .MuiButtonBase-root': {
		margin: 5,
		border: 0,
	},
	'& .MuiButtonBase-root:hover': {
		backgroundColor: 'transparent',
	},
}));

export interface IRSwitchProps {
	leftOption: IROption;
	rightOption: IROption;
	onChange: (value: IROption) => void;
	value?: boolean;
}

const RSwitch: React.FC<IRSwitchProps> = ({ leftOption, rightOption, onChange, value }) => {
	const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: boolean | undefined) => {
		if (newValue != null) onChange(newValue ? rightOption : leftOption);
	};

	return (
		<CustomToggleButtonGroup value={value} exclusive onChange={handleChange} aria-label="text alignment">
			<ToggleButton value={false}>
				<RLabel value={leftOption.label} includeGutterBottom={false} />
			</ToggleButton>
			<ToggleButton value={true}>
				<RLabel value={rightOption.label} includeGutterBottom={false} />
			</ToggleButton>
		</CustomToggleButtonGroup>
	);
};

export default RSwitch;
