import { CardContent, makeStyles } from '@material-ui/core';
import React from 'react';
import RButton from 'components/Button/RButton';
import { useParams } from 'react-router-dom';
import { Resources } from 'enum/resources.enum';
import RCard from 'components/Card/RFade';
import { BaseRoute } from 'framework/Routes/Base.route';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { FlexSpaceBetween } from 'components/general';
import { IProductCategory } from 'models/productCategory';
import { ProductsPageRoute } from 'framework/Routes/ProductsPage.route';
import { DocumentStatus } from 'models/misc';
import RListGroup from 'components/ListGroup/RListGroup';
import { ICategoryParams } from 'features/CategoriesPage/CategoriesPage';

export const useStyles = makeStyles(theme => ({
	indicator: {
		backgroundColor: theme.palette.error.light,
		borderRadius: 4,
		color: 'white',
		padding: 5,
	},
}));
export interface RCategoryCardProps {
	category: IProductCategory;
}

const RCategoryCard: React.FC<RCategoryCardProps> = ({ category }: RCategoryCardProps) => {
	const { vendorId } = useParams<ICategoryParams>();
	const classes = useStyles();

	const navigateToProductsPage = () => {
		BaseRoute.navigateTo(new ProductsPageRoute(), [vendorId, category.menu, category.id]);
	};

	return (
		<RCard height={100} padding={20}>
			<CardContent>
				<FlexSpaceBetween>
					<RLabel value={category.name} type={RLabelTypes.BOLD_18} />
					<RListGroup horizontal>
						{category.status === DocumentStatus.DELETED && (
							<div className={classes.indicator}>
								<RLabel value={Resources.INACTIVE} />
							</div>
						)}
						<RButton
							label={Resources.CATEGORIES_SCREEN_CARD_ACTION_TITLE}
							onClick={navigateToProductsPage}
							disabled={category.status === DocumentStatus.DELETED}
						/>
					</RListGroup>
				</FlexSpaceBetween>
			</CardContent>
		</RCard>
	);
};

export default RCategoryCard;
