import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { Subscriber } from 'models/slot';
import RListGroup from 'components/ListGroup/RListGroup';
import RImageAndLabel from 'components/ImageAndLabel/RImageAndLabel';
import { FloatRight } from 'components/general';
import RCheckbox from 'components/Checkbox/RCheckbox';
import { Resources } from 'enum/resources.enum';
import { IProfile, SearchableProfile } from 'models/profile';

type Props = {
	subscriber: Subscriber;
	canRemoveSubscriber: boolean;
	onRemove: (profile: SearchableProfile) => void;
	onChange: (subscriberId: string, isChecked: boolean) => void;
};

const SlotSubscriber = ({ subscriber, canRemoveSubscriber, onRemove, onChange }: Props) => {
	const profile = subscriber.profile as IProfile;

	return (
		<Grid container spacing={3} alignItems="center">
			<Grid item xs={6}>
				<RListGroup horizontal>
					{canRemoveSubscriber && (
						<IconButton
							color="primary"
							onClick={() =>
								onRemove({
									id: subscriber._id,
									name: profile.name,
									email: profile.email,
									status: profile.status,
									picture: profile.picture,
									first_login: '',
								})
							}
						>
							<ClearIcon />
						</IconButton>
					)}
					<RImageAndLabel imageUrl={profile.picture} label={profile.name ?? profile.email ?? 'Unnamed user'} />
					{subscriber.with_guest ? <div>+</div> : ''}
					{subscriber.with_guest ? <RImageAndLabel imageUrl="" label="Guest" /> : ''}
					{subscriber.subscription_notes ? (
						<Tooltip title={subscriber.subscription_notes}>
							<Info color="primary" />
						</Tooltip>
					) : (
						''
					)}
				</RListGroup>
			</Grid>
			<Grid item xs={6}>
				<FloatRight>
					<RCheckbox
						onChange={isChecked => {
							onChange(subscriber._id, isChecked);
						}}
						isChecked={Boolean(subscriber.fulfilled)}
						label={Resources.ARRIVED}
					/>
				</FloatRight>
			</Grid>
		</Grid>
	);
};

export default SlotSubscriber;
