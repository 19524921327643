import { ROUTES } from 'constants/routes';
import { BaseRoute } from 'framework/Routes/Base.route';
import OrderManagementPage from 'features/OrderManagementPage';

export class OrderManagementPageRoute extends BaseRoute {
	getPath(): ROUTES {
		return ROUTES.ORDER_MANAGEMENT;
	}

	getParams(): string[] {
		return [];
	}

	getComponent(): React.FC<any> {
		return OrderManagementPage;
	}

	getQueryParams(): string[] {
		return [];
	}
}
