import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'store';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { FlexSpaceBetween, Spacer } from 'components/general';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { Resources } from 'enum/resources.enum';
import RContainer from 'components/Container/RContainer';
import { IVendor } from 'models/vendor';
import { performAsyncAction } from 'features/Vendor/vendor.actions';
import { VendorsApi } from 'framework/API/vendor.api';
import { GetVendorResponse } from 'store/slices/vendor.slice';
import { ICategoryParams } from 'features/CategoriesPage/CategoriesPage';
import REmptyCategoryView from 'features/CategoriesPage/REmptyCategoryView';
import { ProductsApi } from 'framework/API/products.api';
import { IProduct } from 'models/product';
import RProductCard from 'features/ProductsPage/RProductCard';
import RBreadcrumbs from 'components/Breadcrumbs/RBreadcrumbs';
import { ROUTES } from 'constants/routes';
import { CategoriesPageRoute } from 'framework/Routes/CategoriesPage.route';
import { IProductCategory } from 'models/productCategory';
import { CategoriesApi } from 'framework/API/categories.api';

export interface IProductsParams extends ICategoryParams {
	categoryId: string;
}

const ProductsPage: React.FC = () => {
	const { menuId, vendorId, categoryId } = useParams<IProductsParams>();
	const dispatch = useDispatch();
	const [currentVendor, setCurrentVendor] = useState<IVendor | null>();
	const [currentCategory, setCurrentCategory] = useState<IProductCategory | null>();
	const [products, setProducts] = useState<IProduct[]>([]);

	useEffect(() => {
		if (!currentVendor) {
			performAsyncAction(dispatch, new VendorsApi().readById(vendorId), (res: GetVendorResponse) => {
				setCurrentVendor(res.vendor);
			});
		}
	}, [currentVendor, dispatch, vendorId]);

	useEffect(() => {
		if (!currentCategory) {
			performAsyncAction(dispatch, new CategoriesApi().readById(categoryId, { menu: menuId }), (res: IProductCategory) => {
				setCurrentCategory(res);
			});
		}
	}, [categoryId, currentCategory, dispatch, menuId]);

	const fetchProducts = useCallback(() => {
		if (currentVendor) {
			performAsyncAction(dispatch, new ProductsApi().searchBy(vendorId, categoryId), (res: any[]) => {
				//mapping the _id to be id
				setProducts(res.map(i => ({ ...i, id: i._id })));
			});
		}
	}, [categoryId, currentVendor, dispatch, vendorId]);

	useEffect(() => {
		fetchProducts();
	}, [fetchProducts]);

	return (
		<RContainer>
			<Spacer />
			<RBreadcrumbs
				links={[
					{ href: ROUTES.FOOD_DASHBOARD, label: Resources.MENUS_SCREEN_CURRENT_DIR_LINK },
					{ href: ROUTES.MENUS.replace(':vendorId', vendorId), label: currentVendor?.type || '' },
					{
						href: new CategoriesPageRoute().getParsedPath([vendorId, menuId]),
						label: `${menuId} ${Resources.CATEGORIES_SCREEN_CURRENT_DIR_LINK}`,
					},
					{ label: `${currentCategory?.name ?? ''} ${Resources.PRODUCTS_SCREEN_TITLE}` },
				]}
			/>
			<FlexSpaceBetween>
				<RLabel value={Resources.PRODUCTS_SCREEN_TITLE} type={RLabelTypes.BOLD_25} />
			</FlexSpaceBetween>
			<Spacer />
			{products.length > 0 ? (
				<Grid container spacing={3}>
					{products.map((product, index) => {
						return (
							<Grid key={product.id} item xs={12} sm={12}>
								<RProductCard product={product} />
							</Grid>
						);
					})}
				</Grid>
			) : (
				<REmptyCategoryView description={Resources.PRODUCTS_SCREEN_EMPTY_STATE_DESCRIPTION} />
			)}
		</RContainer>
	);
};

export default ProductsPage;
