import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Role } from 'enum/role.enum';

interface Props {
	// any props that come into the component
	component: any;
	path: string;
	exact: boolean;
	adminRoute?: boolean;
	role: Role[];
}

export const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			rest.adminRoute && rest.role.includes(Role.Admin) && localStorage.getItem('token') ? (
				<Component {...props} />
			) : rest.adminRoute && rest.role.includes(Role.Admin) ? (
				<Redirect to={{ pathname: '/', state: { from: props.location } }} />
			) : localStorage.getItem('token') ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: '/', state: { from: props.location } }} />
			)
		}
	/>
);
