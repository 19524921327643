import React from 'react';
import { Menu as MuiMenu, MenuItem } from '@material-ui/core';
import { RServiceCardMoreOption } from 'features/MyServicesPage/ServiceCard/RServiceCard';

interface MenuProps {
	options: RServiceCardMoreOption[];
	id: string;
	anchorEl: null | HTMLElement;
	handleClose: VoidFunction;
	handleSelect: (option: RServiceCardMoreOption) => void;
}

const Menu = ({ options, id, anchorEl, handleClose, handleSelect }: MenuProps) => {
	return (
		<MuiMenu id={id} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
			{options.map((option, index) => {
				return (
					<MenuItem key={index} onClick={() => handleSelect(option)}>
						{option.title}
					</MenuItem>
				);
			})}
		</MuiMenu>
	);
};

export default Menu;
