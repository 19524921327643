import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import RCheckbox from 'components/Checkbox/RCheckbox';
import { IROption } from 'components/Select/RSelect';
import { ListGroup } from 'react-bootstrap';
import { RStartEndSelectTimes } from 'components/ScheduleSlotsDaysTable/ScheduleSlotsDayRow/StartEndSelectTimes';
import RButton from 'components/Button/RButton';
import RFade from 'components/Fade/RFade';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { HoverItem } from 'components/general';
import { Trash } from 'components/Icons/Icons';
import {
	buildHoursSelectOptions,
	businessDayToString,
	DEFAULT_END_HOUR,
	getDefaultDayProperties,
	getSelectedTime,
	HourAndMinutes,
	SlotDayProperty,
} from 'features/ScheduleSlotsPage/ScheduleSlotsPage.types';

import { RightSpacer } from 'components/general';
import RSmallTextField from 'components/SmallTextField/RSmallTextField';

export const useStyles = makeStyles(theme => ({
	root: {},
	addSelectionTimeSection: {
		alignItems: 'center',
		margin: '0 auto',
	},
	dayContainer: {
		marginTop: 20,
	},
	AddButtonContainer: {
		marginTop: 10,
	},
	unavailableContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	trashButton: {
		marginLeft: 5,
	},
}));

export interface RScheduleSlotsDayData {
	isActive: boolean;
	selectedProperties: SlotDayProperty[];
}
export interface RScheduleSlotsDayRowProps {
	day: number;
	selectedProperties: (Record<`${'start' | 'end'}Options`, IROption[]> & { maxParticipants: number; location: string | null })[];
	isActive: boolean;
	disableCheckbox: boolean;
	onDataChange?: (data: RScheduleSlotsDayData) => void;
	sessionDuration: number;
	withLocation?: boolean;
}

const RScheduleSlotsDayRow: React.FC<RScheduleSlotsDayRowProps> = ({
	selectedProperties,
	isActive,
	sessionDuration,
	onDataChange,
	disableCheckbox,
	day,
	withLocation = false,
}) => {
	const classes = useStyles();

	const buildSelectedProperties = (
		values: {
			startOptions: IROption[];
			endOptions: IROption[];
			maxParticipants: number;
			location: string;
		}[]
	) => {
		const selectedTimes: SlotDayProperty[] = values.map(
			item =>
				new SlotDayProperty(
					getSelectedTime(item.startOptions),
					getSelectedTime(item.endOptions),
					item.maxParticipants,
					item.location
				)
		);
		return selectedTimes;
	};

	const handleRemoveSelectTime = (index: number) => {
		const updated = [...selectedProperties.filter((a, i) => i !== index)];
		onRowChange(updated.length > 0, buildSelectedProperties(updated));
	};

	const startOptionsSelectedTime = React.useMemo(
		() => getSelectedTime(selectedProperties.at(-1).endOptions).addDuration(sessionDuration),
		[selectedProperties, sessionDuration]
	);

	const endOptionsSelectedTime = React.useMemo(
		() => new HourAndMinutes(startOptionsSelectedTime.hour, startOptionsSelectedTime.minutes).addDuration(sessionDuration),
		[sessionDuration, startOptionsSelectedTime.hour, startOptionsSelectedTime.minutes]
	);

	const hasReachedMaxHour = startOptionsSelectedTime.hour >= DEFAULT_END_HOUR || endOptionsSelectedTime.hour >= DEFAULT_END_HOUR;

	const onRowChange = React.useCallback(
		(active: boolean, properties: SlotDayProperty[]) => {
			if (onDataChange) {
				onDataChange({
					selectedProperties: properties,
					isActive: active,
				});
			}
		},
		[onDataChange]
	);

	const handleAddSelectTime = React.useCallback(() => {
		if (selectedProperties.length > 0)
			onRowChange(
				isActive,
				buildSelectedProperties([
					...selectedProperties,
					{
						startOptions: buildHoursSelectOptions(getDefaultDayProperties(), [startOptionsSelectedTime]),
						endOptions: buildHoursSelectOptions(getDefaultDayProperties(), [endOptionsSelectedTime]),
						maxParticipants: 15,
						location: '',
					},
				])
			);
		else onRowChange(isActive, buildSelectedProperties(selectedProperties));
	}, [endOptionsSelectedTime, isActive, onRowChange, selectedProperties, startOptionsSelectedTime]);

	const handleCheckboxChange = (isChecked: boolean) => {
		onRowChange(isChecked, buildSelectedProperties(selectedProperties));
	};

	return (
		<Wrapper>
			<Grid container>
				<Grid className={classes.dayContainer} item xs={12} sm={3}>
					<RCheckbox
						disabled={disableCheckbox}
						label={businessDayToString(day)}
						isChecked={isActive}
						onChange={handleCheckboxChange}
					/>
				</Grid>
				{isActive ? (
					<>
						<Grid className={classes.dayContainer} item xs={12} sm={9}>
							{selectedProperties.map((item, index) => {
								return (
									<RFade key={index} wrapperClassName="row-modifiers">
										<RStartEndSelectTimes
											key={index}
											index={index}
											startOptions={item.startOptions}
											endOptions={item.endOptions}
											onEndOptionsChange={(i, options) => {
												const updated = [...selectedProperties];
												updated[i].endOptions = options;
												onRowChange(isActive, buildSelectedProperties([...updated]));
											}}
											onStartOptionsChange={(i, options) => {
												const updated = [...selectedProperties];
												updated[i].startOptions = options;
												onRowChange(isActive, buildSelectedProperties([...updated]));
											}}
											duration={sessionDuration}
										/>
										<RSmallTextField
											dense
											label={<PeopleAltIcon color="primary" />}
											value={selectedProperties[index].maxParticipants}
											onChange={value => {
												if (Number.isNaN(+value)) return;
												onRowChange(
													isActive,
													buildSelectedProperties(
														selectedProperties.map((property, i) => ({
															...property,
															maxParticipants: i === index ? +value : property.maxParticipants,
														}))
													)
												);
											}}
										/>
										<ListGroup className={classes.addSelectionTimeSection} horizontal>
											{index > 0 ? (
												<HoverItem
													className={classes.trashButton}
													onClick={() => {
														handleRemoveSelectTime(index);
													}}
												>
													{Trash}
												</HoverItem>
											) : (
												<RButton
													width={36}
													height={36}
													disabled={!isActive || hasReachedMaxHour}
													label="+"
													onClick={handleAddSelectTime}
												/>
											)}
											<RightSpacer />
										</ListGroup>
									</RFade>
								);
							})}
						</Grid>
					</>
				) : (
					<Grid className={classes.unavailableContainer} item xs={12} sm={5}>
						{
							<RFade>
								<RLabel value="Unavailable" type={RLabelTypes.REGULAR_18} />
							</RFade>
						}
					</Grid>
				)}
			</Grid>
		</Wrapper>
	);
};

export default RScheduleSlotsDayRow;

const Wrapper = styled.div`
	.row-modifiers {
		display: flex;
		align-items: center;

		.small-field-textfield-container {
			margin-left: 50px;
		}
	}
`;
