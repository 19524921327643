import React from 'react';
import RButton, { RButtonTheme } from 'components/Button/RButton';
import { SmallSpacer } from 'components/general';
import { EmptyImage } from 'components/Icons/Icons';
import RLabel from 'components/Label/RLabel';
import RListGroup from 'components/ListGroup/RListGroup';
import { Resources } from 'enum/resources.enum';

export interface REmptyViewProps {
	onClick: () => void;
	description: string;
	actionDescription: string;
}

const REmptyView: React.FC<REmptyViewProps> = (props: REmptyViewProps) => {
	return (
		<div>
			<RListGroup horizontal={false}>
				{EmptyImage}
				<SmallSpacer />
				<RLabel value={props.description} />
				<SmallSpacer />
				<RButton theme={RButtonTheme.DARK} label={`+ ${Resources.SERVICE_CARD_CREATE_BUTTON}`} onClick={props.onClick} />
			</RListGroup>
		</div>
	);
};

export default REmptyView;
