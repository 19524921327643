import { CardContent } from '@material-ui/core';
import React from 'react';
import RButton from 'components/Button/RButton';
import { Resources } from 'enum/resources.enum';
import RCard from 'components/Card/RFade';
import RLabel, { RLabelTypes } from 'components/Label/RLabel';
import { FlexSpaceBetween } from 'components/general';
import { IProductChoiceSet } from 'models/product';
import RListGroup from 'components/ListGroup/RListGroup';

export interface RProductOptionSetCardProps {
	set: IProductChoiceSet;
	onView?: () => void;
}

const RProductOptionSetCard: React.FC<RProductOptionSetCardProps> = ({ set, onView }: RProductOptionSetCardProps) => (
	<RCard height={50} padding={1}>
		<CardContent>
			<FlexSpaceBetween>
				<RLabel value={set.name} type={RLabelTypes.BOLD_18} />
				<RListGroup horizontal={true}>
					<RButton label={Resources.PRODUCT_SCREEN_OPTION_CARD_ACTION_BUTTON} onClick={onView} />
				</RListGroup>
			</FlexSpaceBetween>
		</CardContent>
	</RCard>
);

export default RProductOptionSetCard;
