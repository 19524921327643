import { makeStyles, Snackbar } from '@material-ui/core';
import React from 'react';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import { showSnackbar } from 'store/slices/app.slice';
import { shallowEqual, useDispatch, useSelector } from 'store';
import { snackbarSelector } from 'store/selectors/app.selector';

export const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.primary.light,
	},
}));

export interface IRSnackbarProps {
	severity?: Color;
	isOpen: boolean;
	label?: string;
}

const RSnackbar: React.FC<IRSnackbarProps> = (props: IRSnackbarProps) => {
	const dispatch = useDispatch();
	const snackbar = useSelector(snackbarSelector, shallowEqual);

	const handleClose = () => {
		dispatch(showSnackbar({ isOpen: false }));
	};

	return (
		<Snackbar
			open={snackbar.isOpen && snackbar.label !== undefined}
			autoHideDuration={3000}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<MuiAlert onClose={handleClose} severity={snackbar.severity}>
				{snackbar.label}
			</MuiAlert>
		</Snackbar>
	);
};

export default RSnackbar;
